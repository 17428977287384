import React, { useEffect, useState } from "react";
import HomeHdr from "../HomeHdr";
import AddHomeForm from "../AddHomeForm";
import EnergyUsed from "../../../common/EnergyUsed";
import DataTableBase from "../../../common/DataTableBase";
// import { useNavigate } from "react-router-dom";
import GoogleMap from "../../../common/GoogleMap";

import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
// import { BallTriangle } from "react-loader-spinner";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import homeImg from '../../../../assets/img5.jpg';
import delIcon from '../../../../assets/icons/del-icon.png';
import { BallTriangle } from "react-loader-spinner";
import { apiEndPoint } from "../../../../utils";
import SweetAlert from "react-bootstrap-sweetalert";

// import {XLSX} from 'xlsx';

import * as XLSX from 'xlsx/xlsx.mjs';


const data2 = [
    {
        date: '2023-04-17',
        time: '18:30',
        chargingTime: '43m',
        EnergyUsed: '1.71',
        status: 'normal',
    },
    {
        date: '2023-04-16',
        time: '7:30',
        chargingTime: '1h 33m',
        EnergyUsed: '2.31',
        status: 'normal',
    },
    {
        date: '2023-04-13',
        time: '15:23',
        chargingTime: '2h 51m',
        EnergyUsed: '4.72',
        status: 'normal',
    },
    {
        date: '2023-04-11',
        time: '2:38',
        chargingTime: '42m',
        EnergyUsed: '1.09',
        status: 'normal',
    },
]
const ChargerDetail = ({user}) => {
    const [activeOp, setActiveOp] = useState(1);
    const [location, setLocation] = useState({
        // lat:33.684422,
        // lng: 73.047882
    })

    const [tableData, setTableData] = useState({});

    const [filter, setFilter] = useState('today');

    const navigate = useNavigate();

    const columns = [
        {
            name: "Date",
            selector: row => {
                if(row?.reportTime !== null && row?.reportTime !== 'undefined'){
                    const date = new Date(row?.reportTime)?.toISOString()?.split('T')[0]
                    return date;
                } 
                // else {
                // }
                return "";
            },
        },
        {
            name: "Start Time",
            selector: row => {
                // if(row?.startTime !== null  && row?.startTime !== 'undefined'){
                //     const time = new Date(row?.startTime)?.toISOString()?.split('T')[1];
                //     const formateTime = time?.split('.')[0];
                //     return formateTime;
                // }
                return "";
            },
        },
        {
            name: "End Time",
            selector: row => {
                // if(row?.stopTime !== null && row?.stopTime !== 'undefined'){
                //     const time = new Date(row?.stopTime)?.toISOString()?.split('T')[1];
                //     const formateTime = time?.split('.')[0];
                //     return formateTime;
                // }
                return "";
            },
        },
        // {
        //     name: "Charging Time",
        //     selector: row => row?.chargingTime,
        // },
        {
            name: "Energy Used (kWh)",
            selector: row => (Number(row?.energy)).toLocaleString(),
        },
        {
            name: "Limit",
            selector: row => `${row?.transCapacity} / 100 %`,
        }
    ]


    function logoutFun2 (){
        localStorage.removeItem('pea_client');
        navigate('/login');
    }


    function handleLocation(event){
        const name = event.target.name
        const value = event.target.value
        if(name === 'lat'){
            setLocation({lng: location.lng, lat: parseInt(value)})
        }else if(name === 'lng'){
            setLocation({lat: location.lat, lng: parseInt(value)})
        }
    }



    const [focusElements, setFocusElements] = useState(['active-tog']);

    const [station, setStation] = useState(null);

    const [image, setImage] = useState(null);

    const [showAlert, setShowAlert] = useState(null);

    const [delInput, setDelInput] = useState(null);

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    
    const {id} = useParams();
    
    useEffect(() => {
        const token = localStorage.getItem("pea_admin");
        axios.get(`${apiEndPoint}/api/admin/chargers/${id}`, {
            headers: {Authorization: `Bearer ${token}`}
        }).then((res) => {
            console.log("Res dashboard : ", res);
            setData(res?.data);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.log("Err : ", err);
        })
    }, [])

    const deleteFunc = () => {
        if(delInput === 'DELETE'){
            handleClose();
            setLoading(true);
            const token = localStorage.getItem("pea_admin");
            
            axios.get(`${apiEndPoint}/api/admin/charger/delete/${id}`, {
                headers: {Authorization: `Bearer ${token}`}
            }).then((res) => {
                console.log("Res dashboard : ", res);
                setLoading(false);
                setShow1(true);
                setTimeout(() => {
                    setShow1(false);
                    navigate('/chargerlist');
                }, 2000)
            }).catch((err) => {
                setLoading(false);
                console.log("Err : ", err);
            })        
        }
    }


    function convertToWorksheet(tableData) {
        const worksheet = XLSX.utils.json_to_sheet(tableData);

        // Set width for all columns to 15 characters
        // worksheet['!cols'] = Array(worksheet['!ref'].split(':')[1].charCodeAt(0) - 64).fill({ width: 25 });
        const columnWidths = [18, 20, 20, 22]

        worksheet['!cols'] = columnWidths.map(width => ({ width }));

        return worksheet;
    }


    function downloadExcelFile(tableData, fileName) {
        const worksheet = convertToWorksheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
    }



    function handleDownloadExcel(fileData) {
        const now = new Date();
        const isoDateTime = now.toISOString();    
        const structuredData2 = [];
        const StructureData = fileData?.filter(item => {
            let date;
            if(item?.startTime !== null && item?.startTime !== 'undefined'){
                date = new Date(item?.startTime)?.toISOString()?.split('T')[0];
            }
            let start;
            if(item?.startTime !== null  && item?.startTime !== 'undefined'){
                const date2 = new Date(item?.startTime)?.toISOString()?.split('T')[1];
                start = date2?.split('.')[0];
            }
            let end;
            if(item?.stopTime !== null && item?.stopTime !== 'undefined'){
                const time = new Date(item?.stopTime)?.toISOString()?.split('T')[1];
                end = time?.split('.')[0];
            }
            const obj = {"Date": date, "Start Time": start, "Stop Time": end, "Energy Used kWh": item?.meterDifference, "Status": `${item?.transCapacity}/100`};
            structuredData2?.push(obj);
            return obj;
            }
        )

        // console.log("Structured data : ", StructureData)

        // console.log("Structured data 2 : ", structuredData2)
        
        downloadExcelFile(structuredData2, `Charger Detail ${isoDateTime}`);
        // if(dateRange2?.option){
        //     const dumArr = [
        //         {
        //             month: null,
        //             session: 0,
        //             revenue: 0,
        //         }
        //     ];


        //     const start = new Date(dateRange2?.startDate);
        //     const end = new Date(dateRange2?.endDate);

        //     data && data.map(obj => {
        //         // console.log("obj : ", obj)
        //         const currentDate = new Date(obj?.startDate);
        //         const rev = parseFloat(obj?.revenue);

        //         if(currentDate >= start && currentDate <= end){
        //             dumArr[0].session +=1;
        //             dumArr[0].revenue += rev;    
        //         }

        //     });

        //     dumArr[0].month = `${dateRange2?.startDate}-${dateRange2?.endDate}`
            
        //     console.log("dumArr data : ", dumArr);

        //     downloadExcelFile(dumArr, `Revenue Report ${isoDateTime}`);
        // } else {
        // }
        // downloadExcelFile(fileData, `Revenue Report ${isoDateTime}`);
    }

  

    if(loading){
        return (
            <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '33vh'}}>
                <BallTriangle
                    height={90}
                    width={90}
                    radius={5}
                    color="#814097"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle="" 
                    visible={true}
                />
            </div>
        )
    } else {
    return (
        <>
            {/* {user != 1 && (
                <HomeHdr type={1} />
            )} */}
            {show1 && (
                <SweetAlert success title="Congratulations" onConfirm={() => {setShow1(false); navigate('/chargerlist')}} onCancel={() => {setShow1(false);}}>
                    Charger deleted sucessfully.
                </SweetAlert>
            )}

            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                <Modal.Title>Alert</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center">
                        <h4>Are you sure you want to delete this? </h4>
                        <p>
                            If yes then write 'DELETE' in the input field
                        </p>
                        <input type="text" className="form-control" onChange={(e) => setDelInput(e.target.value)} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={deleteFunc}>
                    Yes
                </Button>
                </Modal.Footer>
            </Modal>
            
            <div className={user === 1 ? "Station-detail hdr-on-mobile" : "Station-detail"}>                            
                <span className="pages-routes1">Charger </span>
                <span className="pages-routes2">&#62;</span>
                <span className="pages-routes1">{data?.charger?.chargerID} </span>
                <span className="pages-routes2">&#62;</span>
                {/* {type === 2 && (
                    <>
                        <span className="pages-routes1">HOME KORAT 01 </span>
                        <span className="pages-routes2">&#62;</span>
                    </>
                )} */}
                
                <span className="pages-routes3">view</span>
                {/* <h2 className="station-detail-title">{station?.locationTH}</h2> */}
            
            <div className="station-detail-card">
                <div className="d-flex w-100">
                    {/* <Modal show={show} style={{marginTop: '25vh'}} onHide={handleClose}>
                        <Modal.Header closeButton>
                        <Modal.Title className="w-100 text-center">Update Profile</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="px-4">
                            <label className="mb-2">Upload Profile</label>
                            <input type="file" name="profile" onChange={(e) => setImage(e.target.files[0])} className="form-control" />
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" style={{background: '#814097', border: 'none'}} onClick={uploadImage}>
                            Save Changes
                        </Button>
                        </Modal.Footer>
                    </Modal> */}
                    <div className="staion-detail-cardimg" style={{background: '#eeeeee'}}>
                        {/* {station?.chargerPhoto ? ( */}
                            <>
                                {/* <div className="edit-img-div2" onClick={handleShow}>
                                    <FiCamera size={"0.5cm"} color="#000" />
                                </div> */}
                                {/* {type === 1 || type === 2 ? ( */}
                                    <img src={data?.charger?.chargerImage?.startsWith('substation_images') === true ? `${apiEndPoint}/${data?.charger?.chargerImage}` : homeImg} style={{marginTop: '-0px'}} height="164px" width="164px" alt="picc" />
                                {/* ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" style={{margin: '45px 30%'}} width="65" height="65" viewBox="504 253 65 65"><path d="M504 253v65h65v-65h-65Zm8.125 8.125h48.75V285.5l-8.125-8.125-8.125 8.125 16.25 16.25v8.125h-8.125l-32.5-32.5-8.125 8.125v-24.375Z" fill="#b2b2b2" fill-rule="evenodd" data-name="Icon open-image"/></svg>
                                )} */}
                            </>
                        {/* ) : (
                            <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '3vh'}}>
                                <BallTriangle
                                    height={70}
                                    width={70}
                                    radius={5}
                                    color="#814097"
                                    ariaLabel="ball-triangle-loading"
                                    wrapperClass={{}}
                                    wrapperStyle=""
                                    visible={true}
                                />
                            </div>
                        )} */}
                    </div>
                    <div className="staion-detail-carddetail">
                        {/* {type === 1 ? (
                            <div className="stations-number" style={{height: '24px'}}>
                                <h6>PEA-1129-P248</h6>
                                <div style={{cursor:'pointer'}}>
                                    <div>
                                        <div className="home-status-div d-flex justify-content-between" style={{ float: 'right' }}>
                                            <div className="w-50 status-heading-div pt-1">
                                                <span className="status-heading-clr">Status :</span>
                                            </div>
                                            <div>
                                                <div className="home-status-div-show">
                                                    <span>
                                                        ON
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="w-100" style={{ float: 'right' }}>
                                        <p className="home-fontstyle-1">Last Charge : 2023-04-07 18:68</p>

                                    </div>
                                </div>
                            </div>
                        ) : (
                            <h6 className="font-style10">PEA-1129-P248</h6>
                        )} */}
                        <h4 className="font-style11" >{data?.charger?.chargerID}</h4>
                        <h5 className="font-style1121">{data?.charger?.chargerName}</h5>
                        <h6 classN0ame="font-style12 mb-0 pt-2 mt-3">
                            {data?.charger?.chargerAddress}
                            <div style={{float: 'right'}}>
                                <button onClick={() => navigate(`/chargerlist/edit/${data?.charger?.id}`)} className="edit-btn-header">Edit</button>
                                <img src={delIcon} className="del-icon-hdr pointer" onClick={handleShow} alt="del-icon-image" />
                            </div>
                        </h6>
                        <h6 className="font-style12 mb-0 pt-2">Status : {data?.charger?.status === 1 ? <span className="clr-ff0000 text-success"> Online</span> : <span className="clr-ff0000"> Offline</span>}</h6>
                        {/* <div className="w-100 d-flex font-style131">
                            <div className="me-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="35.75" height="31.778" viewBox="650.458 336.223 35.75 31.778"><path d="M671.312 364.028h-19.86a.993.993 0 0 0-.994.993v1.986c0 .549.444.993.993.993h19.861a.993.993 0 0 0 .993-.993v-1.986a.993.993 0 0 0-.993-.993Zm12.91-19.86v-2.98a.993.993 0 1 0-1.986 0v2.98h-1.986v-2.98a.993.993 0 1 0-1.986 0v2.98h-.993a.993.993 0 0 0-.994.992v1.986c0 2.22 1.466 4.077 3.476 4.713v7.354c0 .866-.59 1.671-1.444 1.812a1.74 1.74 0 0 1-2.032-1.714v-1.738a5.462 5.462 0 0 0-5.461-5.461h-.497v-11.917a3.972 3.972 0 0 0-3.972-3.972h-9.93a3.972 3.972 0 0 0-3.973 3.972v21.847h17.875v-6.951h.497a2.483 2.483 0 0 1 2.482 2.482v1.528c0 2.462 1.795 4.665 4.246 4.904a4.721 4.721 0 0 0 5.188-4.694v-7.452c2.01-.636 3.476-2.493 3.476-4.713v-1.986a.993.993 0 0 0-.993-.993h-.993ZM666.6 347.13l-5.816 8.628a.77.77 0 0 1-.645.325c-.476 0-.836-.39-.724-.802l1.429-5.157h-3.684c-.45 0-.797-.347-.738-.738l.993-6.64c.05-.324.365-.566.738-.566h4.22c.49 0 .846.406.72.82l-.72 3.152h3.582c.573 0 .931.545.645.978Z" fill="#b2b2b2" fill-rule="evenodd" data-name="Icon awesome-charging-station"/></svg>
                            </div>
                            <div>
                                <h6 className="font-style133 mb-0">Charger name : PEA VOLTA #01</h6>
                                <h6 className="font-style134">ID : PEA-1129</h6>
                            </div>
                        </div> */}
                        
                    </div> 
                </div>
                
            </div>
            {/* <Outlet/> */}
            </div>



            <div className="overview-page pt-0">
                <EnergyUsed charger={true} chargerUnit={data?.charger?.chargerType} max={Number(data?.charger?.chargerCapacity) + 2} setTableData={setTableData} url={`Charger/${id}`} type={1} lab1={`${data?.charger?.chargerID} Statistic`} />        
            </div>
            <div className="m-nav-div">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" className={activeOp === 1 ? "m-active-link" : "no-optt"} onClick={() => {navigate('/home'); setActiveOp(1)}} width="27.424" height="27.424" viewBox="56 801.576 27.424 27.424"><path d="M69.712 817.002a7.715 7.715 0 0 0 7.713-7.713 7.715 7.715 0 0 0-7.713-7.713A7.715 7.715 0 0 0 62 809.289a7.715 7.715 0 0 0 7.713 7.713Zm6.856 1.714h-2.951a9.334 9.334 0 0 1-3.905.857c-1.392 0-2.71-.31-3.905-.857h-2.95A6.856 6.856 0 0 0 56 825.572v.857A2.572 2.572 0 0 0 58.571 829h22.282a2.572 2.572 0 0 0 2.571-2.571v-.857a6.856 6.856 0 0 0-6.856-6.856Z" fill-rule="evenodd" data-name="Icon awesome-user-alt"/></svg>
                </div>
                <div>
                    <svg className={activeOp === 2 ? "m-active-link" : "no-optt"} onClick={() => {navigate('/home/profile'); setActiveOp(2)}} xmlns="http://www.w3.org/2000/svg" width="27.424" height="27.424" viewBox="181 799.288 27.424 27.424"><path d="m205.099 799.288-10.387 10.284-3.428-3.428L181 816.531l3.428 3.428 6.856-6.959 3.428 3.428 13.712-13.712-3.325-3.428ZM181 823.284v3.428h27.424v-3.428H181Z" fill-rule="evenodd" data-name="Icon open-graph"/></svg>
                </div>
                <div>
                    <svg onClick={logoutFun2} xmlns="http://www.w3.org/2000/svg" width="25.71" height="27.424" viewBox="301.993 801.575 25.71 27.424"><path d="M322.56 818.716v-3.428h-8.57v-3.428h8.57v-3.428l5.143 5.142-5.142 5.142Zm-1.713-1.714v6.856h-8.57V829l-10.284-5.142v-22.283h18.854v8.57h-1.714v-6.856H305.42l6.856 3.429v15.426h6.856v-5.142h1.714Z"  fill-rule="evenodd" data-name="Icon metro-exit"/></svg>
                </div>
            </div>

            
            
            
            
            
            <div className='overview-section' style={{padding: '0px 32px'}}>
                <div className="charge-div-border">
                    <div>
                        <h3 className="mb-0 clr-707070">Charger Detail</h3>
                        <p className="clr-707070 mb-2">Charger details and address</p>
                    </div>
                </div>

                <div className='station-field pt-4'>
                    <div className='station-field-div1'>
                        <h5 className="clr-707070">Charger ID</h5>
                        {/* <p></p> */}
                    </div>
                    <div className="width-35-percent">
                        <input className='form-control input-read clr-707070 ps-0'  defaultValue={data?.charger?.chargerID} readOnly placeholder='CHARGER-ID-1'/>
                    </div>
                </div>
                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5 className="clr-707070">Charger Name</h5>
                        {/* <p>This will be displayed to customer.</p> */}
                    </div>
                    <div className="width-35-percent">
                        <input className='form-control input-read ps-0'  defaultValue={data?.charger?.chargerName} readOnly placeholder='NAME-1' />
                    </div>
                </div>
                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5 className="clr-707070">Address</h5>
                        {/* <p>This will be displayed to customer.</p> */}
                    </div>
                    <div className="width-35-percent">
                        <input className='form-control input-read ps-0'  defaultValue={data?.charger?.chargerAddress} readOnly  placeholder='111/111 mittraparb road naimuang muang korat 30000'/>
                    </div>
                </div>

                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5 className="clr-707070">Location</h5>
                        <p className="clr-707070">Location of this Charger</p>
                    </div>
                    <div>
                        <div className='station-field-location'>
                            <div>
                                <h6 className="clr-707070">Latitude</h6>
                                <input className='ps-0 form-control input-read' defaultValue={data?.charger?.chargerlocationLat} placeholder='14.044750' name='lat' onChange={handleLocation}/>
                            </div>
                            <div>
                                <h6 className="clr-707070">Longitude</h6>
                                <input className='ps-0 form-control input-read' defaultValue={data?.charger?.chargerlocationLong} placeholder='100.591728' name='lng' onChange={handleLocation}/>
                            </div>
                        </div>
                        <div className='station-map station-map-desk'>
                            {/* <GoogleMap lat={location.lat} lng={location.lng} width={'40%'} height={'40%'}/> */}
                            {/* {station ? ( */}
                                <GoogleMap type={5} lat={parseFloat(data?.charger?.chargerlocationLat)} lng={parseFloat(data?.charger?.chargerlocationLong)} width={'40%'} height={'380px'}/>
                            {/* ) : (
                                <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '3vh'}}>
                                    <BallTriangle
                                        height={70}
                                        width={70}
                                        radius={5}
                                        color="#814097"
                                        ariaLabel="ball-triangle-loading"
                                        wrapperClass={{}}
                                        wrapperStyle=""
                                        visible={true}
                                    />
                                </div>
                            )} */}
                        </div>
                    </div>
                </div>


                <div className='w-100 station-map-mob' style={{overflow:'hidden'}}>
                    <div className='station-map '>
                        {/* <GoogleMap lat={location.lat} lng={location.lng} width={'40%'} height={'40%'}/> */}
                        {/* {station ? ( */}
                        <GoogleMap lat={15.8700} lng={100.9925} width={'40%'} height={'40%'}/>
                    </div>
                </div>
                

                <div className="charge-log mt-5">
                    <div className="d-flex charge-div-border">
                        <div className="w-75">
                            <h3 className="mb-0">Charger Information</h3>
                            <p className="mb-2">The information of the charger</p>
                        </div>
                        <div className="w-25">
                            <div className="float-end pt-md-4 pt-2 mt-2">
                                <button className="arrow-btns">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10.56" height="18.471" viewBox="10.166 6.257 10.56 18.471"><path d="m13.349 15.49 6.989-6.984a1.314 1.314 0 0 0 0-1.864 1.326 1.326 0 0 0-1.87 0l-7.918 7.913c-.5.5-.511 1.303-.038 1.82l7.95 7.968c.26.258.6.385.936.385.335 0 .676-.127.934-.385a1.314 1.314 0 0 0 0-1.864l-6.983-6.99Z" fill="#b2b2b2" fill-rule="evenodd" data-name="Icon ionic-ios-arrow-back"/></svg>
                                </button>
                                <button className="arrow-btns arrow-right-rotate ms-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10.56" height="18.471" viewBox="10.166 6.257 10.56 18.471"><path d="m13.349 15.49 6.989-6.984a1.314 1.314 0 0 0 0-1.864 1.326 1.326 0 0 0-1.87 0l-7.918 7.913c-.5.5-.511 1.303-.038 1.82l7.95 7.968c.26.258.6.385.936.385.335 0 .676-.127.934-.385a1.314 1.314 0 0 0 0-1.864l-6.983-6.99Z" fill="#b2b2b2" fill-rule="evenodd" data-name="Icon ionic-ios-arrow-back"/></svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="mt-2">
                        <div className="overview-sec4">
                            <div className="d-flex w-100">
                                
                                
                                <div className="w-20 pe-3">
                                    <h5 className="mb-2">Substation (1)</h5>
                                    <div className="scrollable-box">
                                        <h6 onClick={() => navigate(`/substationdetail/${data?.charger?.substation?.id}`)} className="mb-1 pointer">{data?.charger?.substation?.substationsID}</h6>

                                    </div>                                                                        
                                </div>
                                <div className="w-20 pe-3">
                                    <h5 className="mb-2">Feeder (1)</h5>
                                    <div className="scrollable-box">
                                        <h6 onClick={() => navigate(`/feederdetail/${data?.charger?.feeder?.id}`)} className="mb-1 pointer">{data?.charger?.feeder?.feederID}</h6>

                                    </div>                                                                        
                                </div>

                                <div className="w-20 pe-3">
                                    <h5 className="mb-2">Transformer (1)</h5>
                                    <div className="scrollable-box">
                                        <h6 onClick={() => navigate(`/transformerdetail/${data?.charger?.transformer?.id}`)} className="mb-1 pointer">{data?.charger?.transformer?.transID}</h6>

                                    </div>                                                                        
                                </div>

                                <div className="w-20 pe-3">
                                    <h5 className="mb-2">Meter (1)</h5>
                                    <div className="scrollable-box">
                                        <h6 onClick={() => navigate(`/meterdetail/${data?.charger?.meter?.id}`)} className="mb-1 pointer">{data?.charger?.meter?.meterID}</h6>

                                    </div>                                                                        
                                </div>
                                
                                <div className="w-20 pe-3">
                                    <h5 className="mb-2">Charger (1)</h5>
                                    <div className="scrollable-box">
                                        <h6 onClick={() => navigate(`/chargerdetail/${data?.charger?.id}`)} className="mb-1 pointer">{data?.charger?.chargerID}</h6>

                                    </div>                                                                        
                                </div>

                                {/* <div className="w-20 pe-3">
                                    <h4 className="mb-2">Limited capacity (Volt)</h4>
                                    <h4 className="mb-2">Substation ID</h4>
                                    <h4 className="mb-3">Feeder ID</h4>                                    
                                    <h4 className="mb-3">Transformer ID</h4>                                    
                                    <h4 className="mb-3">Meter ID</h4>                                    
                                    
                                </div>
                                <div className="w-20 pe-21">
                                    <h4 className="mb-2">5000</h4>
                                    <h4 className="mb-2">{data?.data?.substationsID}</h4>
                                    <h4 className="mb-3">{data?.data?.feederID}</h4>
                                    <h4 className="mb-3">{data?.data?.transID}</h4>
                                    <h4 className="mb-3">{data?.data?.meterId}</h4>
                                    
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>



                <div className='overview-section-heading' style={{marginTop: '22px'}}>
                    <h4 className='haeding-style-charger-info'>Charger Detail</h4>
                    <p>The information of the charger and connector types.</p>
                </div>
                <div className='overview-section'>
                    <div className='station-field'>
                        <div className='station-field-div1'>
                            <h5>Charger Detail</h5>
                        </div>
                        <div>
                            {/* <div className="d-flex">
                                <div>
                                    <p>Charger name</p>
                                    <input className='form-control input-read ps-0 pt-0' readOnly defaultValue={station?.locationTH} placeholder='PEA VOLTA #01'/>
                                </div>
                                <div>
                                    <p>Charger ID</p>
                                    <input className='form-control input-read ps-0 pt-0' readOnly defaultValue={data?.data?.chargerId} placeholder='PEA-1129'/>
                                </div>
                                <div>
                                    <p>Charger Number</p>
                                    <input className='form-control input-read ps-0 pt-0' readOnly defaultValue={station?.number} placeholder='P101'/>
                                </div>

                            </div> */}
                            {/* <div className='station-field-location'>
                            </div> */}
                            <div className='station-field-locationx d-flex'>
                                <div>
                                    <p>Brand</p>
                                    <input className='form-control input-read ps-0 pt-0' readOnly  placeholder='AlphaEV'/>
                                </div>
                                <div>
                                    <p>Model</p>
                                    <input className='form-control input-read ps-0 pt-0' readOnly  placeholder='A129'/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='station-field'>
                        <div className='station-field-div1'>
                            <h5>Connector Type</h5>
                            {/* <p>required *</p> */}
                        </div>
                        
                        <div className='connector-type-divv' style={{width: '55%'}}>
                            {data?.charger?.connectors?.map((item, index) => (
                                <div className='connector-display-div d-flex justify-content-betweenx mb-3' key={index}>
                                    <div className='div1 py-4'>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="63.5" height="50.758" viewBox="881.5 2833.154 63.5 50.758"><path d="M941.46 2837.426a7.862 7.862 0 0 0-7.015-4.272h-42.389a7.89 7.89 0 0 0-7.037 4.309c-2.314 4.5-3.498 9.362-3.519 14.448-.035 8.784 3.47 17 9.875 23.132 5.934 5.685 13.855 8.869 21.945 8.869.523 0 1.045-.014 1.57-.04 16.884-.862 30.11-14.843 30.11-31.827 0-5.151-1.19-10.07-3.54-14.62Zm-19.5.77c2.682 0 4.866 2.19 4.866 4.884 0 2.693-2.184 4.884-4.867 4.884s-4.866-2.19-4.866-4.884c0-2.693 2.183-4.885 4.866-4.885Zm-17.42 0c2.684 0 4.867 2.19 4.867 4.884 0 2.693-2.183 4.884-4.866 4.884-2.684 0-4.867-2.19-4.867-4.884 0-2.693 2.183-4.885 4.867-4.885Zm-16.886 16.814c0-3.863 3.131-7.006 6.98-7.006 3.848 0 6.98 3.143 6.98 7.006 0 3.863-3.132 7.006-6.98 7.006-3.849 0-6.98-3.143-6.98-7.006Zm17.117 20.81c-3.85 0-6.981-3.144-6.981-7.007 0-3.863 3.131-7.006 6.98-7.006 3.848 0 6.98 3.143 6.98 7.006 0 3.863-3.132 7.007-6.98 7.007Zm8.479-13.804c-3.85 0-6.98-3.143-6.98-7.006 0-3.863 3.13-7.006 6.98-7.006 3.849 0 6.98 3.143 6.98 7.006 0 3.863-3.131 7.006-6.98 7.006Zm8.48 13.804c-3.849 0-6.979-3.144-6.979-7.007 0-3.863 3.13-7.006 6.98-7.006 3.847 0 6.979 3.143 6.979 7.006 0 3.863-3.132 7.007-6.98 7.007Zm10.137-13.804c-3.849 0-6.98-3.143-6.98-7.006 0-3.863 3.131-7.006 6.98-7.006 3.848 0 6.98 3.143 6.98 7.006 0 3.863-3.132 7.006-6.98 7.006Z" fill="#00a651" fill-rule="evenodd" data-name="Path 25"/></svg> */}
                                        {item?.connectorName === "Type 2" ? (
                                            <svg height="102px" viewBox="0 0 24 24" width="95px" xmlns="http://www.w3.org/2000/svg"><path fill='#0E8D06' d="m7.5 11c0 .28-.22.5-.5.5s-.5-.22-.5-.5.22-.5.5-.5.5.22.5.5m2 3.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5m0-6.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5m5 0c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5m-2.5 2.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5m8 .5c0 4.42-3.58 8-8 8s-8-3.58-8-8c0-2.39 1.06-4.53 2.72-6h10.56c1.66 1.47 2.72 3.61 2.72 6m-7-3.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5-1.5.67-1.5 1.5m-5 0c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5-1.5.67-1.5 1.5m-1 5.5c1.11 0 2-.89 2-2 0-1.1-.89-2-2-2s-2 .9-2 2c0 1.11.9 2 2 2m4.5 2c0-1.1-.89-2-2-2s-2 .9-2 2c0 1.11.9 2 2 2s2-.89 2-2m.5-2c1.11 0 2-.89 2-2 0-1.1-.89-2-2-2s-2 .9-2 2c0 1.11.9 2 2 2m4.5 2c0-1.1-.89-2-2-2s-2 .9-2 2c0 1.11.9 2 2 2s2-.89 2-2m2.5-4c0-1.1-.89-2-2-2s-2 .9-2 2c0 1.11.9 2 2 2s2-.89 2-2m-2-.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5m-2.5 4c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5z"/></svg>
                                        ) : item?.connectorName === "Type 1" ? (                                        
                                            <svg height="102px" viewBox="0 0 24 24" width="95px" xmlns="http://www.w3.org/2000/svg"><path fill='#0E8D06' d="m12.5 15c0 .28-.22.5-.5.5s-.5-.22-.5-.5.22-.5.5-.5.5.22.5.5m2.5-4.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5m-6 0c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5m4 8.42v1.08h-2v-1.08c-3.39-.49-6-3.39-6-6.92s2.61-6.43 6-6.92v-1.08h2v1.08c3.39.49 6 3.42 6 6.92s-2.61 6.43-6 6.92m0-8.92c0 1.11.9 2 2 2s2-.89 2-2-.89-2-2-2-2 .9-2 2m-6 0c0 1.11.9 2 2 2s2-.89 2-2-.89-2-2-2-2 .9-2 2m2.5 4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5.67 1.5 1.5 1.5 1.5-.67 1.5-1.5m4.5 1c0-1.1-.89-2-2-2s-2 .9-2 2 .9 2 2 2 2-.89 2-2m2 .5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5-1.5.67-1.5 1.5.67 1.5 1.5 1.5z"/></svg>
                                        ) : item?.connectorName === "CHAdeMO" ? (
                                            <svg height="102px" viewBox="0 0 24 24" width="95px" xmlns="http://www.w3.org/2000/svg"><path fill='#0E8D06' d="m8 10.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5-1.5-.67-1.5-1.5.67-1.5 1.5-1.5m0-1.5c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3m8 1.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5-1.5-.67-1.5-1.5.67-1.5 1.5-1.5m0-1.5c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3m-1.88-.59-1.41-1.41 1.41-1.41-.71-.71-1.41 1.41-1.41-1.41-.71.71 1.41 1.41-1.41 1.41.71.71 1.41-1.41 1.41 1.41zm0 10-1.41-1.41 1.41-1.41-.71-.71-1.41 1.41-1.41-1.41-.71.71 1.41 1.41-1.41 1.41.71.71 1.41-1.41 1.41 1.41zm5.62-12.74.76-.74-1.43-1.43-.74.76c-1.73-1.41-3.93-2.26-6.33-2.26s-4.6.85-6.33 2.26l-.74-.76-1.43 1.43.76.74c-1.41 1.73-2.26 3.93-2.26 6.33 0 5.5 4.5 10 10 10s10-4.5 10-10c0-2.4-.85-4.6-2.26-6.33m-7.74 14.33c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>
                                        ) : item?.connectorName === "CCS2" ? (
                                            <svg height="102px" viewBox="0 0 24 24" width="95px" xmlns="http://www.w3.org/2000/svg"><path fill='#0E8D06' d="m14 4.5c0-.28.22-.5.5-.5s.5.22.5.5-.22.5-.5.5-.5-.22-.5-.5m6 14.5c0 2.21-1.79 4-4 4h-8c-2.21 0-4-1.79-4-4s1.79-4 4-4h.13c-2.46-1.36-4.13-4-4.13-7 0-2.39 1.06-4.53 2.72-6h10.56c1.66 1.47 2.72 3.61 2.72 6 0 3-1.67 5.64-4.13 7h.13c2.21 0 4 1.79 4 4m-3.5-7c0-1.1-.89-2-2-2s-2 .9-2 2c0 1.11.9 2 2 2s2-.89 2-2m.5-6c-1.1 0-2 .9-2 2 0 1.11.9 2 2 2s2-.89 2-2c0-1.1-.89-2-2-2m-4-1.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5-1.5.67-1.5 1.5m-1 1.5c-1.1 0-2 .9-2 2 0 1.11.9 2 2 2s2-.89 2-2c0-1.1-.89-2-2-2m-4-1.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5-1.5.67-1.5 1.5m-1 5.5c1.11 0 2-.89 2-2 0-1.1-.89-2-2-2s-2 .9-2 2c0 1.11.9 2 2 2m2.5 4c1.11 0 2-.89 2-2 0-1.1-.89-2-2-2s-2 .9-2 2c0 1.11.9 2 2 2m1.5 5c0-1.66-1.34-3-3-3s-3 1.34-3 3 1.34 3 3 3 3-1.34 3-3m8 0c0-1.66-1.34-3-3-3s-3 1.34-3 3 1.34 3 3 3 3-1.34 3-3m-2-10.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5m-2.5 3c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5m1.5 6.5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1m-4-9.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5m-4 9.5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1m-1-10.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5m2.5-2.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5m.5 7c0-.28-.22-.5-.5-.5s-.5.22-.5.5.22.5.5.5.5-.22.5-.5z"/></svg>
                                        ) : item?.connectorName === "CCS1" ? (
                                            <svg height="102px" viewBox="0 0 24 24" width="99px" xmlns="http://www.w3.org/2000/svg"><path fill='#0E8D06' d="m16 15h-.41c2.04-1.22 3.41-3.45 3.41-6 0-3.53-2.61-6.43-6-6.92v-1.131h-2v1.131c-3.39.49-6 3.39-6 6.92 0 2.55 1.37 4.78 3.41 6h-.41c-2.21 0-4 1.79-4 4s1.79 4 4 4h8c2.21 0 4-1.79 4-4s-1.79-4-4-4m1.5-4c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5.67-1.5 1.5-1.5 1.5.67 1.5 1.5m-2.5-6c1.11 0 2 .9 2 2s-.89 2-2 2-2-.89-2-2 .9-2 2-2m-6 0c1.11 0 2 .9 2 2s-.89 2-2 2-2-.89-2-2 .9-2 2-2m-1 7.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5m1 9.32v-.82h-2v.82c-1.16-.42-2-1.52-2-2.82 0-1.66 1.34-3 3-3s3 1.34 3 3c0 1.3-.84 2.4-2 2.82m1-9.82c0-1.1.9-2 2-2s2 .9 2 2-.89 2-2 2-2-.89-2-2m7 9.82v-.82h-2v.82c-1.16-.42-2-1.52-2-2.82 0-1.66 1.34-3 3-3s3 1.34 3 3c0 1.3-.84 2.4-2 2.82m-2.5-14.82c0-.28.22-.5.5-.5s.5.22.5.5-.22.5-.5.5-.5-.22-.5-.5m-6 0c0-.28.22-.5.5-.5s.5.22.5.5-.22.5-.5.5-.5-.22-.5-.5m.5 12c0 .55-.45 1-1 1s-1-.45-1-1 .45-1 1-1 1 .45 1 1m8 0c0 .55-.45 1-1 1s-1-.45-1-1 .45-1 1-1 1 .45 1 1m-4.5-7c0 .28-.22.5-.5.5s-.5-.22-.5-.5.22-.5.5-.5.5.22.5.5z"/></svg>
                                        ) : (                       
                                            <>
                                                {/* <img src={item?.name === "Type 1" || item?.name === "Type 2"? `https://volta-webapp.pea.co.th/images/mapIconMobile/acT2Available.png` : item?.name === "CCS1" || item?.name === "CCS2" ? `https://volta-webapp.pea.co.th/images/mapIconMobile/CCS2Available.png` : `https://volta-webapp.pea.co.th/images/mapIconMobile/CHAdeMOAvailable.png`} className="me-2" style={{height: '42px', width: '40px', position:'relative', top: '-3px'}} /> */}
                                            </>                 
                                        )}
                                    </div>
                                    <div className="pt-1">
                                        <p className='connector-name mb-0 mt-1'>Connector ID : {item?.connectorID}</p>
                                        <p className='connector-name mb-0 mt-0'>{item?.connectorName}</p>
                                        <p className='connector-type mb-0'>Type : {item?.connectorType}</p>
                                        <p className='connector-type mb-0'>Rated Power : {`${item?.connectorPower} ${data?.charger?.chargerType}`}</p>
                                        {/* <div className='d-flex'>
                                            <div className='pe-3'>
                                                <p className='connector-type mb-0'>Rated Power : </p>
                                            </div>
                                            <div className='w-25'>
                                                <input type='number' className='form-control'  placeholder='40' />
                                            </div>
                                            <div>
                                                <p className='connector-type mb-0 ms-2 mb-0'> kW</p>
                                            </div>
                                        </div> */}
                                    </div>
                                    {/* <div className="pe-3 pt-5">
                                        <div className='d-flex pe-2 mt-1'>
                                            <div className='pe-3'>
                                                <p className='connector-type mb-0'>Rated Power : </p>
                                            </div>
                                            <div>
                                                <p className='connector-type mb-0 ms-2 mb-0'> {item?.connectorPower} kW</p>
                                            </div>
                                        </div>

                                    </div> */}
                                </div>
                            ))}                           
                        </div>
                    </div>
                </div>



                <div className="charge-log">
                    <div className="d-flex charge-div-border">
                        <div className="w-50">
                            <h3 className="mb-0">Charge Log</h3>
                            <p className="mb-2">status history</p>
                        </div>
                        <div className="w-50">
                            <div className="float-endx d-flex justify-content-end w-100 pt-md-4 pt-2 mt-2">
                                <div className="pt-2">
                                    <span>Choose time :</span>
                                </div>
                                <div className="mx-3">
                                    <select onChange={(e) => setFilter(e.target.value)} style={{width: '110px'}} className="form-control d-inline">
                                        <option value="today">Today</option>
                                        <option value="week">This Week</option>
                                        <option value="month">This Month</option>
                                        <option value="year">This Year</option>
                                    </select>
                                </div>
                                <div>
                                    <button onClick={() => handleDownloadExcel(filter === 'today' ? tableData?.today : filter === 'week' ? tableData?.week : filter === "month" ? tableData?.month : tableData?.year)} className="exprt-btn">Export</button>
                                </div>
                                {/* <button className="arrow-btns">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10.56" height="18.471" viewBox="10.166 6.257 10.56 18.471"><path d="m13.349 15.49 6.989-6.984a1.314 1.314 0 0 0 0-1.864 1.326 1.326 0 0 0-1.87 0l-7.918 7.913c-.5.5-.511 1.303-.038 1.82l7.95 7.968c.26.258.6.385.936.385.335 0 .676-.127.934-.385a1.314 1.314 0 0 0 0-1.864l-6.983-6.99Z" fill="#b2b2b2" fill-rule="evenodd" data-name="Icon ionic-ios-arrow-back"/></svg>
                                </button>
                                <button className="arrow-btns arrow-right-rotate ms-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10.56" height="18.471" viewBox="10.166 6.257 10.56 18.471"><path d="m13.349 15.49 6.989-6.984a1.314 1.314 0 0 0 0-1.864 1.326 1.326 0 0 0-1.87 0l-7.918 7.913c-.5.5-.511 1.303-.038 1.82l7.95 7.968c.26.258.6.385.936.385.335 0 .676-.127.934-.385a1.314 1.314 0 0 0 0-1.864l-6.983-6.99Z" fill="#b2b2b2" fill-rule="evenodd" data-name="Icon ionic-ios-arrow-back"/></svg>
                                </button> */}
                            </div>
                        </div>
                    </div>
                    <div className="mt-2">
                        <DataTableBase
                            pagination 
                            // title={"Error Home Location"}
                            columns={columns}
                            data={filter === 'today' ? tableData?.today : filter === 'week' ? tableData?.week : filter === "month" ? tableData?.month : tableData?.year}
                        />
                    </div>
                </div>


            
            </div>

            {/* {user != 1 && (
                <AddHomeForm type={1} />
            )} */}
        </>
    )}
}

export default ChargerDetail;