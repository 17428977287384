import React, { useState } from "react";

import { useForm} from 'react-hook-form';


import loginImage from '../../../../assets/pea volta home-01.png';
import { useNavigate } from "react-router-dom";

const ClientLogin = () => {
    const [error, setError] = useState(null);
    const {register, handleSubmit} = useForm();

    const navigate = useNavigate();

    const onSubmit = (data) => {
     if(data?.email === "home@pea.com" && data?.password === "1234"){
        localStorage.setItem("pea_client", "logged-in")
        localStorage.setItem("nav", "1");
        navigate('/home');
     } else {
        setError("Invalid Email or password!"); 
     }   
    }

    return (
        <div className="admin-login-page">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="login-divv  client-login-divv">
                    <img src={loginImage} alt="company-logo" />
                    <div className="d-flex mt-3">
                        <div className="login-icon-div">
                            <div className="me-3 pt-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="34.875" height="34.875" viewBox="757 492.563 34.875 34.875"><path d="M774.438 492.563c-9.633 0-17.438 7.804-17.438 17.437 0 9.633 7.805 17.438 17.438 17.438 9.632 0 17.437-7.805 17.437-17.438s-7.805-17.438-17.438-17.438Zm0 6.75a6.188 6.188 0 1 1 0 12.375 6.188 6.188 0 0 1 0-12.375Zm0 24.187c-4.128 0-7.826-1.87-10.301-4.795 1.322-2.49 3.91-4.205 6.926-4.205.168 0 .337.028.499.077.914.296 1.87.486 2.875.486 1.006 0 1.97-.19 2.876-.486.162-.049.33-.077.5-.077 3.016 0 5.603 1.716 6.925 4.205-2.475 2.925-6.173 4.795-10.3 4.795Z" fill="#814097" fill-rule="evenodd" data-name="Icon awesome-user-circle"/></svg>
                            </div>
                            <div className="me-3 pt-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="31.5" height="36" viewBox="760.375 565 31.5 36"><path d="M788.5 583h-17.438v-7.25c0-2.784 2.23-5.097 5.014-5.125a5.07 5.07 0 0 1 5.111 5.063v1.125c0 .935.753 1.687 1.688 1.687h2.25c.935 0 1.688-.752 1.688-1.688v-1.125c0-5.906-4.817-10.708-10.723-10.687-5.906.021-10.653 4.887-10.653 10.793V583h-1.687a3.376 3.376 0 0 0-3.375 3.375v11.25A3.376 3.376 0 0 0 763.75 601h24.75a3.376 3.376 0 0 0 3.375-3.375v-11.25A3.376 3.376 0 0 0 788.5 583Z" fill="#814097" fill-rule="evenodd" data-name="Icon awesome-unlock"/></svg>
                            </div>
                        </div>
                        <div className="login-input-div">
                            <div className="mb-3 mt-4">
                                <input type="text" {...register("email")} className="form-control w-100" placeholder="Enter your username email" />
                            </div>
                            <div className="mb-3">
                                <input type="password" {...register("password")} className="form-control" placeholder="Enter your password" />
                            </div>
                            <button className="admin-login-btn">LOGIN</button>
                            <p className="forget-pass">forget password ?</p>
                            {error && <p className="text-danger my-3">{error}</p>}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ClientLogin;