import React from "react";
import Overview from "./Overview";

const ErrorHomeNotifications = () => {
    return (
        <>
            <Overview type={1} />
        </>
    )
}

export default ErrorHomeNotifications;