import React, { useEffect } from "react";
import Sidebar from "../../common/Sidebar";
import Navbar from "../../common/Navbar";
import { Outlet, useNavigate } from "react-router-dom";

const Admin = () => {
    const navigate = useNavigate();


    useEffect(() => {
        const token = localStorage.getItem('pea_admin');
        if(token === null){
          navigate('/adminlogin');
        }
    }, [])
      


    return (
        <>
            <div className="App">
                <Sidebar />
                <div className='content-div'>
                    <Navbar />
                    <Outlet />                    
                </div>
          </div>
        </>
    )
}


export default Admin;