import React, { useEffect, useState } from "react";
import ColumnGraph from "./ColumnGraph";
import DatePicker from 'react-datepicker';
import Circle from "./Circle";
import RealTimeChart from "./RealTimeChart";
import axios from "axios";
import { apiEndPoint, formatDate, getCurrentMonthName, getCurrentYear } from "../../utils";
import { BallTriangle } from "react-loader-spinner";

// const WeekLabel = ['Mon', 'Sun', 'Sat', 'Fri', 'Thu', 'Wed', 'Tue'];
const dayLabel = ['12', '1 AM', '2 AM', '3 AM', '4 AM', '5 AM', '6 AM', '7 AM', '8 AM', '9 AM', '10 AM', '11 AM', '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM', '6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM'];
const dayLabel3 = ['12:00 - 12:15 AM ', '12:15 - 12:30 AM ', '12:30 - 12:45 AM ', '12:45 - 1:00 AM ', '1:00 - 1:15 AM', '1:15 - 1:30 AM', '1:30 - 1:45 AM', '1:45 - 2:00 AM', '2:00 - 2:15 AM', '2:15 - 2:30 AM', '2:30 - 2:45 AM', '2:45 - 3:00 AM', '3:00 - 3:15 AM', '3:15 - 3:30 AM', '3:30 - 3:45 AM', '3:45 - 4:00 AM', '4:00 - 4:15 AM', '4:15 - 4:30 AM', '4:30 - 4:45 AM', '4:45 - 5:00 AM', '5:00 - 5:15 AM', '5:15 - 5:30 AM', '5:30 - 5:45 AM', '5:45 - 6:00 AM', '6:00 - 6:15 AM', '6:15 - 6:30 AM', '6:30 - 6:45 AM', '6:45 - 7:00 AM', '7:00 - 7:15 AM', '7:15 - 7:30 AM', '7:30 - 7:45 AM',  '7:45 - 8:00 AM', '8:00 - 8:15 AM', '8:15 - 8:30 AM', '8:30 - 8:45 AM', '8:45 - 9:00 AM', '9:00 - 9:15 AM', '9:15 - 9:30 AM', '9:30 - 9:45 AM', '9:45 - 10:00 AM', '10 AM', '11 AM', '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM', '6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM'];
const dayLabel2 = ['00',' ', ' ', ' ', '1',' ', ' ', ' ', '2',' ', ' ', ' ', '3',' ', ' ', ' ', '4',' ', ' ', ' ', '5',' ', ' ', ' ', '6',' ', ' ', ' ', '7',' ', ' ', ' ', '8',' ', ' ', ' ', '9',' ', ' ', ' ', '10',' ', ' ', ' ', '11',' ', ' ', ' ', '12','', '', '', '13','', '', '', '14','', '', '', '15','', '', '', '16','', '', '', '17','', '', '', '18','', '', '', '19','', '', '', '20','', '', '', '21','', '', '', '22','', '', '', '23','', '', '',];
// const yearLabel = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
// const yearLabel = ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6', 'Week 7', 'Week 8', 'Week 9', 'Week 10', 'Week 11', 'Week 12', 'Week 13', 'Week 14', 'Week 15', 'Week 16', 'Week 17', 'Week 18', 'Week 19', 'Week 20', 'Week 21', 'Week 22', 'Week 23', 'Week 24', 'Week 25', 'Week 26', 'Week 27', 'Week 28', 'Week 29', 'Week 30', 'Week 31', 'Week 32', 'Week 33', 'Week 34', 'Week 35', 'Week 36', 'Week 37', 'Week 38', 'Week 39', 'Week 40', 'Week 41', 'Week 42', 'Week 43', 'Week 44', 'Week 45', 'Week 46', 'Week 47', 'Week 48', 'Week 49', 'Week 50', 'Week 51', 'Week 52'];
const yearLabel = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52'];
const weekLabel = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60', '61', '62', '63', '64', '65', '66', '67', '68', '69', '70', '71', '72', '73', '74', '75', '76', '77', '78', '79', '80', '81', '82', '83', '84', '85', '86', '87', '88', '89', '90', '91', '92', '93', '94', '95', '96', '97', '98', '99', '100', '101', '102', '103', '104', '105', '106', '107', '108', '109', '110', '111', '112', '113', '114', '115', '116', '117', '118', '119', '120', '121', '122', '123', '124', '125', '126', '127', '128', '129', '130', '131', '132', '133', '134', '135', '136', '137', '138', '139', '140', '141', '142', '143', '144', '145', '146', '147', '148', '149', '150', '151', '152', '153', '154', '155', '156', '157', '158', '159', '160', '161', '162', '163', '164', '165', '166', '167', '168'];
// const WeekLabel = [];
const dateFormat1 = (dt) => {
    const date = new Date(dt);
    // Using toLocaleDateString()
    const formattedDate = date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: '2-digit' });
    // console.log(formattedDate); // Output: "Feb 20, 2024"
    return formattedDate;
}
 
const dateFormat2 = (dt) => {
    const date = new Date(dt);
    // Using toLocaleDateString()
    const formattedDate = date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: '2-digit' });
    // console.log(formattedDate); // Output: "Feb 20, 2024"
    return date?.getFullYear();
}

function calculatePercentageChange(currentMonthSales, lastYearMonthSales) {
    // Calculate the difference between current month sales and last year month sales
    let difference = currentMonthSales - lastYearMonthSales;
    // Calculate the percentage change
    let percentageChange = (difference / lastYearMonthSales) * 100;
    return percentageChange;
}

const EnergyUsed = ({type, lab1, lab2, url, setTableData, feeder, apiData, meter, max, charger, chargerUnit}) => {
    const [tog, setTog] = useState(1);
    const [tog2, setTog2] = useState(1);
    // const [percentage, setPercentage] = useState(1);
    const [data, setData] = useState({});
    // const [selectedDate, setSelectedDate] = useState(null);

    const [lastYearData, setLastYearData] = useState({});

    const [weekDateLabel, setWeekDateLabel] = useState([]);

    // const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);

    const [monthLabel, setMonthLabel] = useState([]);
    
    // const [flag, setFlag] = useState(2);
    const [labs, setLabs] = useState({});
    const [chartData, setChartData] = useState({});


    const [powerChartData, setPowerChartData] = useState([]);
    const [powerData, setPowerData] = useState([]);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const generateLabels = (dataArray) => {
        const arr = [];
        dataArray?.map(item => {
          arr?.push(item?.x);
        })
        // console.log("label array : ", arr);
        return arr;
    };

    
    function findAveragePeak(data){
        let sum = 0;
        data?.map(item => {
            sum = sum + parseFloat(item?.y);
        })
        const avg = data?.length > 0 ? sum / data?.length : 0;

        return avg;
    }

    function findPeakPoint(data){
        let point = 0;
        data?.map(item => {
            // console.log("point : ", point);
            // console.log("item y : ", item?.y);
            if(Number(item?.y) > Number(point)){
                // console.log("true");
                point = item?.y;
            }
        })
        return point;
    }


    useEffect(() => {
        const token = localStorage.getItem("pea_admin");

        axios.get(`${apiEndPoint}/api/admin/power/${url}/year`, {
            headers: {Authorization: `Bearer ${token}`}
        }).then((res) => {
            console.log("Power api response : ", res);

            // setApiData(res?.data);

            // Get today's date in UTC string format
            const today = new Date().toISOString().split('T')[0];
            console.log("today today : ", today)
            // Filter data for today
            // const dataForToday = res?.data[0]?.filter(item => item?.reportTime?.startsWith(today));
            const dataForToday = res?.data[0]?.filter(item => item?.reportTime?.startsWith(today));
            console.log("data for today : ", dataForToday);
    


            // Get start of current week
            const startOfWeek = new Date();
            startOfWeek?.setDate(startOfWeek.getDate() - startOfWeek.getDay()); // Get Sunday of this week
            const startOfWeekString = startOfWeek?.toISOString()?.split('T')[0];
    
            // Filter data for this week
            // const dataForThisWeek = res?.data[0]?.filter(item => item?.reportTime >= startOfWeekString);    
            const dataForThisWeek = res?.data[0]?.filter(item => item?.reportTime >= startOfWeekString);    
            // console.log("data for this week : ", dataForThisWeek);




            // Get start of current month
            const startOfMonth = new Date();
            startOfMonth?.setDate(1); // Set to 1st day of the month
            const startOfMonthString = startOfMonth?.toISOString()?.split('T')[0];
            
            // const dataForThisMonth = res?.data[0]?.filter(item => item?.reportTime >= startOfMonthString);
            const dataForThisMonth = res?.data[0]?.filter(item => item?.reportTime >= startOfMonthString);
            // console.log("data for this month : ", dataForThisMonth);




            // Get start of current year
            const startOfYear = new Date();
            startOfYear?.setMonth(0, 1); // Set to 1st day of January
            const startOfYearString = startOfYear?.toISOString()?.split('T')[0];
            
            // const dataForThisYear = res?.data[0]?.filter(item => item?.reportTime >= startOfYearString);
            const dataForThisYear = res?.data[0]?.filter(item => item?.reportTime >= startOfYearString);
            // console.log("Data for this year : ", dataForThisYear);



            const calculateSum = (dataArray) => {
                return dataArray?.reduce((sum, item) => sum + parseFloat(item?.power), 0);
            };

            const calculateSum2 = (dataArray) => {
                // Multiply the length of the dataArray by 4
                const newDataArray = Array.from({ length: dataArray?.length * 4 });
            
                // Get the first value of the first 4 quarters
                for (let i = 0; i < 4; i++) {
                    const quarterStartIndex = i * (dataArray?.length / 4);
                    const quarterEndIndex = (i + 1) * (dataArray?.length / 4);
                    const quarterData = dataArray?.slice(quarterStartIndex, quarterEndIndex);
            
                    if (quarterData?.length > 0) {
                        newDataArray[i] = quarterData[0]?.power;
                    } else {
                        newDataArray[i] = 0;
                    }
                }
            
                // Sum the values in newDataArray
                return newDataArray.reduce((sum, item) => sum + parseFloat(item), 0);
            };

            const calculateFirstValue = (dataArray, quarterHourStart, quarterHourEnd) => {
                // data.filter(item => new Date(item?.reportTime) >= quarterHourStart && new Date(item?.reportTime) < quarterHourEnd)
                for(let i=0; i < dataArray?.length; i++){
                    if(new Date(dataArray[i]?.reportTime) >= quarterHourStart && new Date(dataArray[i]?.reportTime) < quarterHourEnd){
                        return dataArray[i]?.power;
                        break;
                    }
                }

                return 0;
                // return dataArray?.find(item => item?.power !== undefined)?.power;
            };


            function firstValueOfDay(data, quarterHourStart, quarterHourEnd){
                let power = 0;
                for(let i = 0; i < data?.length; i++){
                    if(new Date(data[i]?.reportTime) >= quarterHourStart && new Date(data[i]?.reportTime) < quarterHourEnd){ 
                        // console.log("-------");
                        // console.log("new Date(item?.reportTime) : ", new Date(data[i]?.reportTime));
                        // console.log("quarterHourStart : ", quarterHourStart);
                        // console.log("quarterHourEnd", quarterHourEnd);
                        // console.log("-------");
                        // console.log("true : ", data[i]?.power);
                        if(power < Number(data[i]?.power)){
                            power = Number(data[i]?.power);
                        }
                        // break;
                    }
                }
                if(chargerUnit === "AMP"){
                    return ((power * 1000) / 230).toFixed(2);
                } else {
                    return power.toFixed(2);
                }                    
            }
            
            function firstValueOfWeek(data, hourStart, hourEnd){
                let power = 0;
                for(let i = 0; i < data?.length; i++){
                    if(new Date(data[i]?.reportTime) >= hourStart && new Date(data[i]?.reportTime) < hourEnd){ 
                        // console.log("-------");
                        // console.log("new Date(item?.reportTime) : ", new Date(data[i]?.reportTime));
                        // console.log("quarterHourStart : ", hourStart);
                        // console.log("quarterHourEnd", hourEnd);
                        // console.log("-------");
                        // console.log("true : ", data[i]?.power);
                        if(power < Number(data[i]?.power)){
                            power = Number(data[i]?.power);
                        }
                        // break;
                    }
                }
    
                if(chargerUnit === "AMP"){
                    return ((power * 1000) / 230).toFixed(2);
                } else {
                    return power.toFixed(2);
                }
            }
            
            
            function firstValueOfMonth(data, day){
                let power = 0;
                for(let i = 0; i < data?.length; i++){
                    if(new Date(data[i]?.reportTime).getDate() === day + 1){ 
                        // console.log("-------");
                        // console.log("new Date(item?.reportTime) : ", new Date(data[i]?.reportTime));
                        // console.log("new Date(data[i]?.reportTime).getDate() : ", new Date(data[i]?.reportTime).getDate());
                        // console.log("day + 1", day + 1);
                        // console.log("true : ", data[i]?.power);
                        // console.log("-------");
                        if(power < Number(data[i]?.power)){
                            power = Number(data[i]?.power);
                        }
                        // break;
                    }
                }
                if(chargerUnit === "AMP"){
                    return ((power * 1000) / 230).toFixed(2);
                } else {
                    return power.toFixed(2);
                }
            }
            
            
            function firstValueOfYear(data, weekStart, weekEnd){
                // console.log("data : ", data);
                let power = 0;
                for(let i = 0; i < data?.length; i++){
                    if(new Date(data[i]?.reportTime) >= weekStart && new Date(data[i]?.reportTime) < weekEnd){ 
                        // console.log("-------");
                        // console.log("new Date(item?.reportTime) : ", new Date(data[i]?.reportTime));
                        // console.log("weekStart : ", weekStart);
                        // console.log("weekEnd", weekEnd);
                        // console.log("true : ", data[i]?.power);
                        // console.log("-------");
                        if(power < Number(data[i]?.power)){
                            power = Number(data[i]?.power);
                        }
                        // break;
                    }
                }
                if(chargerUnit === "AMP"){
                    return ((power * 1000) / 230).toFixed(2);
                } else {
                    return power.toFixed(2);
                }
            }


            // Function to create an array with aggregated data for each time interval
            const createChartData = (data, interval) => {
                let chartData = [];

                switch(interval) {
                    case 'today':
                        // Create an array of length 24 (hours)
                        // chartData = Array.from({ length: 24 }, (_, hour) => {
                        //     const hourStart = new Date();
                        //     hourStart.setHours(hour, 0, 0, 0);
                        //     const hourEnd = new Date(hourStart);
                        //     hourEnd.setHours(hourEnd.getHours() + 1);
                        //     return {
                        //         x: hourStart?.toLocaleTimeString('en-US', {hour: 'numeric'}),
                        //         y: calculateSum(data?.filter(item => new Date(item?.reportTime) >= hourStart && new Date(item?.reportTime) < hourEnd))
                        //         // y: data?.filter(item => new Date(item?.reportTime) >= hourStart && new Date(item?.reportTime) < hourEnd)
                        //     };
                        // });
                        // chartData = Array.from({ length: 24 }, (_, hour) => {
                        //     const hourStart = new Date();
                        //     hourStart.setHours(hour, 0, 0, 0);
                        //     const hourEnd = new Date(hourStart);
                        //     hourEnd.setHours(hourEnd.getHours() + 1);
                        
                        //     // Filter the data for the current hour
                        //     const hourData = data?.filter(item => new Date(item.reportTime) >= hourStart && new Date(item.reportTime) < hourEnd);
                        
                        //     // Call calculateSum to get the sum for the current hour
                        //     return {
                        //         x: hourStart.toLocaleTimeString('en-US', { hour: 'numeric' }),
                        //         y: calculateSum2(hourData)
                        //     };
                        // });
                        // chartData = Array.from({ length: 24 * 4 }, (_, index) => {
                        //     const day = Math.floor(index / (24 * 4)); // Calculate the day index
                        //     const quarterHour = index % (24 * 4); // Calculate the quarter hour index
                        //     const dayStart = new Date();
                        //     dayStart.setDate(dayStart.getDate());
                        //     const quarterHourStart = new Date(dayStart);
                        //     quarterHourStart.setMinutes(quarterHour * 15, 0, 0);
                        //     const quarterHourEnd = new Date(quarterHourStart);
                        //     quarterHourEnd.setMinutes(quarterHourEnd.getMinutes() + 15);

                            

                        //     return {
                        //         x: quarterHourStart.toLocaleString('en-US', { weekday: 'short', hour: 'numeric', minute: 'numeric' }),
                        //         y: data.map(item => {if(new Date(item?.reportTime) >= quarterHourStart && new Date(item?.reportTime) < quarterHourEnd){ return item?.power;}})
                        //     };
                        // });
                        chartData = Array.from({ length: 24 * 4 }, (_, index) => {
                            const today = new Date(); // Get today's date
                            const quarterHour = index; // Calculate the quarter hour index
                            const quarterHourStart = new Date(today); // Clone today's date
                            quarterHourStart.setHours(0, quarterHour * 15, 0, 0); // Set hours and minutes
                            const quarterHourEnd = new Date(quarterHourStart);
                            quarterHourEnd.setMinutes(quarterHourEnd.getMinutes() + 15);
                            
                            // data.map(item => {
                            //     if(new Date(item?.reportTime) >= quarterHourStart && new Date(item?.reportTime) < quarterHourEnd){ 
                            //         console.log("-------");
                            //         console.log("new Date(item?.reportTime) : ", new Date(item?.reportTime));
                            //         console.log("quarterHourStart : ", quarterHourStart);
                            //         console.log("quarterHourEnd", quarterHourEnd);
                            //         console.log("-------");
                            //         console.log("true : ", item?.power);
                            //         // return item?.power;
                            //     }
                            // })

                            return {
                                x: quarterHourStart.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric' }),
                                y: firstValueOfDay(data, quarterHourStart, quarterHourEnd)
                                
                            };
                        });
                        break;
                    case 'thisWeek':
                        // Create an array of length 7 (days)
                        // chartData = Array.from({ length: 7 }, (_, day) => {
                        //     const dayStart = new Date();
                        //     // console.log("dayStart : ",dayStart)
                        //     dayStart.setDate(dayStart.getDate() - day);
                        //     return {
                        //         x: dayStart.toLocaleDateString('en-US', { weekday: 'short' }),
                        //         y: calculateSum(data.filter(item => dateFormat1(item?.reportTime) === dateFormat1(dayStart)))
                        //     }; 
                        // });
                        // chartData = Array.from({ length: 7 }, (_, day) => {
                        //     return Array.from({ length: 24 }, (_, hour) => {
                        //         const dayStart = new Date();
                        //         dayStart.setDate(dayStart.getDate() - day);
                        //         const hourStart = new Date(dayStart);
                        //         hourStart.setHours(hour, 0, 0, 0);
                        //         const hourEnd = new Date(hourStart);
                        //         hourEnd.setHours(hourEnd.getHours() + 1);
                        //         return {
                        //             x: hourStart.toLocaleString('en-US', { weekday: 'short', hour: 'numeric' }),
                        //             y: calculateSum(data.filter(item => new Date(item?.reportTime) >= hourStart && new Date(item?.reportTime) < hourEnd))
                        //         };
                        //     });
                        // });
                        chartData = Array.from({ length: 7 * 24 }, (_, index) => {
                            const day = Math.floor(index / 24); // Calculate the day index
                            const hour = index % 24; // Calculate the hour index
                            const dayStart = new Date();
                            dayStart.setDate(dayStart.getDate() - day);
                            const hourStart = new Date(dayStart);
                            hourStart.setHours(hour, 0, 0, 0);
                            const hourEnd = new Date(hourStart);
                            hourEnd.setHours(hourEnd.getHours() + 1);
                            return {
                                x: hourStart.toLocaleString('en-US', { weekday: 'short', hour: 'numeric' }),
                                // y: calculateSum(data.filter(item => new Date(item?.reportTime) >= hourStart && new Date(item?.reportTime) < hourEnd))
                                y: firstValueOfWeek(data, hourStart, hourEnd),
                                d: dayStart,
                            };
                        });
                        // chartData = Array.from({ length: 7 * 24 * 4 }, (_, index) => {
                        //     const day = Math.floor(index / (24 * 4)); // Calculate the day index
                        //     const quarterHour = index % (24 * 4); // Calculate the quarter hour index
                        //     const dayStart = new Date();
                        //     dayStart.setDate(dayStart.getDate() - day);
                        //     const quarterHourStart = new Date(dayStart);
                        //     quarterHourStart.setMinutes(quarterHour * 15, 0, 0);
                        //     const quarterHourEnd = new Date(quarterHourStart);
                        //     quarterHourEnd.setMinutes(quarterHourEnd.getMinutes() + 15);
                        //     return {
                        //         x: quarterHourStart.toLocaleString('en-US', { weekday: 'short', hour: 'numeric', minute: 'numeric' }),
                        //         y: data.find(item => {if(new Date(item?.reportTime) >= quarterHourStart && new Date(item?.reportTime) < quarterHourEnd){ return item?.power;}})
                        //     };
                        // });,,
                        
                        // chartData = Array.from({ length: 7 * 24 * 4 }, (_, index) => {
                        //     const day = Math.floor(index / (24 * 4)); // Calculate the day index
                        //     const quarterHour = index % (24 * 4); // Calculate the quarter hour index
                        //     const dayStart = new Date();
                        //     // console.log("day and index", day, index);
                        //     // console.log("quarterHour : ", quarterHour);
                        //     // console.log("dayStart : ", dayStart);
                        //     dayStart.setDate(dayStart.getDate() - day);
                        //     // console.log("dayStart - day : ", dayStart);
                            
                        //     const quarterHourStart = new Date(dayStart);
                        //     // console.log("quarterHourStar : ", quarterHourStart);
                        //     quarterHourStart.setMinutes(quarterHour * 15, 0, 0);
                        //     // console.log("quarterHourStar quater * 15 set mints: ", quarterHourStart);
                        //     const quarterHourEnd = new Date(quarterHourStart);
                        //     // console.log("quarterHourEnd : ", quarterHourEnd);
                        //     quarterHourEnd.setMinutes(quarterHourEnd.getMinutes() + 15);
                        //     // console.log("quarterHourEnd + 15 mints : ", quarterHourEnd);
                        //     return {
                        //         x: quarterHourStart.toLocaleString('en-US', { weekday: 'short', hour: 'numeric', minute: 'numeric' }),
                        //         y: calculateFirstValue(data, quarterHourStart, quarterHourEnd)
                        //     };
                        // });                                                                        
                        break;
                    case 'thisMonth':
                        // Create an array with number of days in the current month
                        const daysInMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
                        chartData = Array.from({ length: daysInMonth }, (_, day) => {
                            const dayStart = new Date();
                            dayStart.setDate(day + 1); // Start from day 1                            
                            return {
                                x:  (day + 1).toString(),
                                // y: calculateSum(data?.filter(item => new Date(item?.reportTime).getDate() === day + 1))
                                y: firstValueOfMonth(data, day)
                            };
                        });
                        break;
                    case 'thisYear':
                        chartData = Array.from({ length: 52 }, (_, week) => {
                            const weekStart = new Date();
                            weekStart.setFullYear(weekStart.getFullYear(), 0, 1); // Set to the first day of the year
                            weekStart.setDate(weekStart.getDate() + (week * 7)); // Move to the start of the current week
                    
                            const weekEnd = new Date(weekStart);
                            weekEnd.setDate(weekEnd.getDate() + 7); // Move to the end of the current week
                    
                            return {
                                x: `Week ${week + 1}`,
                                // y: calculateSum(data?.filter(item => new Date(item?.reportTime) >= weekStart && new Date(item?.reportTime) < weekEnd))
                                y: firstValueOfYear(data, weekStart, weekEnd)
                            };
                        });
                        break;
                    default:
                        break;
                }

                return chartData;
            };


            const chartDataToday = createChartData(dataForToday, 'today');
            const chartDataThisWeek = createChartData(dataForThisWeek, 'thisWeek');
            const chartDataThisMonth = createChartData(dataForThisMonth, 'thisMonth');
            const chartDataThisYear = createChartData(dataForThisYear, 'thisYear');

            // console.log("chart Data Today : ", chartDataToday);
            // console.log("char tData This Week :", chartDataThisWeek);
            // console.log("chart Data This Month : ", chartDataThisMonth);
            // console.log("chart Data This Year : ", chartDataThisYear);

            const weekDateLabels = [];

            for(let a = 0; a < chartDataThisWeek?.length; a++){
                // console.log("a start:", a);
                weekDateLabels?.push(dateFormat1(chartDataThisWeek[a]?.d));
                a = a+ 24;
                // console.log("a  24:", a);
            } 

            // console.log("week labels arr : ", weekDateLabels)
            setWeekDateLabel(weekDateLabels);
            console.log("Power chart data : ", {
                today: chartDataToday,
                week: chartDataThisWeek,
                month: chartDataThisMonth,
                year: chartDataThisYear
            });
            setPowerChartData({
                today: chartDataToday,
                week: chartDataThisWeek,
                month: chartDataThisMonth,
                year: chartDataThisYear
            });


            setPowerData(res?.data[1]);


















            // Energy used api 

            // Get today's date in UTC string format
            // const today = new Date().toISOString().split('T')[0];

            // console.log("today : ", today);
    
            // Filter data for today
            const dataForTodayEnergy = res?.data[0]?.filter(item => item?.reportTime?.startsWith(today));
    
            // console.log("data for today : ", dataForToday);
    
            // Get start of current week
            // const startOfWeek = new Date();
            // startOfWeek?.setDate(startOfWeek.getDate() - startOfWeek.getDay()); // Get Sunday of this week
            // const startOfWeekString = startOfWeek?.toISOString()?.split('T')[0];
            // console.log("start of week string : ", startOfWeekString);
            // Filter data for this week
            const dataForThisWeekEnergy = res?.data[0]?.filter(item => item?.reportTime >= startOfWeekString);
    
            // console.log("data for this week : ", dataForThisWeek);
    
            // Get start of current month
            // const startOfMonth = new Date();
            // startOfMonth?.setDate(1); // Set to 1st day of the month
            // const startOfMonthString = startOfMonth?.toISOString()?.split('T')[0];
            // let tempSum = 0;
            // console.log(res?.data?.filter(item =>{ 
            //     if(item?.startTime >= startOfMonthString){
            //         console.log("item?.startTime : ", item?.startTime);
            //         console.log("startOfMonthString : ", startOfMonthString, item?.meterDifference);
            //         tempSum = tempSum + item?.meterDifference;
            //         console.log("true : ", tempSum);
            //         return item?.startTime >= startOfMonthString
            //     }
            // }))

            // Filter data for this month
            const dataForThisMonthEnergy = res?.data[0]?.filter(item => item?.reportTime >= startOfMonthString);
    
            // console.log("data for this month : ", dataForThisMonth);
    
            // Get start of current year
            // const startOfYear = new Date();
            // startOfYear?.setMonth(0, 1); // Set to 1st day of January
            // const startOfYearString = startOfYear?.toISOString()?.split('T')[0];
            // console.log(res?.data?.filter(item => {
            //     console.log("item?.startTime : ", item?.startTime);
            //     console.log("start of year String : ", startOfYearString);
            //     return item?.startTime >= startOfYearString;
            // }))
            // Filter data for this year
            const dataForThisYearEnergy = res?.data[0]?.filter(item => item?.reportTime >= startOfYearString);
    
   
            // console.log("Data for this year : ", dataForThisYear);

            // Get today's date of last year
            const todayLastYear = new Date(today);
            todayLastYear.setFullYear(todayLastYear.getFullYear() - 1);;

            const dataForTodayLastYearEnergy = res?.data[0]?.filter(item => item?.reportTime.startsWith(todayLastYear));

            // console.log("data for today Last Year : ", dataForTodayLastYear);

            // Get start of week of last year
            const startOfWeekLastYear = new Date(startOfWeek);
            startOfWeekLastYear.setFullYear(startOfWeekLastYear.getFullYear() - 1);

            const dataForThisWeekLastYear = res?.data[0]?.filter(item => item?.reportTime >= startOfWeekLastYear);

            // console.log("data for this week last year : ", dataForThisWeekLastYear);

            // Get start of month of last year
            const startOfMonthLastYear = new Date(startOfMonth);
            startOfMonthLastYear.setFullYear(startOfMonthLastYear.getFullYear() - 1);


            const dataForThisMonthLastYear = res?.data[0]?.filter(item => item?.reportTime >= startOfMonthLastYear);


            const dataForLastYear = res?.data[0]?.filter(item => dateFormat2(item?.reportTime) === dateFormat2(todayLastYear));

            
            // console.log("data of last year : ", dataForLastYear);

            // Example usage:0
            let currentMonthSales = 1500; // Replace with actual current month sales
            let lastYearMonthSales = 2000; // Replace with actual last year month sales

            let percentageChange = calculatePercentageChange(currentMonthSales, lastYearMonthSales);
            // console.log("Percentage Change:", percentageChange.toFixed(2) + "%");

            // Function to calculate sum of meterDifference
            const calculateSumEnergy = (dataArray) => {
                return dataArray?.reduce((sum, item) => sum + parseFloat(item?.energy), 0);
                // let sum = 0;
                // dataArray?.map(item => {
                //     sum = sum + item?.meterDifference;
                // })
                // return sum;
            };

            // console.log("************************************************************************");124,251,152
            // console.log("Total count of two years : ", calculateSum(res?.data));
            // console.log("************************************************************************");
    
            // Function to create an array with aggregated data for each time interval
            const createChartDataEnergy = (data, interval) => {
                let chartData = [];

                switch(interval) {
                    case 'today':
                        // Create an array of length 24 (hours)
                        chartData = Array.from({ length: 24 }, (_, hour) => {
                            const hourStart = new Date();
                            hourStart.setHours(hour, 0, 0, 0);
                            const hourEnd = new Date(hourStart);
                            hourEnd.setHours(hourEnd.getHours() + 1);
                            return {
                                x: hourStart?.toLocaleTimeString('en-US', {hour: 'numeric'}),
                                y: calculateSumEnergy(data?.filter(item => new Date(item?.reportTime) >= hourStart && new Date(item?.reportTime) < hourEnd))
                            };
                        });
                        break;
                    case 'thisWeek':
                        // chartData = Array.from({ length: 7 }, (_, day) => {
                        //     return Array.from({ length: 24 }, (_, hour) => {
                        //         const dayStart = new Date();
                        //         dayStart.setDate(dayStart.getDate() - day);
                        //         const hourStart = new Date(dayStart);
                        //         hourStart.setHours(hour, 0, 0, 0);
                        //         const hourEnd = new Date(hourStart);
                        //         hourEnd.setHours(hourEnd.getHours() + 1);
                        //         return {
                        //             x: hourStart.toLocaleString('en-US', { weekday: 'short', hour: 'numeric' }),
                        //             y: calculateSum(data.filter(item => new Date(item?.startTime) >= hourStart && new Date(item?.startTime) < hourEnd))
                        //         };
                        //     });
                        // });
                        

                        
                        
                        chartData = Array.from({ length: 7 * 24 }, (_, index) => {
                            const day = Math.floor(index / 24); // Calculate the day index
                            const hour = index % 24; // Calculate the hour index
                            const dayStart = new Date();
                            dayStart.setDate(dayStart.getDate() - day);
                            const hourStart = new Date(dayStart);
                            hourStart.setHours(hour, 0, 0, 0);
                            const hourEnd = new Date(hourStart);
                            hourEnd.setHours(hourEnd.getHours() + 1);
                            
                            return {
                                x: hourStart.toLocaleString('en-US', { weekday: 'short', hour: 'numeric' }),
                                y: calculateSumEnergy(data.filter(item => new Date(item?.reportTime) >= hourStart && new Date(item?.reportTime) < hourEnd)),
                                d: dayStart,
                            };
                        });                        
                        break;
                    case 'thisMonth':
                        // Create an array with number of days in the current month
                        const daysInMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
                        chartData = Array.from({ length: daysInMonth }, (_, day) => {
                            const dayStart = new Date();
                            dayStart.setDate(day + 1); // Start from day 1
                            // console.log("day start : ", dayStart);
                            // console.log("data : ", data?.filter(item => {
                            //     console.log("item?.startTime : ", new Date(item?.startTime).getDate());
                            //     console.log("day + 1 : ", day + 1);
                            //     if(new Date(item?.startTime).getDate() === day + 1){
                            //         console.log("true");
                            //     }
                            //     return new Date(item?.startTime).getDate() === day + 1
                            // }));

                            return {
                                x:  (day + 1).toString(),
                                y: calculateSumEnergy(data?.filter(item => new Date(item?.reportTime).getDate() === day + 1))
                            };
                        });
                        break;
                    case 'thisYear':
                        // Create an array of length 12 (months)
                        // chartData = Array.from({ length: 12 }, (_, month) => {
                        //     const monthStart = new Date();
                        //     monthStart.setMonth(month, 1); // Set to 1st day of the month
                        //     const monthEnd = new Date();
                        //     monthEnd.setMonth(month + 1, 0); // Last day of the month
                        //     return {
                        //         x: monthStart.toLocaleDateString('en-US', { month: 'short' }),
                        //         y: calculateSum(data?.filter(item => new Date(item?.startTime) >= monthStart && new Date(item?.startTime) <= monthEnd))
                        //     };
                        // });
                        chartData = Array.from({ length: 52 }, (_, week) => {
                            const weekStart = new Date();
                            weekStart.setFullYear(weekStart.getFullYear(), 0, 1); // Set to the first day of the year
                            weekStart.setDate(weekStart.getDate() + (week * 7)); // Move to the start of the current week
                    
                            const weekEnd = new Date(weekStart);
                            weekEnd.setDate(weekEnd.getDate() + 7); // Move to the end of the current week
                    
                            return {
                                x: `Week ${week + 1}`,
                                y: calculateSumEnergy(data?.filter(item => new Date(item?.reportTime) >= weekStart && new Date(item?.reportTime) < weekEnd))
                            };
                        });
                        break;
                    default:
                        break;
                }

                return chartData;
            };


            const dataObj = {
                today: calculateSumEnergy(dataForToday),
                week: calculateSumEnergy(dataForThisWeek),
                month: calculateSumEnergy(dataForThisMonth),
                year: calculateSumEnergy(dataForThisYear),
                power: res?.data[1],
            }
    
            // console.log("data object : ", dataObj);
    
            const sumDataForLastYear = calculateSumEnergy(dataForLastYear);
            // console.log("Comparasion : ", calculatePercentageChange(dataObj?.year, sumDataForLastYear));

            setData(dataObj);
            setLastYearData({
                year: calculatePercentageChange(dataObj?.year, sumDataForLastYear)
            })
            // Create chart data for each time interval

            const chartDataTodayEnergy = createChartDataEnergy(dataForTodayEnergy, 'today');
            const chartDataThisWeekEnergy = createChartDataEnergy(dataForThisWeekEnergy, 'thisWeek');
            const chartDataThisMonthEnergy = createChartDataEnergy(dataForThisMonthEnergy, 'thisMonth');
            const chartDataThisYearEnergy = createChartDataEnergy(dataForThisYearEnergy, 'thisYear');

            // console.log("chart Data Today : ", chartDataToday);
            // console.log("char tData This Week :", chartDataThisWeek);
            // console.log("chart Data This Month : ", chartDataThisMonth);
            // console.log("chart Data This Year : ", chartDataThisYear);
            console.log("Tableee dataa : : ", {
                today: dataForTodayEnergy,
                week: dataForThisWeekEnergy,
                month: dataForThisMonthEnergy,
                year: dataForThisYearEnergy,
            });
            setTableData({
                today: dataForTodayEnergy,
                week: dataForThisWeekEnergy,
                month: dataForThisMonthEnergy,
                year: dataForThisYearEnergy,
            })

            setChartData({
                today: chartDataTodayEnergy,
                week: chartDataThisWeekEnergy,
                month: chartDataThisMonthEnergy,
                year: chartDataThisYearEnergy
            });

            // const labsForToday = generateLabels(chartDataToday);
            const labsForThisWeekEnergy = generateLabels(chartDataThisWeekEnergy);
            const labsForThisMonthEnergy = generateLabels(chartDataThisMonthEnergy);
            // const labsForThisYear = generateLabels(chartDataThisYear);
            setMonthLabel(labsForThisMonthEnergy);
            setLabs({
                // today: labsForToday,
                week: labsForThisWeekEnergy,
                // month: labsForThisMonth,
                // year: labsForThisYear
            });






            setLoading2(false);
        }).catch((err) => {
            setLoading2(false);
            console.log("Power API Error : ", err);
        })
    }, [])


    // useEffect(() => {
    //     const token = localStorage.getItem("pea_admin");
    //     // axios.get(`${apiEndPoint}/api/admin/transactions/Overview`, {
    //     axios.get(`${apiEndPoint}/api/admin/transactions/${url}`, {
    //         headers: {Authorization: `Bearer ${token}`}
    //     }).then((res) => {
    //         console.log("Res Transaction overview : ", res);
            
    //         // // Get today's date in UTC string format
    //         // const today = new Date().toISOString().split('T')[0];

    //         // // console.log("today : ", today);
    
    //         // // Filter data for today
    //         // const dataForToday = res?.data[0]?.filter(item => item?.startTime?.startsWith(today));
    
    //         // // console.log("data for today : ", dataForToday);
    
    //         // // Get start of current week
    //         // const startOfWeek = new Date();
    //         // startOfWeek?.setDate(startOfWeek.getDate() - startOfWeek.getDay()); // Get Sunday of this week
    //         // const startOfWeekString = startOfWeek?.toISOString()?.split('T')[0];
    //         // // console.log("start of week string : ", startOfWeekString);
    //         // // Filter data for this week
    //         // const dataForThisWeek = res?.data[0]?.filter(item => item?.startTime >= startOfWeekString);
    
    //         // // console.log("data for this week : ", dataForThisWeek);
    
    //         // // Get start of current month
    //         // const startOfMonth = new Date();
    //         // startOfMonth?.setDate(1); // Set to 1st day of the month
    //         // const startOfMonthString = startOfMonth?.toISOString()?.split('T')[0];
    //         // // let tempSum = 0;
    //         // // console.log(res?.data?.filter(item =>{ 
    //         // //     if(item?.startTime >= startOfMonthString){
    //         // //         console.log("item?.startTime : ", item?.startTime);
    //         // //         console.log("startOfMonthString : ", startOfMonthString, item?.meterDifference);
    //         // //         tempSum = tempSum + item?.meterDifference;
    //         // //         console.log("true : ", tempSum);
    //         // //         return item?.startTime >= startOfMonthString
    //         // //     }
    //         // // }))

    //         // // Filter data for this month
    //         // const dataForThisMonth = res?.data[0]?.filter(item => item?.startTime >= startOfMonthString);
    
    //         // // console.log("data for this month : ", dataForThisMonth);
    
    //         // // Get start of current year
    //         // const startOfYear = new Date();
    //         // startOfYear?.setMonth(0, 1); // Set to 1st day of January
    //         // const startOfYearString = startOfYear?.toISOString()?.split('T')[0];
    //         // // console.log(res?.data?.filter(item => {
    //         // //     console.log("item?.startTime : ", item?.startTime);
    //         // //     console.log("start of year String : ", startOfYearString);
    //         // //     return item?.startTime >= startOfYearString;
    //         // // }))
    //         // // Filter data for this year
    //         // const dataForThisYear = res?.data[0]?.filter(item => item.startTime >= startOfYearString);
    
   
    //         // // console.log("Data for this year : ", dataForThisYear);

    //         // // Get today's date of last year
    //         // const todayLastYear = new Date(today);
    //         // todayLastYear.setFullYear(todayLastYear.getFullYear() - 1);;

    //         // const dataForTodayLastYear = res?.data[0]?.filter(item => item?.startTime.startsWith(todayLastYear));

    //         // // console.log("data for today Last Year : ", dataForTodayLastYear);

    //         // // Get start of week of last year
    //         // const startOfWeekLastYear = new Date(startOfWeek);
    //         // startOfWeekLastYear.setFullYear(startOfWeekLastYear.getFullYear() - 1);

    //         // const dataForThisWeekLastYear = res?.data[0]?.filter(item => item?.startTime >= startOfWeekLastYear);

    //         // // console.log("data for this week last year : ", dataForThisWeekLastYear);

    //         // // Get start of month of last year
    //         // const startOfMonthLastYear = new Date(startOfMonth);
    //         // startOfMonthLastYear.setFullYear(startOfMonthLastYear.getFullYear() - 1);


    //         // const dataForThisMonthLastYear = res?.data[0]?.filter(item => item?.startTime >= startOfMonthLastYear);


    //         // const dataForLastYear = res?.data[0]?.filter(item => dateFormat2(item?.startTime) === dateFormat2(todayLastYear));

            
    //         // // console.log("data of last year : ", dataForLastYear);

    //         // // Example usage:0
    //         // let currentMonthSales = 1500; // Replace with actual current month sales
    //         // let lastYearMonthSales = 2000; // Replace with actual last year month sales

    //         // let percentageChange = calculatePercentageChange(currentMonthSales, lastYearMonthSales);
    //         // // console.log("Percentage Change:", percentageChange.toFixed(2) + "%");

    //         // // Function to calculate sum of meterDifference
    //         // const calculateSum = (dataArray) => {
    //         //     return dataArray?.reduce((sum, item) => sum + parseFloat(item?.meterDifference), 0);
    //         //     // let sum = 0;
    //         //     // dataArray?.map(item => {
    //         //     //     sum = sum + item?.meterDifference;
    //         //     // })
    //         //     // return sum;
    //         // };

    //         // // console.log("************************************************************************");124,251,152
    //         // // console.log("Total count of two years : ", calculateSum(res?.data));
    //         // // console.log("************************************************************************");
    
    //         // // Function to create an array with aggregated data for each time interval
    //         // const createChartData = (data, interval) => {
    //         //     let chartData = [];

    //         //     switch(interval) {
    //         //         case 'today':
    //         //             // Create an array of length 24 (hours)
    //         //             chartData = Array.from({ length: 24 }, (_, hour) => {
    //         //                 const hourStart = new Date();
    //         //                 hourStart.setHours(hour, 0, 0, 0);
    //         //                 const hourEnd = new Date(hourStart);
    //         //                 hourEnd.setHours(hourEnd.getHours() + 1);
    //         //                 return {
    //         //                     x: hourStart?.toLocaleTimeString('en-US', {hour: 'numeric'}),
    //         //                     y: calculateSum(data?.filter(item => new Date(item?.startTime) >= hourStart && new Date(item?.startTime) < hourEnd))
    //         //                 };
    //         //             });
    //         //             break;
    //         //         case 'thisWeek':
    //         //             // chartData = Array.from({ length: 7 }, (_, day) => {
    //         //             //     return Array.from({ length: 24 }, (_, hour) => {
    //         //             //         const dayStart = new Date();
    //         //             //         dayStart.setDate(dayStart.getDate() - day);
    //         //             //         const hourStart = new Date(dayStart);
    //         //             //         hourStart.setHours(hour, 0, 0, 0);
    //         //             //         const hourEnd = new Date(hourStart);
    //         //             //         hourEnd.setHours(hourEnd.getHours() + 1);
    //         //             //         return {
    //         //             //             x: hourStart.toLocaleString('en-US', { weekday: 'short', hour: 'numeric' }),
    //         //             //             y: calculateSum(data.filter(item => new Date(item?.startTime) >= hourStart && new Date(item?.startTime) < hourEnd))
    //         //             //         };
    //         //             //     });
    //         //             // });
                        

                        
                        
    //         //             chartData = Array.from({ length: 7 * 24 }, (_, index) => {
    //         //                 const day = Math.floor(index / 24); // Calculate the day index
    //         //                 const hour = index % 24; // Calculate the hour index
    //         //                 const dayStart = new Date();
    //         //                 dayStart.setDate(dayStart.getDate() - day);
    //         //                 const hourStart = new Date(dayStart);
    //         //                 hourStart.setHours(hour, 0, 0, 0);
    //         //                 const hourEnd = new Date(hourStart);
    //         //                 hourEnd.setHours(hourEnd.getHours() + 1);
                            
    //         //                 return {
    //         //                     x: hourStart.toLocaleString('en-US', { weekday: 'short', hour: 'numeric' }),
    //         //                     y: calculateSum(data.filter(item => new Date(item?.startTime) >= hourStart && new Date(item?.startTime) < hourEnd)),
    //         //                     d: dayStart,
    //         //                 };
    //         //             });                        
    //         //             break;
    //         //         case 'thisMonth':
    //         //             // Create an array with number of days in the current month
    //         //             const daysInMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
    //         //             chartData = Array.from({ length: daysInMonth }, (_, day) => {
    //         //                 const dayStart = new Date();
    //         //                 dayStart.setDate(day + 1); // Start from day 1
    //         //                 // console.log("day start : ", dayStart);
    //         //                 // console.log("data : ", data?.filter(item => {
    //         //                 //     console.log("item?.startTime : ", new Date(item?.startTime).getDate());
    //         //                 //     console.log("day + 1 : ", day + 1);
    //         //                 //     if(new Date(item?.startTime).getDate() === day + 1){
    //         //                 //         console.log("true");
    //         //                 //     }
    //         //                 //     return new Date(item?.startTime).getDate() === day + 1
    //         //                 // }));

    //         //                 return {
    //         //                     x:  (day + 1).toString(),
    //         //                     y: calculateSum(data?.filter(item => new Date(item?.startTime).getDate() === day + 1))
    //         //                 };
    //         //             });
    //         //             break;
    //         //         case 'thisYear':
    //         //             // Create an array of length 12 (months)
    //         //             // chartData = Array.from({ length: 12 }, (_, month) => {
    //         //             //     const monthStart = new Date();
    //         //             //     monthStart.setMonth(month, 1); // Set to 1st day of the month
    //         //             //     const monthEnd = new Date();
    //         //             //     monthEnd.setMonth(month + 1, 0); // Last day of the month
    //         //             //     return {
    //         //             //         x: monthStart.toLocaleDateString('en-US', { month: 'short' }),
    //         //             //         y: calculateSum(data?.filter(item => new Date(item?.startTime) >= monthStart && new Date(item?.startTime) <= monthEnd))
    //         //             //     };
    //         //             // });
    //         //             chartData = Array.from({ length: 52 }, (_, week) => {
    //         //                 const weekStart = new Date();
    //         //                 weekStart.setFullYear(weekStart.getFullYear(), 0, 1); // Set to the first day of the year
    //         //                 weekStart.setDate(weekStart.getDate() + (week * 7)); // Move to the start of the current week
                    
    //         //                 const weekEnd = new Date(weekStart);
    //         //                 weekEnd.setDate(weekEnd.getDate() + 7); // Move to the end of the current week
                    
    //         //                 return {
    //         //                     x: `Week ${week + 1}`,
    //         //                     y: calculateSum(data?.filter(item => new Date(item?.startTime) >= weekStart && new Date(item?.startTime) < weekEnd))
    //         //                 };
    //         //             });
    //         //             break;
    //         //         default:
    //         //             break;
    //         //     }

    //         //     return chartData;
    //         // };


    //         // const dataObj = {
    //         //     today: calculateSum(dataForToday),
    //         //     week: calculateSum(dataForThisWeek),
    //         //     month: calculateSum(dataForThisMonth),
    //         //     year: calculateSum(dataForThisYear),
    //         //     power: res?.data[1],
    //         // }
    
    //         // // console.log("data object : ", dataObj);
    
    //         // const sumDataForLastYear = calculateSum(dataForLastYear);
    //         // // console.log("Comparasion : ", calculatePercentageChange(dataObj?.year, sumDataForLastYear));

    //         // setData(dataObj);
    //         // setLastYearData({
    //         //     year: calculatePercentageChange(dataObj?.year, sumDataForLastYear)
    //         // })
    //         // // Create chart data for each time interval

    //         // const chartDataToday = createChartData(dataForToday, 'today');
    //         // const chartDataThisWeek = createChartData(dataForThisWeek, 'thisWeek');
    //         // const chartDataThisMonth = createChartData(dataForThisMonth, 'thisMonth');
    //         // const chartDataThisYear = createChartData(dataForThisYear, 'thisYear');

    //         // // console.log("chart Data Today : ", chartDataToday);
    //         // // console.log("char tData This Week :", chartDataThisWeek);
    //         // // console.log("chart Data This Month : ", chartDataThisMonth);
    //         // // console.log("chart Data This Year : ", chartDataThisYear);

    //         // setTableData({
    //         //     today: dataForToday,
    //         //     week: dataForThisWeek,
    //         //     month: dataForThisMonth,
    //         //     year: dataForThisYear
    //         // })

    //         // setChartData({
    //         //     today: chartDataToday,
    //         //     week: chartDataThisWeek,
    //         //     month: chartDataThisMonth,
    //         //     year: chartDataThisYear
    //         // });

    //         // // const labsForToday = generateLabels(chartDataToday);
    //         // const labsForThisWeek = generateLabels(chartDataThisWeek);
    //         // const labsForThisMonth = generateLabels(chartDataThisMonth);
    //         // // const labsForThisYear = generateLabels(chartDataThisYear);
    //         // setMonthLabel(labsForThisMonth);
    //         // setLabs({
    //         //     // today: labsForToday,
    //         //     week: labsForThisWeek,
    //         //     // month: labsForThisMonth,
    //         //     // year: labsForThisYear
    //         // });

    //         setLoading(false);
            
    //     }).catch((err) => {
    //         setLoading(false);
    //         console.log("Err : ", err);
    //     })
    // }, [flag])


    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         if (percentage < 25) {
    //             // if(percentage === 1){
    //                 setData(['1,476.63', '7,348.72', '30,510.14', '1.762']);
    //             // }
    //             setPercentage(percentage + 1); 
    //         } else if(percentage < 50) {
    //             // if(percentage === 26){
    //                 setData(['1,362.43', '6,913.48', '29,369.58', '1.825']);
    //             // }
    //             setPercentage(percentage + 1);         
    //         } else if(percentage < 75){
    //             // if(percentage === 51){
    //                 setData(['1,528.43', '7,232.25', '31,062.19', '2.012']);
    //             // }
    //             setPercentage(percentage + 1);         
    //         } else {
    //             setPercentage(percentage + 1);         
    //             // // if(percentage === 76){
    //                     setData(['1,528.43', '7,232.25', '31,062.19', '2.012']);
    //                 // // }
    //                 if(percentage === 99){
    //                 setPercentage(1);
    //             }
    //         }

    //     }, 40);
    
    //     return () => clearInterval(interval);
    // }, [percentage]);

    const handleDateChange = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    
    return (
        <div className="w-100 generic-section">
            <div className="w-100 d-flex justify-content-between">
                <div>
                    <h4 className="ov-style3">{lab1 ? lab1 : "Energy Monitoring"}</h4>
                    <span className="ov-style4">{lab2 ? lab2 : "Overview statistics of all energy used"}</span>
                </div>
                <div>
                    {/* <ul className="filter-list-ul ps-0 p-0">
                        <li onClick={() => setTog(1)} className={tog === 1 ? "active-op1" : "nondoaa"}>
                            <svg className="hide-li-item" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="1130 801 21 21"><path d="M1151 811.5c0 5.799-4.701 10.5-10.5 10.5s-10.5-4.701-10.5-10.5 4.701-10.5 10.5-10.5 10.5 4.701 10.5 10.5Zm-11.715 5.56 7.79-7.79a.677.677 0 0 0 0-.959l-.957-.958a.677.677 0 0 0-.958 0l-6.354 6.354-2.966-2.967a.677.677 0 0 0-.958 0l-.958.958a.677.677 0 0 0 0 .958l4.403 4.404a.677.677 0 0 0 .958 0Z" fill="#e3e3e3" fillRule="evenodd" data-name="Icon awesome-check-circle"/></svg>
                            <span>Today</span>
                        </li>
                        <li onClick={() => setTog(2)} className={tog === 2 ? "active-op1 hide-li-item" : "nondoaa hide-li-item"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="1130 801 21 21"><path d="M1151 811.5c0 5.799-4.701 10.5-10.5 10.5s-10.5-4.701-10.5-10.5 4.701-10.5 10.5-10.5 10.5 4.701 10.5 10.5Zm-11.715 5.56 7.79-7.79a.677.677 0 0 0 0-.959l-.957-.958a.677.677 0 0 0-.958 0l-6.354 6.354-2.966-2.967a.677.677 0 0 0-.958 0l-.958.958a.677.677 0 0 0 0 .958l4.403 4.404a.677.677 0 0 0 .958 0Z" fill="#e3e3e3" fillRule="evenodd" data-name="Icon awesome-check-circle"/></svg>
                            <span>Week</span>
                        </li>
                        <li onClick={() => setTog(3)} className={tog === 3 ? "active-op1 hide-li-item" : "nondoaa hide-li-item"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="1130 801 21 21"><path d="M1151 811.5c0 5.799-4.701 10.5-10.5 10.5s-10.5-4.701-10.5-10.5 4.701-10.5 10.5-10.5 10.5 4.701 10.5 10.5Zm-11.715 5.56 7.79-7.79a.677.677 0 0 0 0-.959l-.957-.958a.677.677 0 0 0-.958 0l-6.354 6.354-2.966-2.967a.677.677 0 0 0-.958 0l-.958.958a.677.677 0 0 0 0 .958l4.403 4.404a.677.677 0 0 0 .958 0Z" fill="#e3e3e3" fillRule="evenodd" data-name="Icon awesome-check-circle"/></svg>
                            <span>Month</span>
                        </li>
                        <li onClick={() => setTog(4)} className={tog === 4 ? "active-op1 hide-li-item" : "nondoaa hide-li-item"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="1130 801 21 21"><path d="M1151 811.5c0 5.799-4.701 10.5-10.5 10.5s-10.5-4.701-10.5-10.5 4.701-10.5 10.5-10.5 10.5 4.701 10.5 10.5Zm-11.715 5.56 7.79-7.79a.677.677 0 0 0 0-.959l-.957-.958a.677.677 0 0 0-.958 0l-6.354 6.354-2.966-2.967a.677.677 0 0 0-.958 0l-.958.958a.677.677 0 0 0 0 .958l4.403 4.404a.677.677 0 0 0 .958 0Z" fill="#e3e3e3" fillRule="evenodd" data-name="Icon awesome-check-circle"/></svg>
                            <span>Year</span>
                        </li>
                        <li onClick={() => setTog(5)} className={tog === 5 ? "active-op1 date-picker-li hide-li-item" : "nondoaa date-picker-li hide-li-item"} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="18.375" height="21" viewBox="15.313 8 18.375 21"><path d="M33.195 14.563h-17.39a.492.492 0 0 1-.492-.493v-1.476c0-1.088.88-1.969 1.968-1.969h1.969V8.492c0-.272.22-.492.492-.492h1.64c.273 0 .493.22.493.492v2.133h5.25V8.492c0-.272.22-.492.492-.492h1.64c.273 0 .493.22.493.492v2.133h1.969c1.087 0 1.968.881 1.968 1.969v1.476c0 .272-.22.492-.492.492Zm-17.39 1.312h17.39c.272 0 .492.22.492.492v10.664A1.969 1.969 0 0 1 31.72 29H17.28a1.969 1.969 0 0 1-1.968-1.969V16.367c0-.272.22-.492.492-.492Zm13.67 3.935-1.155-1.164a.492.492 0 0 0-.696-.003l-4.35 4.314-1.885-1.901a.492.492 0 0 0-.697-.003l-1.164 1.155a.492.492 0 0 0-.003.696l3.388 3.416a.492.492 0 0 0 .696.003l5.863-5.817a.492.492 0 0 0 .003-.696Z" fill="#e3e3e3" fillRule="evenodd" data-name="Icon awesome-calendar-check"/></svg>
                            <span className="d-inline-block">                                        
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={date => setSelectedDate(date)}
                                    dateFormat="yyyy/mm/dd"
                                    placeholderText="YYYY-MM-DD"
                                    className="custom-datepicker"
                                    // selectsRange={true}
                                    // inline
                                />
                            </span>
                        </li>
                        <li onClick={() => setTog(6)} className={tog === 6 ? "active-op1 date-picker-li me-0 hide-li-item" : "nondoaa date-picker-li me-0 hide-li-item"}  >
                            <svg xmlns="http://www.w3.org/2000/svg" width="18.375" height="21" viewBox="15.313 8 18.375 21"><path d="M33.195 14.563h-17.39a.492.492 0 0 1-.492-.493v-1.476c0-1.088.88-1.969 1.968-1.969h1.969V8.492c0-.272.22-.492.492-.492h1.64c.273 0 .493.22.493.492v2.133h5.25V8.492c0-.272.22-.492.492-.492h1.64c.273 0 .493.22.493.492v2.133h1.969c1.087 0 1.968.881 1.968 1.969v1.476c0 .272-.22.492-.492.492Zm-17.39 1.312h17.39c.272 0 .492.22.492.492v10.664A1.969 1.969 0 0 1 31.72 29H17.28a1.969 1.969 0 0 1-1.968-1.969V16.367c0-.272.22-.492.492-.492Zm13.67 3.935-1.155-1.164a.492.492 0 0 0-.696-.003l-4.35 4.314-1.885-1.901a.492.492 0 0 0-.697-.003l-1.164 1.155a.492.492 0 0 0-.003.696l3.388 3.416a.492.492 0 0 0 .696.003l5.863-5.817a.492.492 0 0 0 .003-.696Z" fill="#e3e3e3" fillRule="evenodd" data-name="Icon awesome-calendar-check"/></svg>
                            <span className="d-inline-block">                                        
                                <DatePicker
                                    selected={startDate}
                                    onChange={handleDateChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange={true}
                                    dateFormat="yyyy/mm/dd"
                                    className="custom-datepicker-range"
                                    placeholderText="YYYY-MM-DD - YYYY-MM-DD"
                                    // inline
                                />
                            </span>
                        </li>
                        <li onClick={() => setTog(14)} className={tog === 14 ? "active-op1 filter-mobile-icon " : "nondoaa filter-mobile-icon"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17.47" height="17.47" viewBox="357.892 78.392 17.47 17.47"><g data-name="Icon ionic-md-options"><g data-name="Group 253"><path d="M357.892 92.119H368.5v1.248h-10.607v-1.248Z" fill="#707070" fillRule="evenodd" data-name="Path 128"/><path d="M372.243 92.119h3.12v1.248h-3.12v-1.248Z" fill="#707070" fillRule="evenodd" data-name="Path 129"/><path d="M371.619 94.595c0 .7-.559 1.268-1.248 1.268-.69 0-1.248-.568-1.248-1.268V90.89c0-.7.559-1.267 1.248-1.267.69 0 1.248.568 1.248 1.267v3.705Z" fill="#707070" fillRule="evenodd" data-name="Path 130"/></g><g data-name="Group 254"><path d="M357.892 86.503h3.12v1.248h-3.12v-1.248Z" fill="#707070" fillRule="evenodd" data-name="Path 131"/><path d="M364.755 86.503h10.608v1.248h-10.608v-1.248Z" fill="#707070" fillRule="evenodd" data-name="Path 132"/><path d="M364.132 88.98c0 .7-.56 1.267-1.248 1.267-.69 0-1.248-.567-1.248-1.267v-3.705c0-.7.558-1.267 1.248-1.267.689 0 1.248.567 1.248 1.267v3.705Z" fill="#707070" fillRule="evenodd" data-name="Path 133"/></g><g data-name="Group 255"><path d="M357.892 80.888H368.5v1.248h-10.607v-1.248Z" fill="#707070" fillRule="evenodd" data-name="Path 134"/><path d="M372.243 80.888h3.12v1.248h-3.12v-1.248Z" fill="#707070" fillRule="evenodd" data-name="Path 135"/><path d="M371.619 83.364c0 .7-.559 1.268-1.248 1.268-.69 0-1.248-.568-1.248-1.268V79.66c0-.7.559-1.267 1.248-1.267.69 0 1.248.568 1.248 1.267v3.705Z" fill="#707070" fillRule="evenodd" data-name="Path 136"/></g></g></svg>
                        </li>
                    </ul> */}
                </div>
            </div>

            {/* Power Used Chart and states section  Stats */}
            
            
            {loading2 ? (
                <div className='w-100' style={{height: '20vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '33vh', paddingBottom: '33vh'}}>
                    <BallTriangle
                        height={90}
                        width={90}
                        radius={5}
                        color="#814097"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle="" 
                        visible={true}
                    />
                </div>
            ) : (
                <div className="energy-used-graph w-100">
                    <h3 className="ov-style5 d-flex justify-content-between">
                        <div>
                            {chargerUnit === 'AMP' ? `Current` : `Power`}
                        </div>
                        <div>                        
                            <ul className="filter-list-ul ps-0 p-0 pt-0">
                                <li onClick={() => setTog2(1)} className={tog2 === 1 ? "active-op1" : "nondoaa"}>
                                    <svg className="hide-li-item" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="1130 801 21 21"><path d="M1151 811.5c0 5.799-4.701 10.5-10.5 10.5s-10.5-4.701-10.5-10.5 4.701-10.5 10.5-10.5 10.5 4.701 10.5 10.5Zm-11.715 5.56 7.79-7.79a.677.677 0 0 0 0-.959l-.957-.958a.677.677 0 0 0-.958 0l-6.354 6.354-2.966-2.967a.677.677 0 0 0-.958 0l-.958.958a.677.677 0 0 0 0 .958l4.403 4.404a.677.677 0 0 0 .958 0Z" fill="#e3e3e3" fillRule="evenodd" data-name="Icon awesome-check-circle"/></svg>
                                    <span>Today</span>
                                </li>
                                <li onClick={() => setTog2(2)} className={tog2 === 2 ? "active-op1 hide-li-item" : "nondoaa hide-li-item"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="1130 801 21 21"><path d="M1151 811.5c0 5.799-4.701 10.5-10.5 10.5s-10.5-4.701-10.5-10.5 4.701-10.5 10.5-10.5 10.5 4.701 10.5 10.5Zm-11.715 5.56 7.79-7.79a.677.677 0 0 0 0-.959l-.957-.958a.677.677 0 0 0-.958 0l-6.354 6.354-2.966-2.967a.677.677 0 0 0-.958 0l-.958.958a.677.677 0 0 0 0 .958l4.403 4.404a.677.677 0 0 0 .958 0Z" fill="#e3e3e3" fillRule="evenodd" data-name="Icon awesome-check-circle"/></svg>
                                    <span>Week</span>
                                </li>
                                <li onClick={() => setTog2(3)} className={tog2 === 3 ? "active-op1 hide-li-item" : "nondoaa hide-li-item"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="1130 801 21 21"><path d="M1151 811.5c0 5.799-4.701 10.5-10.5 10.5s-10.5-4.701-10.5-10.5 4.701-10.5 10.5-10.5 10.5 4.701 10.5 10.5Zm-11.715 5.56 7.79-7.79a.677.677 0 0 0 0-.959l-.957-.958a.677.677 0 0 0-.958 0l-6.354 6.354-2.966-2.967a.677.677 0 0 0-.958 0l-.958.958a.677.677 0 0 0 0 .958l4.403 4.404a.677.677 0 0 0 .958 0Z" fill="#e3e3e3" fillRule="evenodd" data-name="Icon awesome-check-circle"/></svg>
                                    <span>Month</span>
                                </li>
                                <li onClick={() => setTog2(4)} className={tog2 === 4 ? "active-op1 hide-li-item" : "nondoaa hide-li-item"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="1130 801 21 21"><path d="M1151 811.5c0 5.799-4.701 10.5-10.5 10.5s-10.5-4.701-10.5-10.5 4.701-10.5 10.5-10.5 10.5 4.701 10.5 10.5Zm-11.715 5.56 7.79-7.79a.677.677 0 0 0 0-.959l-.957-.958a.677.677 0 0 0-.958 0l-6.354 6.354-2.966-2.967a.677.677 0 0 0-.958 0l-.958.958a.677.677 0 0 0 0 .958l4.403 4.404a.677.677 0 0 0 .958 0Z" fill="#e3e3e3" fillRule="evenodd" data-name="Icon awesome-check-circle"/></svg>
                                    <span>Year</span>
                                </li>
                            </ul>
                        </div>
                    </h3>
                    <p className="mb-0 unit-style">{ charger ? '' : (max && `(${max})`)} {feeder ? `kVA` : meter ? `kW` : charger ? chargerUnit :'kW'}</p>
                    {tog2 === 2 ? (
                        <ColumnGraph max={max} tooltipLabel={charger ? (chargerUnit === 'AMP' ? 'Current' : 'Power') : "Power"} label="Power" tog2={2} weekDateLabel={weekDateLabel} labs={weekLabel}  data2={powerChartData?.week} />
                    ) : tog2 === 3 ? (
                        <ColumnGraph max={max} tooltipLabel={charger ? (chargerUnit === 'AMP' ? 'Current' : 'Power') : "Power"} label="Power" labs={monthLabel} data2={powerChartData?.month} />
                    ) : tog2 === 4 ? (
                        <ColumnGraph max={max} tooltipLabel={charger ? (chargerUnit === 'AMP' ? 'Current' : 'Power') : "Power"} label="Power" tog={4} labs={yearLabel} data2={powerChartData?.year} />
                    ) : (
                        <ColumnGraph max={max} tooltipLabel={charger ? (chargerUnit === 'AMP' ? 'Current' : 'Power') : "Power"} label="Power" tog2={1} labs={dayLabel2} data2={powerChartData?.today} />
                    )}
                    {/* <ColumnGraph label="Energy Used" labs={tog === 1 ? dayLabel : tog === 2 ? WeekLabel : tog === 3 ? labs?.month : yearLabel} data2={tog === 1 ? chartData?.today : tog === 2 ? chartData?.week : tog === 3 ? chartData?.month : chartData?.year} /> */}


                    {/* <RealTimeChart /> */}
                    <h4 style={{margin: `${tog2 === 1 ? '-25px' : '-20px'}`}} className="time-stylex mb-0 pt-1 text-center">{tog2 === 4 ? getCurrentYear() : tog2 === 3 ? `${getCurrentYear()} ${getCurrentMonthName()}` : tog2 === 1 ? formatDate(new Date()) : ''}</h4>

                    <div className="w-100  energy-stats-section">
                        <div className="energy-stat-card" style={{border: 'none'}}>
                            {/* <h5 className="mb-0">Date Range Energy Used</h5>
                            <h3>00.00</h3>
                            <h6><span>- % </span> vs last year</h6> */}
                            {/* {type !== 1 && (
                                <Circle />
                            )} */}
                            <h5 className="mb-0">Peak Points</h5>
                            <h3>{powerChartData ? ( tog2 === 1 ? (findPeakPoint(powerChartData?.today))?.toLocaleString() : tog2 === 2 ? (findPeakPoint(powerChartData?.week))?.toLocaleString() : tog2 === 3 ? (findPeakPoint(powerChartData?.month))?.toLocaleString() : tog2 === 4 ? (findPeakPoint(powerChartData?.year))?.toLocaleString() : 0.00 ) : (0.00)} {charger ? (chargerUnit === 'AMP' ? 'A' : 'kW') : 'kW'}</h3>
                            {/* <h6>
                                <span className={lastYearData?.power >= 0 ? "green" : "red"}>
                                    {lastYearData?.mmonth >= 0 ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="7.999" height="8.209" viewBox="836.001 1429.294 7.999 8.209"><path d="m836.537 1434.013-.407-.407a.438.438 0 0 1 0-.62l3.56-3.562a.438.438 0 0 1 .62 0l3.56 3.56a.438.438 0 0 1 0 .62l-.406.407a.44.44 0 0 1-.628-.007l-2.102-2.206v5.266c0 .243-.196.44-.44.44h-.586a.439.439 0 0 1-.44-.44v-5.266l-2.103 2.208a.437.437 0 0 1-.628.007Z" fill="#12b76a" fillRule="evenodd" data-name="Icon awesome-arrow-up"/></svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="7.999" height="8.209" viewBox="836 1429.294 7.999 8.209"><path d="m843.463 1432.784.407.407a.438.438 0 0 1 0 .62l-3.56 3.562a.438.438 0 0 1-.62 0l-3.56-3.56a.438.438 0 0 1 0-.62l.406-.407a.44.44 0 0 1 .628.007l2.102 2.206v-5.266c0-.243.196-.44.44-.44h.586c.244 0 .44.197.44.44V1435l2.103-2.208a.437.437 0 0 1 .628-.007Z" fill="red" fillRule="evenodd" data-name="Icon awesome-arrow-up"/></svg>
                                    )}
                                    {((Number(lastYearData?.month ? lastYearData?.month : 0)).toFixed(2))?.toLocaleString()} % 
                                </span>
                                    vs last year
                            </h6> */}
                        </div>

                        <div className="energy-stat-card">
                            <h5 className="mb-0">Avg Peak Point</h5>
                            <h3>{powerChartData ? ( tog2 === 1 ? ((findAveragePeak(powerChartData?.today))?.toFixed(2))?.toLocaleString() : tog2 === 2 ? ((findAveragePeak(powerChartData?.week))?.toFixed(2))?.toLocaleString() : tog2 === 3 ? ((findAveragePeak(powerChartData?.month))?.toFixed(2))?.toLocaleString() : tog2 === 4 ? ((findAveragePeak(powerChartData?.year))?.toFixed(2))?.toLocaleString() : 0.00 ) : (0.00)}{charger ? (chargerUnit === 'AMP' ? 'A' : 'kW') : 'kW'}</h3>
                            {/* <h6>
                                <span className={lastYearData?.today >= 0 ? "green" : "red"}>
                                    {lastYearData?.today >= 0 ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="7.999" height="8.209" viewBox="836.001 1429.294 7.999 8.209"><path d="m836.537 1434.013-.407-.407a.438.438 0 0 1 0-.62l3.56-3.562a.438.438 0 0 1 .62 0l3.56 3.56a.438.438 0 0 1 0 .62l-.406.407a.44.44 0 0 1-.628-.007l-2.102-2.206v5.266c0 .243-.196.44-.44.44h-.586a.439.439 0 0 1-.44-.44v-5.266l-2.103 2.208a.437.437 0 0 1-.628.007Z" fill="#12b76a" fillRule="evenodd" data-name="Icon awesome-arrow-up"/></svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="7.999" height="8.209" viewBox="836 1429.294 7.999 8.209"><path d="m843.463 1432.784.407.407a.438.438 0 0 1 0 .62l-3.56 3.562a.438.438 0 0 1-.62 0l-3.56-3.56a.438.438 0 0 1 0-.62l.406-.407a.44.44 0 0 1 .628.007l2.102 2.206v-5.266c0-.243.196-.44.44-.44h.586c.244 0 .44.197.44.44V1435l2.103-2.208a.437.437 0 0 1 .628-.007Z" fill="red" fillRule="evenodd" data-name="Icon awesome-arrow-up"/></svg>
                                    )}
                                    {((Number(lastYearData?.today ? lastYearData?.today : 0)).toFixed(2))?.toLocaleString()} % 
                                </span>
                                    vs last year
                            </h6> */}
                        </div>
                        
                        

                    </div>
                </div>
            )}
            
            {/* Power Used Chart and states section  Stats */}

            {/* Energy Used Chart and states section  Stats */}
            
            {loading2 ? (
                <div className='w-100' style={{height: '20vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '33vh', paddingBottom: '33vh'}}>
                    <BallTriangle
                        height={90}
                        width={90}
                        radius={5}
                        color="#814097"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle="" 
                        visible={true}
                    />
                </div>
            ) : (

                <div className="energy-used-graph w-100">
                    <h3 className="ov-style5 d-flex justify-content-between">
                        <div>
                            Energy Usage
                        </div>  
                        <div>                        
                            <ul className="filter-list-ul ps-0 p-0 pt-0">
                                <li onClick={() => setTog(1)} className={tog === 1 ? "active-op1" : "nondoaa"}>
                                    <svg className="hide-li-item" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="1130 801 21 21"><path d="M1151 811.5c0 5.799-4.701 10.5-10.5 10.5s-10.5-4.701-10.5-10.5 4.701-10.5 10.5-10.5 10.5 4.701 10.5 10.5Zm-11.715 5.56 7.79-7.79a.677.677 0 0 0 0-.959l-.957-.958a.677.677 0 0 0-.958 0l-6.354 6.354-2.966-2.967a.677.677 0 0 0-.958 0l-.958.958a.677.677 0 0 0 0 .958l4.403 4.404a.677.677 0 0 0 .958 0Z" fill="#e3e3e3" fillRule="evenodd" data-name="Icon awesome-check-circle"/></svg>
                                    <span>Today</span>
                                </li>
                                <li onClick={() => setTog(2)} className={tog === 2 ? "active-op1 hide-li-item" : "nondoaa hide-li-item"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="1130 801 21 21"><path d="M1151 811.5c0 5.799-4.701 10.5-10.5 10.5s-10.5-4.701-10.5-10.5 4.701-10.5 10.5-10.5 10.5 4.701 10.5 10.5Zm-11.715 5.56 7.79-7.79a.677.677 0 0 0 0-.959l-.957-.958a.677.677 0 0 0-.958 0l-6.354 6.354-2.966-2.967a.677.677 0 0 0-.958 0l-.958.958a.677.677 0 0 0 0 .958l4.403 4.404a.677.677 0 0 0 .958 0Z" fill="#e3e3e3" fillRule="evenodd" data-name="Icon awesome-check-circle"/></svg>
                                    <span>Week</span>
                                </li>
                                <li onClick={() => setTog(3)} className={tog === 3 ? "active-op1 hide-li-item" : "nondoaa hide-li-item"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="1130 801 21 21"><path d="M1151 811.5c0 5.799-4.701 10.5-10.5 10.5s-10.5-4.701-10.5-10.5 4.701-10.5 10.5-10.5 10.5 4.701 10.5 10.5Zm-11.715 5.56 7.79-7.79a.677.677 0 0 0 0-.959l-.957-.958a.677.677 0 0 0-.958 0l-6.354 6.354-2.966-2.967a.677.677 0 0 0-.958 0l-.958.958a.677.677 0 0 0 0 .958l4.403 4.404a.677.677 0 0 0 .958 0Z" fill="#e3e3e3" fillRule="evenodd" data-name="Icon awesome-check-circle"/></svg>
                                    <span>Month</span>
                                </li>
                                <li onClick={() => setTog(4)} className={tog === 4 ? "active-op1 hide-li-item" : "nondoaa hide-li-item"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="1130 801 21 21"><path d="M1151 811.5c0 5.799-4.701 10.5-10.5 10.5s-10.5-4.701-10.5-10.5 4.701-10.5 10.5-10.5 10.5 4.701 10.5 10.5Zm-11.715 5.56 7.79-7.79a.677.677 0 0 0 0-.959l-.957-.958a.677.677 0 0 0-.958 0l-6.354 6.354-2.966-2.967a.677.677 0 0 0-.958 0l-.958.958a.677.677 0 0 0 0 .958l4.403 4.404a.677.677 0 0 0 .958 0Z" fill="#e3e3e3" fillRule="evenodd" data-name="Icon awesome-check-circle"/></svg>
                                    <span>Year</span>
                                </li>
                            </ul>
                        </div>
                    </h3>
                    {/* <p className="mb-0 unit-style">{feeder ? `(${apiData?.feeders?.feederCapacity}) kVA` : 'kWh'}</p>  */}
                    <p className="mb-0 unit-style">kWh</p> 
                    {tog === 2 ? (
                        <ColumnGraph tooltipLabel="Energy Used" label="Energy Used" tog2={2} weekDateLabel={weekDateLabel} labs={weekLabel} data2={chartData?.week} />
                    ) : tog === 3 ? (
                        <ColumnGraph tooltipLabel="Energy Used" label="Energy Used" labs={monthLabel} data2={chartData?.month} />
                    ) : tog === 4 ? (
                        <ColumnGraph tooltipLabel="Energy Used" label="Energy Used" tog={4} labs={yearLabel} data2={chartData?.year} />
                    ) : (
                        <ColumnGraph tooltipLabel="Energy Used" label="Energy Used" labs={dayLabel} data2={chartData?.today} />
                    )}
                    
                    {/* <ColumnGraph label="Energy Used" labs={tog === 1 ? dayLabel : tog === 2 ? WeekLabel : tog === 3 ? labs?.month : yearLabel} data2={tog === 1 ? chartData?.today : tog === 2 ? chartData?.week : tog === 3 ? chartData?.month : chartData?.year} /> */}

                    <h4 style={{margin: `${tog === 1 ? '-25px' : '-20px'}`}} className="time-stylex mb-0 pt-1 text-center">{tog === 4 ? getCurrentYear() : tog === 3 ? `${getCurrentYear()} ${getCurrentMonthName()}` : tog === 1 ? formatDate(new Date()) : ''}</h4>


                    {/* <RealTimeChart /> */}
                    {/* <p className="time-style mb-0 pt-1">Time</p> */}

                    <div className="w-100  energy-stats-section">
                        {/* <div className="energy-stat-card" style={{border: 'none'}}> */}
                            {/* <h5 className="mb-0">Date Range Energy Used</h5>
                            <h3>00.00</h3>
                            <h6><span>- % </span> vs last year</h6> */}
                            {/* {type !== 1 && (
                                <Circle />
                            )} */}
                            {/* <h5 className="mb-0">Peak Point</h5>
                            <h3>{data?.power ? ( tog === 1 ? ((Number(data?.power?.today_power))?.toFixed(2))?.toLocaleString() : tog === 2 ? ((Number(data?.power?.week_power))?.toFixed(2))?.toLocaleString() : tog === 3 ? ((Number(data?.power?.month_power))?.toFixed(2))?.toLocaleString() : tog === 4 ? ((Number(data?.power?.year_power))?.toFixed(2))?.toLocaleString() : 0.00 ) : (0.00)}</h3>
                            <h6>
                                <span className={lastYearData?.power >= 0 ? "green" : "red"}>
                                    {lastYearData?.mmonth >= 0 ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="7.999" height="8.209" viewBox="836.001 1429.294 7.999 8.209"><path d="m836.537 1434.013-.407-.407a.438.438 0 0 1 0-.62l3.56-3.562a.438.438 0 0 1 .62 0l3.56 3.56a.438.438 0 0 1 0 .62l-.406.407a.44.44 0 0 1-.628-.007l-2.102-2.206v5.266c0 .243-.196.44-.44.44h-.586a.439.439 0 0 1-.44-.44v-5.266l-2.103 2.208a.437.437 0 0 1-.628.007Z" fill="#12b76a" fillRule="evenodd" data-name="Icon awesome-arrow-up"/></svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="7.999" height="8.209" viewBox="836 1429.294 7.999 8.209"><path d="m843.463 1432.784.407.407a.438.438 0 0 1 0 .62l-3.56 3.562a.438.438 0 0 1-.62 0l-3.56-3.56a.438.438 0 0 1 0-.62l.406-.407a.44.44 0 0 1 .628.007l2.102 2.206v-5.266c0-.243.196-.44.44-.44h.586c.244 0 .44.197.44.44V1435l2.103-2.208a.437.437 0 0 1 .628-.007Z" fill="red" fillRule="evenodd" data-name="Icon awesome-arrow-up"/></svg>
                                    )}
                                    {((Number(lastYearData?.month ? lastYearData?.month : 0)).toFixed(2))?.toLocaleString()} % 
                                </span>
                                    vs last year
                            </h6> */}
                        {/* </div> */}
 
                        <div className="energy-stat-card" style={{border: 'none'}}>
                            <h5 className="mb-0">Today Energy Used</h5>
                            <h3>{data?.today ? (data?.today)?.toLocaleString() : (0.00)} kWh</h3>
                            {/* <h6>
                                <span className={lastYearData?.today >= 0 ? "green" : "red"}>
                                    {lastYearData?.today >= 0 ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="7.999" height="8.209" viewBox="836.001 1429.294 7.999 8.209"><path d="m836.537 1434.013-.407-.407a.438.438 0 0 1 0-.62l3.56-3.562a.438.438 0 0 1 .62 0l3.56 3.56a.438.438 0 0 1 0 .62l-.406.407a.44.44 0 0 1-.628-.007l-2.102-2.206v5.266c0 .243-.196.44-.44.44h-.586a.439.439 0 0 1-.44-.44v-5.266l-2.103 2.208a.437.437 0 0 1-.628.007Z" fill="#12b76a" fillRule="evenodd" data-name="Icon awesome-arrow-up"/></svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="7.999" height="8.209" viewBox="836 1429.294 7.999 8.209"><path d="m843.463 1432.784.407.407a.438.438 0 0 1 0 .62l-3.56 3.562a.438.438 0 0 1-.62 0l-3.56-3.56a.438.438 0 0 1 0-.62l.406-.407a.44.44 0 0 1 .628.007l2.102 2.206v-5.266c0-.243.196-.44.44-.44h.586c.244 0 .44.197.44.44V1435l2.103-2.208a.437.437 0 0 1 .628-.007Z" fill="red" fillRule="evenodd" data-name="Icon awesome-arrow-up"/></svg>
                                    )}
                                    {((Number(lastYearData?.today ? lastYearData?.today : 0)).toFixed(2))?.toLocaleString()} % 
                                </span>
                                    vs last year
                            </h6> */}
                        </div>
                        
                        <div className="energy-stat-card">
                            <h5 className="mb-0">Week Energy Used</h5>
                            <h3>{data?.week ? (data?.week)?.toLocaleString() : (0.00)} kWh</h3>
                            {/* <h6>
                                <span className={lastYearData?.month >= 0 ? "green" : "red"}>
                                    {lastYearData?.week >= 0 ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="7.999" height="8.209" viewBox="836.001 1429.294 7.999 8.209"><path d="m836.537 1434.013-.407-.407a.438.438 0 0 1 0-.62l3.56-3.562a.438.438 0 0 1 .62 0l3.56 3.56a.438.438 0 0 1 0 .62l-.406.407a.44.44 0 0 1-.628-.007l-2.102-2.206v5.266c0 .243-.196.44-.44.44h-.586a.439.439 0 0 1-.44-.44v-5.266l-2.103 2.208a.437.437 0 0 1-.628.007Z" fill="#12b76a" fillRule="evenodd" data-name="Icon awesome-arrow-up"/></svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="7.999" height="8.209" viewBox="836 1429.294 7.999 8.209"><path d="m843.463 1432.784.407.407a.438.438 0 0 1 0 .62l-3.56 3.562a.438.438 0 0 1-.62 0l-3.56-3.56a.438.438 0 0 1 0-.62l.406-.407a.44.44 0 0 1 .628.007l2.102 2.206v-5.266c0-.243.196-.44.44-.44h.586c.244 0 .44.197.44.44V1435l2.103-2.208a.437.437 0 0 1 .628-.007Z" fill="red" fillRule="evenodd" data-name="Icon awesome-arrow-up"/></svg>
                                    )}
                                    {((Number(lastYearData?.week ? lastYearData?.week : 0)).toFixed(2))?.toLocaleString()} % 
                                </span>
                                    vs last year
                            </h6> */}
                        </div>

                        <div className="energy-stat-card">
                            <h5 className="mb-0">Month Energy Used</h5>
                            <h3>{data?.month ? (data?.month)?.toLocaleString() : (0.00)} kWh</h3>
                            {/* <h6>
                                <span className={lastYearData?.month >= 0 ? "green" : "red"}>
                                    {lastYearData?.month >= 0 ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="7.999" height="8.209" viewBox="836.001 1429.294 7.999 8.209"><path d="m836.537 1434.013-.407-.407a.438.438 0 0 1 0-.62l3.56-3.562a.438.438 0 0 1 .62 0l3.56 3.56a.438.438 0 0 1 0 .62l-.406.407a.44.44 0 0 1-.628-.007l-2.102-2.206v5.266c0 .243-.196.44-.44.44h-.586a.439.439 0 0 1-.44-.44v-5.266l-2.103 2.208a.437.437 0 0 1-.628.007Z" fill="#12b76a" fillRule="evenodd" data-name="Icon awesome-arrow-up"/></svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="7.999" height="8.209" viewBox="836 1429.294 7.999 8.209"><path d="m843.463 1432.784.407.407a.438.438 0 0 1 0 .62l-3.56 3.562a.438.438 0 0 1-.62 0l-3.56-3.56a.438.438 0 0 1 0-.62l.406-.407a.44.44 0 0 1 .628.007l2.102 2.206v-5.266c0-.243.196-.44.44-.44h.586c.244 0 .44.197.44.44V1435l2.103-2.208a.437.437 0 0 1 .628-.007Z" fill="red" fillRule="evenodd" data-name="Icon awesome-arrow-up"/></svg>
                                    )}
                                    {((Number(lastYearData?.month ? lastYearData?.month : 0)).toFixed(2))?.toLocaleString()} % 
                                </span>
                                    vs last year
                            </h6> */}
                        </div>

                        <div className="energy-stat-card">
                            <h5 className="mb-0">Year Energy Used</h5>
                            <h3>{data?.year ? (data?.year)?.toLocaleString() : (0.00)} kWh</h3>
                            {/* <h6>
                                <span className={lastYearData?.year >= 0 ? "green" : "red"}>
                                    {lastYearData?.year >= 0 ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="7.999" height="8.209" viewBox="836.001 1429.294 7.999 8.209"><path d="m836.537 1434.013-.407-.407a.438.438 0 0 1 0-.62l3.56-3.562a.438.438 0 0 1 .62 0l3.56 3.56a.438.438 0 0 1 0 .62l-.406.407a.44.44 0 0 1-.628-.007l-2.102-2.206v5.266c0 .243-.196.44-.44.44h-.586a.439.439 0 0 1-.44-.44v-5.266l-2.103 2.208a.437.437 0 0 1-.628.007Z" fill="#12b76a" fillRule="evenodd" data-name="Icon awesome-arrow-up"/></svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="7.999" height="8.209" viewBox="836 1429.294 7.999 8.209"><path d="m843.463 1432.784.407.407a.438.438 0 0 1 0 .62l-3.56 3.562a.438.438 0 0 1-.62 0l-3.56-3.56a.438.438 0 0 1 0-.62l.406-.407a.44.44 0 0 1 .628.007l2.102 2.206v-5.266c0-.243.196-.44.44-.44h.586c.244 0 .44.197.44.44V1435l2.103-2.208a.437.437 0 0 1 .628-.007Z" fill="red" fillRule="evenodd" data-name="Icon awesome-arrow-up"/></svg>
                                    )}
                                    {((Number(lastYearData?.year)).toFixed(2))?.toLocaleString()} % 
                                </span>
                                    vs last year
                            </h6> */}
                        </div>

                    </div>
                </div>
            )}

            {/* Energy Used Chart and states section  Ends */}

        </div>
    )
}

export default EnergyUsed;