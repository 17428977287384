import React, { useEffect } from "react";
import Sidebar from "../../common/Sidebar";
import Navbar from "../../common/Navbar";
import { Outlet, useNavigate } from "react-router-dom";

const Client = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('pea_client');
        if(token === null){
          navigate('/login');
        }
    }, [])

    return (
        <>
            <div className="App">
                <Sidebar type={1} />
                <div className='content-div'>
                    <Navbar type={1} />
                    <Outlet />                    
                </div>
          </div>
        </>
    )
}


export default Client;