import React, { useState, useRef, useEffect } from "react";
import dash1Icon from '../../../../assets/icons/dash1.png';
import dash2Icon from '../../../../assets/icons/dash2.png';
import dash3Icon from '../../../../assets/icons/dash3.png';
import dash4Icon from '../../../../assets/icons/dash4.png';
import dash5Icon from '../../../../assets/icons/dash5.png';

import map1Icon from '../../../../assets/icons/map3.png';

import federIcon from '../../../../assets/icons/feeder.svg';
import transformerIcon from '../../../../assets/icons/transformer.svg';
import meterIcon from '../../../../assets/icons/meter.svg';
import chargerIcon from '../../../../assets/icons/charger.svg';
import alertIcon from '../../../../assets/icons/alert.svg';
import Map from "../../../common/Map";
import DataTableBase from "../../../common/DataTableBase";

// import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import ColumnGraph from "../../../common/ColumnGraph";
import EnergyUsed from "../../../common/EnergyUsed";
import RealTimeChart from "../../../common/RealTimeChart";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BallTriangle } from 'react-loader-spinner'
import { apiEndPoint } from "../../../../utils";


const Overview = ({type}) => {    

    // let center = {};

    // let markers = [
        // { 
        //     id: 1, 
        //     lat: 15.9703, 
        //     lng: 100.4995,
        //     icon:  dash1Icon,
        // },
        // { 
        //     id: 2, 
        //     lat: 15.8700, 
        //     lng: 100.9925, 
        //     icon: dash1Icon,
        // },
        // { 
        //     id: 3, 
        //     lat: 15.6700, 
        //     lng: 100.09925, 
        //     icon: map1Icon,
        // },
    // ];

    const columns = [
        {
            name: "Charger ID",
            selector: row => row?.chargerId,
        },
        {
            name: "Home Name",
            selector: row => row?.homeName
        },
        {
            name: "Date Time",
            selector: row => row?.dateTime,
            sortable: true,
        },
        {
            name: "Status",
            selector: row => row?.status
        },
        {
            name: "Message",
            selector: row => row?.message
        }
    ]

    const dataa = [
        {
            chargerId: "T53-477S2-012",
            homeName: "Home A",
            dateTime: "19:00",
            status: "Warning",
            message: "Energy Used Over 100%",
        },
        {
            chargerId: "T53-477S2-013",
            homeName: "Home B",
            dateTime: "17:00",
            status: "Danger",
            message: "Energy Used Over 60%",
        },
        {
            chargerId: "T53-477S2-014",
            homeName: "Home C",
            dateTime: "21:00",
            status: "Warning",
            message: "Energy Used Over 80%",
        },
    ];



    const [logs, setLogs] = useState([]);
    const [logs2, setLogs2] = useState([]);
    
    const navigate = useNavigate();
    
    const [data, setData] = useState([]);
    const [substations, setSubstations] = useState([]);
    const [chargers, setChargers] = useState([]);
    const [feeders, setFeeders] = useState([]);
    const [meters, setMeters] = useState([]);
    const [transformers, setTransformers] = useState([]);
    
    const [substationMark, setSubstationMark] = useState([]);
    const [chargerMark, setChargerMark] = useState([]);
    const [feederMark, setFeederMark] = useState([]);
    const [meterMark, setMeterMark] = useState([]);
    const [transformerMark, setTransformerMark] = useState([]);

    const [search, setSearch] = useState(null);
    
    const [substation, setSubstation] = useState(true);
    const [charger, setCharger] = useState(true);
    const [feeder, setFeeder] = useState(true);
    const [meter, setMeter] = useState(true);
    const [transformer, setTransformer] = useState(true);
    
    const [markers, setMarkers] = useState([]);
    const [center, setCenter] = useState({});
    
    const [flag, setFlag] = useState(2);

    const [loading, setLoading] = useState(true);

    const [tableData, setTableData] = useState({});



    useEffect(() => {
        const token = localStorage.getItem("pea_admin");
        axios.get(`${apiEndPoint}/api/admin/dashboard`, {
            headers: {Authorization: `Bearer ${token}`}
        }).then((res) => {
            console.log("Res dashboard : ", res);
            setData(res?.data);
            setSubstations(res?.data?.substations);
            setTransformers(res?.data?.transformer);
            setMeters(res?.data?.meters);
            setFeeders(res?.data?.feeders);
            setChargers(res?.data?.chargers);
            let markerSubstation = [];
            // let markerFeeder = [];
            let markerTransformer = [];
            let markerMeter = [];
            let markerCharger = [];
            let centerTemp = [];
            res?.data?.substations?.map((item, index) => {
                if(index === 0){
                    centerTemp = { lat: parseFloat(item?.substationlocationLat), lng: parseFloat(item?.substationlocationLong) };
                    setCenter(centerTemp);
                }
                markerSubstation?.push({ 
                    id: item?.id, 
                    lat: parseFloat(item?.substationlocationLat), 
                    lng: parseFloat(item?.substationlocationLong),
                    icon:  dash1Icon,
                    data: item,
                    type: "substation"
                })
            })
            
            setSubstationMark(markerSubstation);

            // res?.data?.feeders?.map((item) => {
            //     markerFeeder?.push({ 
            //         id: item?.id, 
            //         lat: parseFloat(item?.feederlocationLat), 
            //         lng: parseFloat(item?.feederlocationLong),
            //         icon:  federIcon,
            //     })
            // })

            // setFeederMark(markerFeeder);

            res?.data?.transformer?.map((item) => {
                markerTransformer?.push({ 
                    id: item?.id, 
                    lat: parseFloat(item?.translocationLat), 
                    lng: parseFloat(item?.translocationLong),
                    icon: transformerIcon,
                    data: item,
                    type: "transformer"
                })
            })

            setTransformerMark(markerTransformer);

            res?.data?.meters?.map((item) => {
                markerMeter?.push({ 
                    id: item?.id, 
                    lat: parseFloat(item?.meterlocationLat), 
                    lng: parseFloat(item?.meterlocationLong),
                    icon:  meterIcon,
                    data: item,
                    type: "meter"
                })
            })

            setMeterMark(markerMeter);

            res?.data?.chargers?.map((item) => {
                markerCharger?.push({ 
                    id: item?.id, 
                    lat: parseFloat(item?.chargerlocationLat), 
                    lng: parseFloat(item?.chargerlocationLong),
                    icon:  chargerIcon,
                    data: item,
                    type: "charger"
                })
            })

            setChargerMark(markerCharger);

            
            // console.log(markerss)
            // setMarkers(markerss);

            setLoading(false);
            
        }).catch((err) => {
            setLoading(false);
            console.log("Err : ", err);
        })
    }, [flag])

    

    useEffect(() => {
        console.log("substation useEffect ");
        if(substation){
            console.log("substation useEffect true");
            if(search){
                const filteredSubstations = data?.substations?.filter((item) =>
                    item?.substationsID?.toLowerCase().includes(search?.toLowerCase())
                );
                setSubstations(filteredSubstations);
                let markerSubstation = [];
                
                filteredSubstations?.map((item, index) => {
                    markerSubstation?.push({ 
                        id: item?.id, 
                        lat: parseFloat(item?.substationlocationLat), 
                        lng: parseFloat(item?.substationlocationLong),
                        data: item,
                        icon:  dash1Icon,
                    })
                })
                setSubstationMark(markerSubstation);

            } else {
                let markerSubstation = [];
                
                data?.substations?.map((item, index) => {
                    markerSubstation?.push({ 
                        id: item?.id, 
                        lat: parseFloat(item?.substationlocationLat), 
                        lng: parseFloat(item?.substationlocationLong),
                        data: item,
                        icon:  dash1Icon,
                    })
                })
                setSubstationMark(markerSubstation);
                setSubstations(data?.substations);            
            }
        } else {
            setSubstationMark([]);
        }
        // console.log("Substation check value : ", substation);
    }, [substation, search])
    
    // useEffect(() => {
    //     if(feeder){
    //         if(search){
    //             const filteredFeeders = data?.feeders?.filter((item) =>
    //                 item?.feederID?.toLowerCase().includes(search?.toLowerCase())
    //             );
    //             setFeeders(filteredFeeders);
    //             let markerFeeder = [];
    
    //             filteredFeeders?.map((item) => {
    //                 markerFeeder?.push({ 
    //                     id: item?.id, 
    //                     lat: parseFloat(item?.feederlocationLat), 
    //                     lng: parseFloat(item?.feederlocationLong),
    //                     icon:  federIcon,
    //                 })
    //             })    
    //             setFeederMark(markerFeeder);
    //         } else {
    //             let markerFeeder = [];
    
    //             data?.feeders?.map((item) => {
    //                 markerFeeder?.push({ 
    //                     id: item?.id, 
    //                     lat: parseFloat(item?.feederlocationLat), 
    //                     lng: parseFloat(item?.feederlocationLong),
    //                     icon:  federIcon,
    //                 })
    //             })    
    //             setFeederMark(markerFeeder);                
    //             setFeeders(data?.feeders);
    //         }
    //     } else {
    //         setFeederMark([]);
    //     }
    //     // console.log("Substation check value : ", substation);
    // }, [feeder, search])
    
    useEffect(() => {
        if(transformer){
            if(search){
                const filteredTransformers = data?.transformer?.filter((item) =>
                    item?.transID?.toLowerCase().includes(search?.toLowerCase())
                );
                setTransformers(filteredTransformers);
                let markerTransformer = [];            
    
                filteredTransformers?.map((item) => {
                    markerTransformer?.push({ 
                        id: item?.id, 
                        lat: parseFloat(item?.translocationLat), 
                        lng: parseFloat(item?.translocationLong),
                        icon:  transformerIcon,
                    })
                })
                setTransformerMark(markerTransformer);
            } else {
                let markerTransformer = [];            
    
                data?.transformer?.map((item) => {
                    markerTransformer?.push({ 
                        id: item?.id, 
                        lat: parseFloat(item?.translocationLat), 
                        lng: parseFloat(item?.translocationLong),
                        icon:  transformerIcon,
                    })
                })
                setTransformerMark(markerTransformer);
                setTransformers(data?.transformer);                
            }
        } else {
            setTransformerMark([]);
        }
        // console.log("Substation check value : ", substation);
    }, [transformer, search])
    
    useEffect(() => {
        if(meter){
            if(search){
                const filteredMeter = data?.meters?.filter((item) =>
                    item?.meterID?.toLowerCase().includes(search?.toLowerCase())
                );
                setMeters(filteredMeter);
    
                let markerMeter = [];
                
                filteredMeter?.map((item) => {
                    markerMeter?.push({ 
                        id: item?.id, 
                        lat: parseFloat(item?.meterlocationLat), 
                        lng: parseFloat(item?.meterlocationLong),
                        icon:  meterIcon,
                    })
                })
                setMeterMark(markerMeter);
            } else {
                let markerMeter = [];
                
                data?.meters?.map((item) => {
                    markerMeter?.push({ 
                        id: item?.id, 
                        lat: parseFloat(item?.meterlocationLat), 
                        lng: parseFloat(item?.meterlocationLong),
                        icon:  meterIcon,
                    })
                })
                setMeterMark(markerMeter);
                setMeters(data?.meters);                
            }

        } else {
            setMeterMark([]);
        }
        // console.log("Substation check value : ", substation);
    }, [meter, search])

    useEffect(() => {
        if(charger){
            if(search){
                const filteredChargers = data?.chargers?.filter((item) =>
                    item?.chargerID?.toLowerCase().includes(search?.toLowerCase())
                );
                setChargers(filteredChargers);
    
                let markerCharger = [];
    
                filteredChargers?.map((item) => {
                    markerCharger?.push({ 
                        id: item?.id, 
                        lat: parseFloat(item?.chargerlocationLat), 
                        lng: parseFloat(item?.chargerlocationLong),
                        icon:  chargerIcon,
                    })
                })
    
                setChargerMark(markerCharger);
            } else {
                let markerCharger = [];

                data?.chargers?.map((item) => {
                    markerCharger?.push({ 
                        id: item?.id, 
                        lat: parseFloat(item?.chargerlocationLat), 
                        lng: parseFloat(item?.chargerlocationLong),
                        icon:  chargerIcon,
                    })
                })
    
                setChargerMark(markerCharger);
                setChargers(data?.chargers);
            }

        } else {
            setChargerMark([]);
            // setChargers([]);
        }
        // console.log("Substation check value : ", substation);
    }, [charger, search])
    

    // useEffect(() => { console.log("search : ", search)}, [search])

    // Function to add a new log
    // const addLog = () => {
    //     setLogs(prevLogs => [...prevLogs, prevLogs.length + 1]);
    // };

    // const addLog2 = () => {
    //     setLogs2(prevLogs => [...prevLogs, prevLogs.length + 1]);
    // };

    // Simulate adding a new log every 2 seconds
    // useEffect(() => {
    //     const interval = setInterval(addLog, 2000);

    //     return () => {
    //     clearInterval(interval);
    //     };
    // }, []);

    // useEffect(() => {
    //     const interval = setInterval(addLog2, 1400);

    //     return () => {
    //     clearInterval(interval);
    //     };
    // }, []);


    // const logListRef = useRef();
    // const logListRef2 = useRef();

    // Function to scroll to the bottom of the log list
    // const scrollToBottom = () => {
    //     // logListRef.current.scrollTop = logListRef.current.scrollHeight;
    // };

    // useEffect(() => {
    //     scrollToBottom(); // Initial scroll to the bottom
    // }, [logs]);

    if(loading){
        return (
            <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '33vh'}}>
                <BallTriangle
                    height={90}
                    width={90}
                    radius={5}
                    color="#814097"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle="" 
                    visible={true}
                />
            </div>
        )
    } else {

        return (
            <>
                <div className="overview-page">
                    <div className="d-flex">
                        <div className="map-status-div">
                            <h4 className="ov-heading1 mb-0">Map Status</h4>
                            <span>EV charging home locations</span>
                        </div>
                        
                        <div className="map-statuses-div d-flex">
                            <div className="d-flex charger-status-div">
                                <div className="pl-30">
                                    <img src={dash1Icon} className="mt-3" alt="charging-icon" />
                                </div>
                                <div className="ps-2 ms-1">
                                    <h6 className="mb-0 ov-style2">Substation</h6>
                                    <span className="clr-707070">{substations?.length}</span>
                                </div>                            
                            </div>
                            
                            <div className="d-flex charger-status-div width-18x">
                                <div className="pl-30">
                                    <img src={federIcon} className="mt-3" alt="charging-icon" />
                                </div>
                                <div className="ps-2 ms-1">
                                    <h6 className="mb-0 ov-style2">Feeder</h6>
                                    <span className="clr-707070">{feeders?.length}</span>
                                </div>                            
                            </div>

                            <div className="d-flex charger-status-div width-18x">
                                <div className="pl-30">
                                    <img src={transformerIcon} className="mt-3" alt="charging-icon" />
                                </div>
                                <div className="ps-2 ms-1">
                                    <h6 className="mb-0 ov-style2">Transformer</h6>
                                    <span className="clr-707070">{transformers?.length}</span>
                                </div>                            
                            </div>

                            <div className="d-flex charger-status-div width-18x">
                                <div className="pl-30">
                                    <img src={meterIcon} className="mt-3" alt="charging-icon" />
                                </div>
                                <div className="ps-2 ms-1">
                                    <h6 className="mb-0 ov-style2">Meter</h6>
                                    <span className="clr-707070">{meters?.length}</span>
                                </div>                            
                            </div>

                            <div className="d-flex charger-status-div width-18x border-right-none">
                                <div className="pl-30">
                                    {/* <img src={dash5Icon} className="mt-3" alt="charging-icon" /> */}
                                    <img src={chargerIcon} className="mt-3" alt="charging-icon" />
                                </div>
                                <div className="ps-2 ms-1">
                                    <h6 className="mb-0 ov-style2">Charger</h6>
                                    <span className="clr-707070">{chargers?.length}</span>
                                </div>                            
                            </div>
                        </div> 
                    </div>

                    
                    <div className="overview-sec3 w-100 mt-4">
                        <div className="d-flex w-100">
                            <div className="w-32">
                                <input type="text" onChange={(e) => setSearch(e.target.value)} className="form-control w-100 search-input" placeholder="Search by ID" />
                            </div>
                            <div className="w-68 d-flex justify-content-between">
                                <div className="d-flex">
                                    <div className="form-checkx ps-3">
                                        <input className="form-check-input" defaultChecked={substation} type="checkbox" onClick={(e) => setSubstation(e.target.checked)} id="Substation" />
                                        <label className="ms-1 form-check-label" htmlFor="Substation">
                                            Substation
                                        </label>
                                    </div>
                                    {/* <div className="form-checkx ps-3">
                                        <input className="form-check-input" defaultChecked={feeder} type="checkbox" onClick={(e) => setFeeder(e.target.checked)} id="Feeder" />
                                        <label className="ms-1 form-check-label" htmlFor="Feeder">
                                            Feeder
                                        </label>
                                    </div> */}
                                    <div className="form-checkx ps-3">
                                        <input className="form-check-input" defaultChecked={transformer} type="checkbox" onClick={(e) => setTransformer(e.target.checked)} id="Transformer" />
                                        <label className="ms-1 form-check-label" htmlFor="Transformer">
                                            Transformer
                                        </label>
                                    </div>
                                    <div className="form-checkx ps-3">
                                        <input className="form-check-input" defaultChecked={meter} type="checkbox" onClick={(e) => setMeter(e.target.checked)} id="Meter" />
                                        <label className="ms-1 form-check-label" htmlFor="Meter">
                                            Meter
                                        </label>
                                    </div>
                                    <div className="form-checkx ps-3">
                                        <input className="form-check-input" defaultChecked={charger} type="checkbox" onClick={(e) => setCharger(e.target.checked)} id="Charger" />
                                        <label className="ms-1 form-check-label" htmlFor="Charger">
                                            Charger
                                        </label>
                                    </div>
                                </div>
                                {/* <div>
                                    <button className="search-btn">Search</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="w-100 map-div pt-0" >
                        {/* <div style={{position: 'relative', height: '38px'}}>
                            <ul className="status-ul">
                                <li>
                                    <div className="div11"></div> Availiable
                                </li>
                                <li>
                                    <div className="div12"></div> Unusual
                                </li>
                                <li>
                                    <div className="div13"></div> Offline
                                </li>
                            </ul>
                        </div> */}
                        {/* <Map  center={{lat: 13.32862473, lng: 100.95155334}} zoom={6} height="500px" width='calc(100% - 32px)' markers={[...substationMark, ...transformerMark, ...chargerMark, ...meterMark]} /> */}
                        <Map  center={{lat: 13.32862473, lng: 100.95155334}} zoom={6} height="500px" width='calc(100% - 32px)' markers={[...substationMark, ...transformerMark,...chargerMark, ...meterMark ]} />
                    </div>

                    <div>
                        {/* <RealTimeChart /> */}
                    </div>

                    

                    <div className="overview-sec4 mt-4">
                        <div className="d-flex w-100">
                            <div className="w-20 pe-3">
                                <h5 className="mb-1">Substation ({substations?.length})</h5>
                                <div className="scrollable-box">
                                    {substations?.map((item, index) => (
                                        <h6 key={index+1} onClick={() => navigate(`/substationdetail/${item?.id}`)} className="mb-1 pointer">{item?.substationsID}</h6>
                                    ))}
                                </div>
                            </div>
                            <div className="w-20 pe-3">
                                <h5 className="mb-1">Feeder ({feeders?.length})</h5>
                                <div className="scrollable-box scroll-y-auto">
                                    {feeders?.map((item, index) => (
                                        <h6 key={index+1} onClick={() => navigate(`/feederdetail/${item?.id}`)} className="mb-1 pointer">{item?.feederID}</h6>
                                    ))}                                
                                </div>
                            </div>
                            <div className="w-20 pe-3">
                                <h5 className="mb-1">Transformer ({transformers?.length})</h5>
                                <div className="scrollable-box scroll-y-auto">
                                    {transformers?.map((item, index) => (
                                        <h6 key={index+1} onClick={() => navigate(`/transformerdetail/${item?.id}`)} className="mb-1 pointer">{item?.transID}</h6>
                                    ))}
                                                                    

                                </div>
                            </div>
                            {/* <div className="w-20 pe-3">
                                <h5 className="mb-1">Transformer ({logs?.length})</h5>
                                <div className="scrollable-box" ref={logListRef}>
                                {logs?.map((log, index) => (
                                    <h6 className="mb-1" key={index}>TRANS-ID-no-name{log}</h6>
                                ))}
                                    //<h6 className="mb-1">1</h6>

                                </div>
                            </div> */}
                            <div className="w-20 pe-3">
                                <h5 className="mb-1">Meter ({meters?.length})</h5>
                                <div className="scrollable-box scroll-y-auto">
                                    {meters?.map((item, index) => (
                                        <h6 key={index+1} onClick={() => navigate(`/meterdetail/${item?.id}`)} className="mb-1 pointer">{item?.meterID}</h6>
                                    ))}
                                    
                                </div>
                            </div>
                            {/* <div className="w-20 pe-3">
                                <h5 className="mb-1">Meter ({logs2?.length})</h5>
                                <div className="scrollable-box" ref={logListRef2}>
                                    
                                    {logs2?.map((log, index) => (
                                        <h6 className="mb-1" key={index}>METER-ID-no-name{log}</h6>
                                    ))}
                                </div>
                            </div> */}
                            <div className="w-20 pe-21">
                                <h5 className="mb-1">Charger ({chargers?.length})</h5>
                                <div className="scrollable-box">
                                    {chargers?.map((item, index) => (
                                        <h6 key={index+1} onClick={() => navigate(`/chargerdetail/${item?.id}`)} className="mb-1 pointer">{item?.chargerID}</h6>
                                    ))}
                                    

                                
                                </div>
                            </div>
                        </div>
                    </div>




                    {/* <div>
                        <DataTableBase 
                            title={"Error Home Location"}
                            columns={columns}
                            data={dataa}
                        />
                    </div> */}

                    {type !== 1 && (
                        <EnergyUsed setTableData={setTableData} url={'Overview'} />
                    )}

                    
                </div>
            </>
        )
    }
}

export default Overview;