import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { BallTriangle } from 'react-loader-spinner'
import { useNavigate, useParams } from 'react-router-dom'
import '../../../../styles/Addhome.css'

import homeImg from '../../../../assets/charger station mock-mobile.png';
import previewImage from '../../../../assets/icons/image-preview.png';


import GoogleMap from '../../../common/GoogleMap';
import { apiEndPoint } from '../../../../utils';
import { useForm } from 'react-hook-form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import SweetAlert from 'react-bootstrap-sweetalert';
// import { VscError } from "react-icons/vsc";

export default function AddCharger({type, user}) {
    const [imgPreview, setImgPreview] = useState([]);
    const [unit, setUnit] = useState(32);
    const [hide, setHide] = useState(false);
    const [station, setStation] = useState(null);

    const [activeOp, setActiveOp] = useState(2);

    const [check, setCheck] = useState(true);
    
    const [newPass, setNewPass] = useState(false);



    const navigate = useNavigate();

    const [location, setLocation] = useState({
        lat:14.5700,
        lng: 100.9925
    })

    // lat={14.5700} lng={100.9925}

    const {register, handleSubmit, watch, reset, setValue, formState: {errors}} = useForm();
    const [data, setData] = useState(null);

    const [loading, setLoading] = useState(false);
    const [show1, setShow1] = useState(false);
    const [image, setImage] = useState(null);

    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);

    const [err1, setErr1] = useState(null);
    const [msg, setMsg] = useState(null);


    const [feederList, setFeederList] = useState([]);
    const [transformerList, setTransformerList] = useState([]);
    const [meterList, setMeterList] = useState([]);

    useEffect(() => {
        const substationIdWatch = watch("substation_id");
        console.log("Watch substation id : ", substationIdWatch);
        if(substationIdWatch){
            setValue("feeder_id", '')
            setValue("meter_id", '')
            setValue("transformer_id", '')
            setFeederList([]);
            setTransformerList([]);
            setMeterList([]);
            const token = localStorage.getItem("pea_admin");
            axios.get(`${apiEndPoint}/api/admin/substations/${substationIdWatch}`, {
                headers: {Authorization: `Bearer ${token}`}
            }).then((res) => {
                console.log("Substation ids : ", res);
                setFeederList(res?.data?.feeders);
                // setLoading(false);
            }).catch((err) => {
                // setLoading(false);
                console.log("Substation ids : ", err);
            })
        }
    }, [watch("substation_id")])

    useEffect(() => {
        const feederIdWatch = watch("feeder_id");
        console.log("Watch feeder id : ", feederIdWatch);
        if(feederIdWatch){
            setValue("meter_id", '')
            setValue("transformer_id", '')
            setTransformerList([]);
            setMeterList([]);
            const token = localStorage.getItem("pea_admin");
            axios.get(`${apiEndPoint}/api/admin/feeders/${feederIdWatch}`, {
                headers: {Authorization: `Bearer ${token}`}
            }).then((res) => {
                console.log("Transformer ids : ", res);
                setTransformerList(res?.data?.transformer);
                // setLoading(false);
            }).catch((err) => {
                // setLoading(false);
                console.log("Transformers ids : ", err);
            })
        }
    }, [watch("feeder_id")])
    
    
    useEffect(() => {
        const TransformerIdWatch = watch("transformer_id");
        console.log("Watch Transformer id : ", TransformerIdWatch);
        if(TransformerIdWatch){
            setValue("meter_id", '')
            setMeterList([]);
            const token = localStorage.getItem("pea_admin");
            axios.get(`${apiEndPoint}/api/admin/transformers/${TransformerIdWatch}`, {
                headers: {Authorization: `Bearer ${token}`}
            }).then((res) => {
                console.log("Transformer ids : ", res);
                setMeterList(res?.data?.meters);
                // setLoading(false);
            }).catch((err) => {
                // setLoading(false);
                console.log("Transformers ids : ", err);
            })
        }
    }, [watch("transformer_id")])


    const handleClose = () => setShow(false);
    const handleShow = () => {
        // if(connectorType){
        //     setErr1(null);
            setShow(true);
        // } else {
        //     setErr1(true);
        // }
    };

    const [connectors, setConnectors] = useState([]);

    const [connectorName, setConnectorName] = useState(null);
    const [connectorType, setConnectorType] = useState("AC");
    const [connectorPower, setConnectorPower] = useState(null);
    const [connectorId, setConnectorId] = useState(null);

    const {id} = useParams();

    const chargerTypeWatch = watch("chargerType");

    useEffect(() => {
        console.log("Reset UseEffect runing ");
        if(watch("chargerType") === 'AMP'){
            setUnit(32);
            setValue('chargerCapacity', 32)
            // reset({chargerCapacity: 32})
            // console.log("First ");
        }
        if(watch("chargerType") === 'kW'){
            console.log("Second ");
            setValue('chargerCapacity', 7.4)
            // reset({chargerCapacity: 7.4})
            setUnit(7.4);
        }
    }, [watch("chargerType")])

    useEffect(() => {
        const watchMeterId = watch('meter_id');
        setLocation({
            lat: parseFloat(data?.meters[watchMeterId]?.meterlocationLat),
            lng: parseFloat(data?.meters[watchMeterId]?.meterlocationLong)
        });
    }, [watch('meter_id')])

    useEffect(() => {
        const token = localStorage.getItem("pea_admin");
        axios.get(`${apiEndPoint}/api/admin/dashboard`, {
            headers: {Authorization: `Bearer ${token}`}
        }).then((res) => {
            console.log("Res dashboard : ", res);
           setData(res?.data); 
        }).catch((err) => {
            console.log("Err : ", err);
        })
    }, [])

    function handleLocation(event){
        const name = event.target.name
        const value = event.target.value
        if(name === 'lat'){
            setLocation({lng: location.lng, lat: parseFloat(value)})
        }else if(name === 'lng'){
            setLocation({lat: location.lat, lng: parseFloat(value)})
        }
    }

    function handleFile(event){
        if(event.target.files){
            const files = event.target.files
            let array = []
            for(var i=0;i<files.length;i++){
                array.push(URL.createObjectURL(files[i]))
            }
            setImgPreview([...array])
        }
        setImage(event.target.files[0]);
    }

    function removeImg(index){
        const array = []
        for(var i=0;i<imgPreview.length;i++){
            if(i !== index){
                array.push(imgPreview[i])
            }
        }
        setImgPreview([...array])
    }



    function logoutFun2 (){
        localStorage.removeItem('pea_client');
        navigate('/login');
    }

    const onSubmit = (dataa) => {
        console.log("data : ", dataa);
        console.log("data meters : ", data?.meters);
        console.log("meters list : ", meterList);
        console.log("meter id : ", meterList[dataa?.meter_id]?.id);
        console.log("image : ", image);
        if(image){
            if(connectors?.length > 0){
                setLoading(true);
                const formData = new FormData();
                formData.append("substation_id", dataa?.substation_id);
                formData.append("feeder_id", dataa?.feeder_id);
                formData.append("chargerType", dataa?.chargerType);
                formData.append("transformer_id", dataa?.transformer_id);
                formData.append("meter_id", meterList[dataa?.meter_id]?.id);
                formData.append("chargerID", dataa?.chargerID);
                formData.append("chargerName", dataa?.chargerName);
                formData.append("chargerAddress", dataa?.chargerAddress);
                formData.append("chargerPhone", "0282929292");
                formData.append("chargerlocationLat", location?.lat);
                formData.append("chargerlocationLong", location?.lng);
                formData.append("chargerImage", image);
                formData.append("chargerCapacity", dataa?.chargerCapacity);
                formData.append("status", 1);
                formData.append("cDetailName", dataa?.cDetailName);
                formData.append("cDetailChargerID", dataa?.cDetailChargerID);
                formData.append("cDetailChargerNumber", dataa?.cDetailChargerNumber);
                formData.append("cDetailChargerBrand", dataa?.cDetailChargerBrand);
                formData.append("cDetailChargerModel", dataa?.cDetailChargerModel);
                console.log("connectors list array : ", connectors);
                const connTypeArr = [];
                const connNameArr = [];
                const connPowerArr = [];
                const connIdArr = [];
                connectors?.map(item => {
                    connTypeArr?.push(item?.type);
                    connNameArr?.push(item?.name);
                    connPowerArr?.push(item?.power);
                    connIdArr?.push(item?.id);
                })
                
                console.log("connectorID", connIdArr);
                console.log("connectorType", connTypeArr);
                console.log("connectorName", connNameArr);
                console.log("connectorPower", connPowerArr);
                
               
                formData.append("connectorID", connIdArr);
                formData.append("connectorType", connTypeArr);
                formData.append("connectorName", connNameArr);
                formData.append("connectorPower", connPowerArr);
            
                const token = localStorage.getItem('pea_admin');
        
                axios.post(`${apiEndPoint}/api/admin/charger/add`, formData, {headers: {'Authorization' : `Bearer ${token}`}}).then((res) => {
                    console.log("Add Substation  API : ", res);
                    setLoading(false);
                    setShow1(true);
                    setTimeout(() => {
                        setShow1(false);
                        navigate('/chargerlist');
                    }, 2000)

                    // setStation(res?.data?.data[0]);            
                    // setLocation({
                    //     lat: res?.data?.data[0]?.lat,
                    //     lng: res?.data?.data[0]?.lng
                    // })
                    // setImgPreview([res?.data?.data[0]?.chargerPhoto])
            
            
                    // console.log("lat : ", res?.data?.data[0]?.lat)
                    // console.log("lng : ", res?.data?.data[0]?.lng)
        
                }).catch((err) =>{
                    if(err?.response?.status === 400){
                        setMsg(err?.response?.data?.error[0]);
                        setShow2(true);
                        setTimeout(() => {setShow2(false); setMsg(null)}, 4500)
                    } else {
                        setMsg("Server Error");
                        setShow2(true);
                        setTimeout(() => {setShow2(false); setMsg(null)}, 4000)                        
                    }
                    setLoading(false);
                    console.log("Error get user : ", err);
                })
            } else {
                setMsg('Charger must contains atleast one connector');
                setShow2(true);
                setTimeout(() => {
                    setShow2(false);
                }, 4500)
            }
        }
    }

    const addConnectorFun = () => {
        if(connectorName && connectorPower && connectorId){
            const connArray = [...connectors, {id: connectorId, type: connectorType, name: connectorName, power: connectorPower}];
            setConnectors(connArray);
            
            handleClose();

            setConnectorName(null);
            setConnectorPower(null);
            setConnectorId(null);
            // console.log("connector array : ", connArray);
        }
    }

    const removeConnectorFun = (i) =>{
        let connArray = connectors;
        // console.log("Conn Array : ", connArray);
        // connArray?.slice(i);
        // console.log("after removing element array : ", connArray);
        setConnectors(connArray => connArray.filter((item, index) => index !== i));
 
    }

    const connectorTypeWatch = watch("chargerType");
    
    useEffect(() => {
        console.log("watch charger type console : ", connectorTypeWatch);
        if(connectorTypeWatch !== null){
            if(connectorTypeWatch === "AMP"){
                console.log("if part")
                setConnectorType("AC");
            } else {
                console.log("else")
                setConnectorType("DC");
            }
        }
        // console.log("watch(chargerType) : ", watch("chargerType"))
        // if(connectorTypeWatch === 'AMP'){
        //     // setUnit(32);
        //     console.log("First ");
        // }
        // if(connectorTypeWatch === 'kW'){
        //     console.log("Second ");
        //     // setUnit(7.4);
        // }
    }, [watch("chargerType")])




if(loading){
    return (
        <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '33vh'}}>
            <BallTriangle
                height={90}
                width={90}
                radius={5}
                color="#814097"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle="" 
                visible={true}
            />
        </div>
    )
} else {

  return (
    <div className='station-overview' style={{marginTop: `${type === 1 ? '-16px': 'afa'}`}}>
        {show1 && (
            <SweetAlert success title="Congratulations" onConfirm={() => {setShow1(false); navigate('/meterlist')}} onCancel={() => {setShow1(false);}}>
                Charger added sucessfully.
            </SweetAlert>
        )}
        {show2 &&(
            <SweetAlert title="Error" onConfirm={() => setShow2(false)} onCancel={() => setShow2(false)} >
                <div className='text-center w-100'>
                    <p className='text-danger text-center'>{msg}</p>

                </div>
            </SweetAlert>
        )}


        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Add Connector</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className='mb-3'>
                        <label>Connector Id</label>
                        <select className='form-control w-100' onChange={(e) => setConnectorId(e.target.value)}>
                            <option>Select Connector Id</option>
                            {connectors?.length > 0 && (
                                connectors[0]?.id === '1' ? (

                                    <option value="2">2</option>
                                ) : (
                                    <option value="1">1</option>
                                )
                            )}

                            {connectors?.length === 0 && (
                                <>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                </>
                            )}
                            
                        </select>
                    </div>
                    <div className='mb-3'>
                        <label>Connector Name</label>
                        <select type='text' onChange={(e) => setConnectorName(e.target.value)} defaultValue={connectorName} className='form-control w-100'>
                            <option>Select Connector Name</option>
                            {connectorType === "DC" ? (
                                <>
                                    <option value="CCS1">CCS1</option>
                                    <option value="CCS2">CCS2</option>
                                    <option value="CHAdeMO">CHAdeMO</option>
                                </>
                            ) : (
                                <>
                                    <option value="Type 1">Type 1</option>
                                    <option value="Type 2">Type 2</option>
                                </>
                            )}
                        </select>
                    </div>
                    <div>
                        <label>Rated {chargerTypeWatch === "kW" ? "kW": "AMP"}</label>
                        <input type='number' onChange={(e) => setConnectorPower(e.target.value)} defaultValue={connectorPower} className='form-control w-100' placeholder='Enter power in' />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" type='button' onClick={handleClose}>
                Close
            </Button>
            <Button variant="primary" type='button' onClick={addConnectorFun}>
                Save Changes
            </Button>
            </Modal.Footer>
        </Modal>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div>
                <span className="pages-routes1">Charger </span>
                <span className="pages-routes2">&#62;</span>            
                <span className="pages-routes1 clr-814097" onClick={() => navigate('/transformerdetail/1')}>Add new </span>
                
            </div>
            <div className='overview-section-heading mt-4 pt-1'>
                <h3>Charger Location Profile</h3>
                <p>Add charger details and address</p>
            </div> 
            <div className='station-img-mobile-div'>
                <img src={homeImg} alt="station-img" width={"100%"} />
            </div>
            <div className='overview-section'>  
                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5>Unit Type</h5>
                        {/* <p>This will be displayed to customer.</p> */}
                    </div>
                    <div className="width-35-percent">
                        <select className='form-control'  {...register("chargerType", {required: true})}>
                            <option value="AMP">AMP</option>
                            <option value="kW">kW</option>
                        </select>
                    </div>
                </div>

                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5>Charger ID</h5>
                        {/* <p>This will be displayed to customer.</p> */}
                    </div>
                    <div className="width-35-percent">
                        <input className='form-control'  {...register("chargerID", {required: true})} placeholder='CHARGER-ID-1'/>
                    </div>
                </div>
                
                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5>Substation ID</h5>
                    </div>
                    <div className="width-35-percent">
                        {/* <input className='form-control' defaultValue={station?.locationTH} placeholder='SUB-ID-1 list'/>                     */}
                        <select className='form-control' {...register("substation_id", {required: true})} placeholder='SUB-ID-1 list'>
                            <option value="">Select Substation</option>
                            {data?.substations?.map((item, index) => (
                                <option key={index} value={item?.id}>{item?.substationsID}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5>Feeder ID</h5>
                    </div>
                    <div className="width-35-percent">
                        {/* <input className='form-control' defaultValue={station?.locationTH} placeholder='SUB-ID-1 list'/>                     */}
                        <select className='form-control' {...register("feeder_id", {required: true})} placeholder='SUB-ID-1 list'>
                            <option value="">Select Feeder</option>
                            {feederList?.map((item, index) => (
                                <option value={item?.id} key={index}>{item?.feederID}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5>Transformer ID</h5>
                    </div>
                    <div className="width-35-percent">
                        {/* <input className='form-control' defaultValue={station?.locationTH} placeholder='TRANS-ID-1 list'/>                     */}
                        <select className='form-control' {...register("transformer_id", {required: true})} placeholder='TRANS-ID-1 list'>
                            <option value="">Select Transformer</option>
                            {transformerList?.map((item, index) => (
                                <option value={item?.id} key={index}>{item?.transID}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5>Meter ID</h5>
                    </div>
                    <div className="width-35-percent">
                        {/* <input className='form-control' defaultValue={station?.locationTH} placeholder='METER-ID-1 list'/>                     */}
                        <select className='form-control' {...register("meter_id", {required: true})} placeholder='TRANS-ID-1 list'>
                            <option value="">Select Meter</option>
                            {meterList?.map((item, index) => (
                                <option value={index} key={index}>{item?.meterID}</option>
                            ))}
                        </select>
                    </div>
                </div>
                
                
                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5>Charger Name</h5>
                        {/* <p>This will be displayed to customer.</p> */}
                    </div>
                    <div className="width-35-percent">
                        <input className='form-control' {...register("chargerName", {required: true})} placeholder='HOME KORAT 01'/>
                    </div>
                </div>
                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5>Address</h5>
                        {/* <p>This will be displayed to customer.</p> */}
                    </div>
                    <div className="width-35-percent">
                        <input className='form-control'  {...register("chargerAddress", {required: true})}  placeholder='111/111 mittraparb road naimuang muang korat 30000'/>
                    </div>
                </div>
                {/* <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5>Location</h5>
                        <p>Location of this substation</p>
                    </div>
                    <div className="width-35-percent">
                        <input className='form-control'  defaultValue={station?.descriptionTH} placeholder='41/179 ต.ในเมือง อ.เมือง จ.นคคราชสีมา 30000 '/>
                    </div>
                </div>
                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5>Address (EN)</h5>
                        <p>Address that available for contact</p>
                    </div>
                    <div className="width-35-percent">
                        <input className='form-control'  defaultValue={station?.descriptionEN} placeholder='41/179 Thambol Nai Mueang Amphur Mueang, Nakhon Ratchasima 30000'/>
                    </div>
                </div>

                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5>User</h5>
                        <p>required *</p>
                    </div>
                    <div className="width-35-percent">
                        <input className='form-control' defaultValue={station?.descriptionEN} placeholder='Santanasandee'/>
                    </div>
                </div>
                
                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5>Email</h5>
                        <p>required *</p>
                    </div>
                    <div className="width-35-percent">
                        <input className='form-control' defaultValue={station?.descriptionEN} placeholder='Santana@gmail.com'/>
                    </div>
                </div>
                
                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5>Name - Surname</h5>
                        <p>required *</p>
                    </div>
                    <div className="width-35-percent">
                        <input className='form-control' defaultValue={station?.descriptionEN} placeholder='Santana Sandee'/>
                    </div>
                </div>

                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5>Phone</h5>
                        <p>required *</p>
                    </div>
                    <div className="width-35-percent">
                        <input className='form-control' defaultValue={station?.descriptionEN} placeholder='This will be displayed to customer.'/>
                    </div>
                </div> */}
                {/* {type === 1 ? (
                    <></>
                ) : type === 2 ? (
                    <>
                        <div className='station-field'>
                            <div className='station-field-div1'>
                                <h5>Password</h5>
                                <p>required *</p>
                            </div>
                            <div className="width-35-percent">
                                <button className='generate-password-btn me-3' onClick={() => setNewPass(!newPass)}>{newPass ? "Don't Want to change" : 'Set New Password'}</button>
                                {newPass && (
                                    <>
                                        <button className='generate-password-btn'>Generate Password</button>
                                        <div className='d-flex'>
                                            <div className='w-888'>
                                                <input className='form-control' type={hide ? 'text' : 'password'} defaultValue={station?.descriptionEN} placeholder='Enter Password'/>                            
                                            </div>
                                            <div>
                                                <button className='hide-btn' onClick={() => setHide(!hide)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22.531" height="17.925" viewBox="673.484 11.333 27.531 22.025"><path d="M687.25 28.54c-3.263 0-5.904-2.526-6.147-5.726l-4.513-3.488c-.593.745-1.139 1.531-1.58 2.392-.199.395-.199.86 0 1.256 2.334 4.551 6.952 7.63 12.24 7.63 1.158 0 2.274-.171 3.35-.45l-2.231-1.726a6.2 6.2 0 0 1-1.119.112Zm13.5 2.5-4.756-3.676a14.25 14.25 0 0 0 3.496-4.39c.2-.396.2-.862 0-1.257-2.333-4.551-6.952-7.63-12.24-7.63-2.215.002-4.393.56-6.337 1.62l-5.473-4.23a.688.688 0 0 0-.966.122l-.845 1.087c-.233.3-.179.732.121.966l25.31 19.561c.3.234.733.18.966-.12l.845-1.088a.688.688 0 0 0-.121-.966Zm-7.903-6.11-1.69-1.306a4.076 4.076 0 0 0-4.996-5.245c.26.351.4.776.4 1.213-.006.146-.028.29-.065.43l-3.167-2.447a6.122 6.122 0 0 1 3.921-1.424 6.191 6.191 0 0 1 6.195 6.194c0 .93-.228 1.798-.598 2.586Z" fill="#814097" fill-rule="evenodd" data-name="Icon awesome-eye-slash"/></svg>
                                                    Hide
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className='station-field'>
                            <div className='station-field-div1'>
                                <h5>Password</h5>
                                <p>required *</p>
                            </div>
                            <div className="width-35-percent">
                                <button className='generate-password-btn'>Generate Password</button>
                                <div className='d-flex'>
                                    <div className='w-888'>
                                        <input className='form-control' type={hide ? 'text' : 'password'} defaultValue={station?.descriptionEN} placeholder='Enter Password'/>                            
                                    </div>
                                    <div>
                                        <button className='hide-btn' onClick={() => setHide(!hide)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22.531" height="17.925" viewBox="673.484 11.333 27.531 22.025"><path d="M687.25 28.54c-3.263 0-5.904-2.526-6.147-5.726l-4.513-3.488c-.593.745-1.139 1.531-1.58 2.392-.199.395-.199.86 0 1.256 2.334 4.551 6.952 7.63 12.24 7.63 1.158 0 2.274-.171 3.35-.45l-2.231-1.726a6.2 6.2 0 0 1-1.119.112Zm13.5 2.5-4.756-3.676a14.25 14.25 0 0 0 3.496-4.39c.2-.396.2-.862 0-1.257-2.333-4.551-6.952-7.63-12.24-7.63-2.215.002-4.393.56-6.337 1.62l-5.473-4.23a.688.688 0 0 0-.966.122l-.845 1.087c-.233.3-.179.732.121.966l25.31 19.561c.3.234.733.18.966-.12l.845-1.088a.688.688 0 0 0-.121-.966Zm-7.903-6.11-1.69-1.306a4.076 4.076 0 0 0-4.996-5.245c.26.351.4.776.4 1.213-.006.146-.028.29-.065.43l-3.167-2.447a6.122 6.122 0 0 1 3.921-1.424 6.191 6.191 0 0 1 6.195 6.194c0 .93-.228 1.798-.598 2.586Z" fill="#814097" fill-rule="evenodd" data-name="Icon awesome-eye-slash"/></svg>
                                            Hide
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='station-field'>
                            <div className='station-field-div1'>
                                <h5>Send User Notification</h5>
                                <p>Send the new user an email about their account.</p>
                            </div>
                            <div className="width-35-percent">
                                <input type="checkbox" class="form-check-input notification-check" id="exampleCheck1" />
                            </div>
                        </div>
                    </>
                )} */}
                
                



                



                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5>Location</h5>
                        <p>Location of this charger</p>
                    </div>
                    <div>
                        <div className='station-field-location'>
                            <div>
                                <h6>Latitude</h6>
                                <input className='form-control' readOnly defaultValue={location?.lat} placeholder='14.044750' name='lat' onChange={handleLocation}/>
                            </div> 
                            <div>
                                <h6>Longitude</h6>
                                <input className='form-control' readOnly defaultValue={location?.lng} placeholder='100.591728' name='lng' onChange={handleLocation}/>
                            </div>
                        </div>
                        <div className='station-map station-map-desk'>
                            {/* <GoogleMap lat={location.lat} lng={location.lng} width={'40%'} height={'40%'}/> */}
                            {/* {station ? ( */}
                                <GoogleMap type={5} lat={location?.lat} lng={location?.lng} width={'40%'} height={'100%'}/>
                            {/* ) : (
                                <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '3vh'}}>
                                    <BallTriangle
                                        height={70}
                                        width={70}
                                        radius={5}
                                        color="#814097"
                                        ariaLabel="ball-triangle-loading"
                                        wrapperClass={{}}
                                        wrapperStyle=""
                                        visible={true}
                                    />
                                </div>
                            )} */}
                        </div>
                    </div>
                </div>


                <div className='w-100 station-map-mob' style={{overflow:'hidden'}}>
                    <div className='station-map '>
                        {/* <GoogleMap lat={location.lat} lng={location.lng} width={'40%'} height={'40%'}/> */}
                        {/* {station ? ( */}
                        <GoogleMap lat={15.8700} lng={100.9925} width={'40%'} height={'100%'}/>
                    </div>
                </div>



                <div className='station-field location' style={{height: '10vh', marginTop: '13vh'}}>
                    
                    <div className='station-field-div1'>
                        <h5>Charger Picture</h5>
                        {/* <p>This will be displayed to customer.</p> */}
                    </div>
                    <div className='img-preview' style={{zIndex:'10'}} >
                        <div className='d-flex'>
                            <div>
                                {imgPreview?.length > 0 ? imgPreview.map((url, index)=>
                                    <div key={index}>
                                        <span style={{cursor:'pointer'}} onClick={()=>removeImg(index)}>&times;</span>
                                        <img src={url} alt={url}/>
                                    </div>
                                ) : (
                                    <img src={previewImage} alt='image-prerview' />
                                )}
                            </div>
                            <div className='pt-5'>
                                <input type='file' required style={{height: '38px'}}  className='form-control' accept="image/*"  multiple onChange={handleFile}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className='overview-section-heading' style={{marginTop: '160px'}}>
                <h4 className='haeding-style-charger-info'>Charger Information</h4>
                <p>The information of the charger</p>
            </div>        
            <div className='overview-section'>            
                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5>Capacity</h5>
                    </div>
                    <div className='d-flex'>
                        <div style={{width: 'calc(100% - 25px)'}} className='pe-1'>
                            <input 
                                type='text' 
                                className='form-control' 
                                defaultValue={unit} 
                                // onChange={(e) => setUnit(e.target.value)}
                                // defaultValue={chargerTypeWatch == 'KW' ? 7.4 : 32} 
                                {...register("chargerCapacity", {required: true, min: {value: 1}})} 
                                placeholder={unit}
                            />                    
                            {errors?.chargerCapacity && <p className='text-danger mb-0'>capacity should be greater than 0</p>}
                        </div>
                        <div className='pt-1 ps-1'>
                            <span>{chargerTypeWatch}</span>
                        </div>
                    </div>
                </div>            
                
            </div>
            
            <div className='overview-section-heading' style={{marginTop: '16px'}}>
                <h4 className='haeding-style-charger-info'>Charger Detail</h4>
                <p>The information of the charger and connector types.</p>
            </div>
            <div className='overview-section'>
                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5>Charger Detail</h5>
                    </div>
                    <div>
                        {/* <p>Charger name</p>
                        <input className='form-control' {...register("cDetailName", {required: true})} placeholder='PEA VOLTA #01'/>
                        <div className='station-field-location'>
                            <div>
                                <p>Charger ID</p>
                                <input className='form-control' {...register("cDetailChargerID", {required: true})} placeholder='PEA-1129'/>
                            </div>
                            <div>
                                <p>Charger Number</p>
                                <input className='form-control' {...register("cDetailChargerNumber", {required: true})} placeholder='P101'/>
                            </div>
                        </div> */}
                        <div className='station-field-location'>
                            <div>
                                <p>Brand</p>
                                <input className='form-control' {...register("cDetailChargerBrand", {required: true})} placeholder='AlphaEV'/>
                            </div>
                            <div>
                                <p>Model</p>
                                <input className='form-control' {...register("cDetailChargerModel", {required: true})}  placeholder='A129'/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5>Connector Type</h5>
                        {/* <p>required *</p> */}
                    </div>
                    <div className='connector-type-divv'>
                        {type === 1 ? (
                            <></>
                        ) : (
                            user != 1 && (
                                <div className='d-flex station-field'>
                                    <div className='connector-type-select-div'>
                                        {/* <input type='text' readOnly className='form-control' value={connectorType} defaultValue={connectorType} /> */}
                                        <select className='form-control pe-4'
                                            defaultValue={connectorType} 
                                            onChange={(e) => setConnectorType(e.target.value)}
                                            // {...register("connectorType")}    
                                        >
                                            {/* <option>Choose Connector Type </option> */}
                                            {watch("chargerType") === 'kW' ? (
                                                <>
                                                    <option value="AC">AC</option>
                                                    <option value="DC">DC</option>
                                                </>
                                            ) : (                                                
                                                <option value="AC">AC</option>
                                            )}
                                        </select>
                                        {/* {err1 && <p className='text-danger mt-2 mb-0'>Please select connector type first</p>} */}
                                    </div>
                                    <div>
                                        {connectors?.length < 2 && (
                                            <button className='add-connector-btn' type='button' onClick={handleShow}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24.846" height="24.846" viewBox="1364.137 2203.154 24.846 24.846"><path d="M1376.56 2203.154c-6.863 0-12.423 5.56-12.423 12.423s5.56 12.423 12.423 12.423c6.862 0 12.423-5.56 12.423-12.423 0-6.862-5.56-12.423-12.423-12.423Zm7.213 13.826c0 .33-.27.6-.601.6h-4.609v4.61c0 .33-.27.6-.6.6h-2.806a.603.603 0 0 1-.601-.6v-4.61h-4.609a.603.603 0 0 1-.6-.6v-2.805c0-.331.27-.601.6-.601h4.609v-4.609c0-.33.27-.601.601-.601h2.805c.33 0 .601.27.601.601v4.609h4.609c.33 0 .601.27.601.6v2.806Z" fill="#fff" fill-rule="evenodd" data-name="Icon awesome-plus-circle"/></svg>
                                                Add Connector
                                            </button>
                                        )}
                                    </div>
                                </div>
                            )
                        )}

                        {connectors?.map((item, index) => (
                            <div className='connector-display-div d-flex mb-3' key={index}>
                                <div className='div1'>
                                {item?.name === "Type 2" ? (
                                        <svg height="62px" viewBox="0 0 24 24" width="54px" xmlns="http://www.w3.org/2000/svg"><path fill='#0E8D06' d="m7.5 11c0 .28-.22.5-.5.5s-.5-.22-.5-.5.22-.5.5-.5.5.22.5.5m2 3.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5m0-6.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5m5 0c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5m-2.5 2.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5m8 .5c0 4.42-3.58 8-8 8s-8-3.58-8-8c0-2.39 1.06-4.53 2.72-6h10.56c1.66 1.47 2.72 3.61 2.72 6m-7-3.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5-1.5.67-1.5 1.5m-5 0c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5-1.5.67-1.5 1.5m-1 5.5c1.11 0 2-.89 2-2 0-1.1-.89-2-2-2s-2 .9-2 2c0 1.11.9 2 2 2m4.5 2c0-1.1-.89-2-2-2s-2 .9-2 2c0 1.11.9 2 2 2s2-.89 2-2m.5-2c1.11 0 2-.89 2-2 0-1.1-.89-2-2-2s-2 .9-2 2c0 1.11.9 2 2 2m4.5 2c0-1.1-.89-2-2-2s-2 .9-2 2c0 1.11.9 2 2 2s2-.89 2-2m2.5-4c0-1.1-.89-2-2-2s-2 .9-2 2c0 1.11.9 2 2 2s2-.89 2-2m-2-.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5m-2.5 4c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5z"/></svg>
                                    ) : item?.name === "Type 1" ? (                                        
                                        <svg height="62px" viewBox="0 0 24 24" width="54px" xmlns="http://www.w3.org/2000/svg"><path fill='#0E8D06' d="m12.5 15c0 .28-.22.5-.5.5s-.5-.22-.5-.5.22-.5.5-.5.5.22.5.5m2.5-4.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5m-6 0c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5m4 8.42v1.08h-2v-1.08c-3.39-.49-6-3.39-6-6.92s2.61-6.43 6-6.92v-1.08h2v1.08c3.39.49 6 3.42 6 6.92s-2.61 6.43-6 6.92m0-8.92c0 1.11.9 2 2 2s2-.89 2-2-.89-2-2-2-2 .9-2 2m-6 0c0 1.11.9 2 2 2s2-.89 2-2-.89-2-2-2-2 .9-2 2m2.5 4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5.67 1.5 1.5 1.5 1.5-.67 1.5-1.5m4.5 1c0-1.1-.89-2-2-2s-2 .9-2 2 .9 2 2 2 2-.89 2-2m2 .5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5-1.5.67-1.5 1.5.67 1.5 1.5 1.5z"/></svg>
                                    ) : item?.name === "CHAdeMO" ? (
                                        <svg height="62px" viewBox="0 0 24 24" width="54px" xmlns="http://www.w3.org/2000/svg"><path fill='#0E8D06' d="m8 10.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5-1.5-.67-1.5-1.5.67-1.5 1.5-1.5m0-1.5c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3m8 1.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5-1.5-.67-1.5-1.5.67-1.5 1.5-1.5m0-1.5c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3m-1.88-.59-1.41-1.41 1.41-1.41-.71-.71-1.41 1.41-1.41-1.41-.71.71 1.41 1.41-1.41 1.41.71.71 1.41-1.41 1.41 1.41zm0 10-1.41-1.41 1.41-1.41-.71-.71-1.41 1.41-1.41-1.41-.71.71 1.41 1.41-1.41 1.41.71.71 1.41-1.41 1.41 1.41zm5.62-12.74.76-.74-1.43-1.43-.74.76c-1.73-1.41-3.93-2.26-6.33-2.26s-4.6.85-6.33 2.26l-.74-.76-1.43 1.43.76.74c-1.41 1.73-2.26 3.93-2.26 6.33 0 5.5 4.5 10 10 10s10-4.5 10-10c0-2.4-.85-4.6-2.26-6.33m-7.74 14.33c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>
                                    ) : item?.name === "CCS2" ? (
                                        <svg height="62px" viewBox="0 0 24 24" width="54px" xmlns="http://www.w3.org/2000/svg"><path fill='#0E8D06' d="m14 4.5c0-.28.22-.5.5-.5s.5.22.5.5-.22.5-.5.5-.5-.22-.5-.5m6 14.5c0 2.21-1.79 4-4 4h-8c-2.21 0-4-1.79-4-4s1.79-4 4-4h.13c-2.46-1.36-4.13-4-4.13-7 0-2.39 1.06-4.53 2.72-6h10.56c1.66 1.47 2.72 3.61 2.72 6 0 3-1.67 5.64-4.13 7h.13c2.21 0 4 1.79 4 4m-3.5-7c0-1.1-.89-2-2-2s-2 .9-2 2c0 1.11.9 2 2 2s2-.89 2-2m.5-6c-1.1 0-2 .9-2 2 0 1.11.9 2 2 2s2-.89 2-2c0-1.1-.89-2-2-2m-4-1.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5-1.5.67-1.5 1.5m-1 1.5c-1.1 0-2 .9-2 2 0 1.11.9 2 2 2s2-.89 2-2c0-1.1-.89-2-2-2m-4-1.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5-1.5.67-1.5 1.5m-1 5.5c1.11 0 2-.89 2-2 0-1.1-.89-2-2-2s-2 .9-2 2c0 1.11.9 2 2 2m2.5 4c1.11 0 2-.89 2-2 0-1.1-.89-2-2-2s-2 .9-2 2c0 1.11.9 2 2 2m1.5 5c0-1.66-1.34-3-3-3s-3 1.34-3 3 1.34 3 3 3 3-1.34 3-3m8 0c0-1.66-1.34-3-3-3s-3 1.34-3 3 1.34 3 3 3 3-1.34 3-3m-2-10.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5m-2.5 3c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5m1.5 6.5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1m-4-9.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5m-4 9.5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1m-1-10.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5m2.5-2.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5m.5 7c0-.28-.22-.5-.5-.5s-.5.22-.5.5.22.5.5.5.5-.22.5-.5z"/></svg>
                                    ) : item?.name === "CCS1" ? (
                                        <svg height="62px" viewBox="0 0 24 24" width="59px" xmlns="http://www.w3.org/2000/svg"><path fill='#0E8D06' d="m16 15h-.41c2.04-1.22 3.41-3.45 3.41-6 0-3.53-2.61-6.43-6-6.92v-1.131h-2v1.131c-3.39.49-6 3.39-6 6.92 0 2.55 1.37 4.78 3.41 6h-.41c-2.21 0-4 1.79-4 4s1.79 4 4 4h8c2.21 0 4-1.79 4-4s-1.79-4-4-4m1.5-4c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5.67-1.5 1.5-1.5 1.5.67 1.5 1.5m-2.5-6c1.11 0 2 .9 2 2s-.89 2-2 2-2-.89-2-2 .9-2 2-2m-6 0c1.11 0 2 .9 2 2s-.89 2-2 2-2-.89-2-2 .9-2 2-2m-1 7.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5m1 9.32v-.82h-2v.82c-1.16-.42-2-1.52-2-2.82 0-1.66 1.34-3 3-3s3 1.34 3 3c0 1.3-.84 2.4-2 2.82m1-9.82c0-1.1.9-2 2-2s2 .9 2 2-.89 2-2 2-2-.89-2-2m7 9.82v-.82h-2v.82c-1.16-.42-2-1.52-2-2.82 0-1.66 1.34-3 3-3s3 1.34 3 3c0 1.3-.84 2.4-2 2.82m-2.5-14.82c0-.28.22-.5.5-.5s.5.22.5.5-.22.5-.5.5-.5-.22-.5-.5m-6 0c0-.28.22-.5.5-.5s.5.22.5.5-.22.5-.5.5-.5-.22-.5-.5m.5 12c0 .55-.45 1-1 1s-1-.45-1-1 .45-1 1-1 1 .45 1 1m8 0c0 .55-.45 1-1 1s-1-.45-1-1 .45-1 1-1 1 .45 1 1m-4.5-7c0 .28-.22.5-.5.5s-.5-.22-.5-.5.22-.5.5-.5.5.22.5.5z"/></svg>
                                    ) : (                       
                                        <>
                                            {/* <img src={item?.name === "Type 1" || item?.name === "Type 2"? `https://volta-webapp.pea.co.th/images/mapIconMobile/acT2Available.png` : item?.name === "CCS1" || item?.name === "CCS2" ? `https://volta-webapp.pea.co.th/images/mapIconMobile/CCS2Available.png` : `https://volta-webapp.pea.co.th/images/mapIconMobile/CHAdeMOAvailable.png`} className="me-2" style={{height: '42px', width: '40px', position:'relative', top: '-3px'}} /> */}
                                        </>                 
                                    )}
                                {/* <img src={item?.name === "Type 1" || item?.name === "Type 2"? `https://volta-webapp.pea.co.th/images/mapIconMobile/acT2Available.png` : item?.name === "CCS1" || item?.name === "CCS2" ? `https://volta-webapp.pea.co.th/images/mapIconMobile/CCS2Available.png` : `https://volta-webapp.pea.co.th/images/mapIconMobile/CHAdeMOAvailable.png`} className="me-2" style={{height: '42px', width: '40px', position:'relative', top: '-3px'}} /> */}
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="63.5" height="50.758" viewBox="881.5 2833.154 63.5 50.758"><path d="M941.46 2837.426a7.862 7.862 0 0 0-7.015-4.272h-42.389a7.89 7.89 0 0 0-7.037 4.309c-2.314 4.5-3.498 9.362-3.519 14.448-.035 8.784 3.47 17 9.875 23.132 5.934 5.685 13.855 8.869 21.945 8.869.523 0 1.045-.014 1.57-.04 16.884-.862 30.11-14.843 30.11-31.827 0-5.151-1.19-10.07-3.54-14.62Zm-19.5.77c2.682 0 4.866 2.19 4.866 4.884 0 2.693-2.184 4.884-4.867 4.884s-4.866-2.19-4.866-4.884c0-2.693 2.183-4.885 4.866-4.885Zm-17.42 0c2.684 0 4.867 2.19 4.867 4.884 0 2.693-2.183 4.884-4.866 4.884-2.684 0-4.867-2.19-4.867-4.884 0-2.693 2.183-4.885 4.867-4.885Zm-16.886 16.814c0-3.863 3.131-7.006 6.98-7.006 3.848 0 6.98 3.143 6.98 7.006 0 3.863-3.132 7.006-6.98 7.006-3.849 0-6.98-3.143-6.98-7.006Zm17.117 20.81c-3.85 0-6.981-3.144-6.981-7.007 0-3.863 3.131-7.006 6.98-7.006 3.848 0 6.98 3.143 6.98 7.006 0 3.863-3.132 7.007-6.98 7.007Zm8.479-13.804c-3.85 0-6.98-3.143-6.98-7.006 0-3.863 3.13-7.006 6.98-7.006 3.849 0 6.98 3.143 6.98 7.006 0 3.863-3.131 7.006-6.98 7.006Zm8.48 13.804c-3.849 0-6.979-3.144-6.979-7.007 0-3.863 3.13-7.006 6.98-7.006 3.847 0 6.979 3.143 6.979 7.006 0 3.863-3.132 7.007-6.98 7.007Zm10.137-13.804c-3.849 0-6.98-3.143-6.98-7.006 0-3.863 3.131-7.006 6.98-7.006 3.848 0 6.98 3.143 6.98 7.006 0 3.863-3.132 7.006-6.98 7.006Z" fill="#00a651" fill-rule="evenodd" data-name="Path 25"/></svg> */}
                                </div>
                                <div>
                                    <p className='connector-name mb-0'>Connector ID : {item?.id}</p>
                                    <p className='connector-name mb-0 mt-2'>{item?.name}</p>
                                    <p className='connector-type mb-0'>Type : {item?.type}</p>
                                    <div className='d-flex'>
                                        <div className='pe-3'>
                                            <p className='connector-type mb-0'>Rated Power : </p>
                                        </div>
                                        <div className='w-25'>
                                            <input readOnly defaultValue={item?.power} className='form-control'  placeholder='40' />
                                        </div>
                                        <div>
                                            {/* <p className='connector-type mb-0 ms-2 mb-0'> {chargerTypeWatch}</p> */}
                                            {/* <p className='connector-type mb-0 ms-2 mb-0'> {item?.type === "AC" ? "AMP" : "kW"}</p> */}
                                            <p className='connector-type mb-0 ms-2 mb-0'> {chargerTypeWatch}</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {type === 1 ? (
                                        <>
                                        <div className='switch-btn-div pt-5 mt-2'>
                                            <input type="checkbox" defaultChecked={true} onChange={(e) => setCheck(e.target.checked)} name="check" value={true} class="toggle-station"/>
                                            {check ? (
                                                <span className='text-successsdf ms-2'>On</span>
                                            ) : (
                                                <span className='text-secondary ms-2'>Off</span>
                                            )}

                                        </div>
                                        </>
                                    ) : (
                                        user != 1 && (
                                            <svg onClick={() => { removeConnectorFun(index); }} className='cross-connector-icon pointer' xmlns="http://www.w3.org/2000/svg" width="29.25" height="29.25" viewBox="1411.613 2844.154 29.25 29.25"><path d="M1426.238 2844.154a14.574 14.574 0 0 0-14.625 14.625 14.575 14.575 0 0 0 14.625 14.625 14.575 14.575 0 0 0 14.625-14.625 14.575 14.575 0 0 0-14.625-14.625Zm7.313 19.89-2.048 2.047-5.265-5.265-5.265 5.265-2.047-2.047 5.265-5.265-5.265-5.265 2.047-2.047 5.265 5.264 5.265-5.264 2.048 2.047-5.265 5.265 5.265 5.265Z" fill="#b2b2b2" fill-rule="evenodd" data-name="Icon ionic-md-close-circle"/></svg>
                                        )
                                    )}
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>


            <div className='add-home-cancel-submitsection mt-4'>
                <div className='station-field w-100 d-flex'>
                    <div className='station-field-div1' style={{width: '31%'}}>
                        <h5></h5>
                        <p></p>
                    </div>
                    <div style={{width:'45%'}}>
                        {type === 1 ? (
                            <>
                                <button className='add-station-submit-btn' onClick={() => navigate(`/homelocationlist/edit/1`)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19.975" height="19" viewBox="1728.013 136.318 19.975 19"><g data-name="Icon feather-edit-3"><path d="M1738 155.318h9.988" stroke-linejoin="round" stroke-linecap="round" stroke-width="3" stroke="#fff" fill="transparent" data-name="Path 26"/><path d="M1742.994 137.008a2.354 2.354 0 0 1 3.33 3.329l-13.872 13.872-4.44 1.11 1.11-4.44 13.872-13.871Z" stroke-linejoin="round" stroke-linecap="round" stroke-width="3" stroke="#fff" fill="transparent" data-name="Path 27"/></g></svg>
                                    Edit Home
                                </button>
                                <button className='add-station-reset-btn del-station-btn'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.533" height="25.271" viewBox="1088.734 2551.281 20.533 25.271"><path d="M1090.314 2559.179v15.794c0 .869.71 1.58 1.579 1.58h14.215c.868 0 1.58-.711 1.58-1.58v-15.794h-17.374Zm4.738 14.214h-1.58v-11.055h1.58v11.055Zm3.159 0h-1.58v-11.055h1.58v11.055Zm3.158 0h-1.579v-11.055h1.58v11.055Zm3.16 0h-1.58v-11.055h1.58v11.055Zm3.553-18.953h-5.133v-1.974c0-.651-.533-1.184-1.185-1.184h-5.528c-.651 0-1.184.533-1.184 1.184v1.974h-5.133c-.652 0-1.185.533-1.185 1.185v1.974h20.533v-1.974c0-.652-.533-1.185-1.185-1.185Zm-6.713 0h-4.738v-1.56h4.738v1.56Z" fill="#fff" fill-rule="evenodd" data-name="Icon metro-bin"/></svg>
                                    Delete Station
                                </button>
                            </>
                        ) : type === 2 ? (
                            <>
                                <button className='add-station-submit-btn' >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19.975" height="19" viewBox="1728.013 136.318 19.975 19"><g data-name="Icon feather-edit-3"><path d="M1738 155.318h9.988" stroke-linejoin="round" stroke-linecap="round" stroke-width="3" stroke="#fff" fill="transparent" data-name="Path 26"/><path d="M1742.994 137.008a2.354 2.354 0 0 1 3.33 3.329l-13.872 13.872-4.44 1.11 1.11-4.44 13.872-13.871Z" stroke-linejoin="round" stroke-linecap="round" stroke-width="3" stroke="#fff" fill="transparent" data-name="Path 27"/></g></svg>
                                    Update
                                </button>
                                {user != 1 && (
                                    <button className='add-station-reset-btn del-station-btn'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20.533" height="25.271" viewBox="1088.734 2551.281 20.533 25.271"><path d="M1090.314 2559.179v15.794c0 .869.71 1.58 1.579 1.58h14.215c.868 0 1.58-.711 1.58-1.58v-15.794h-17.374Zm4.738 14.214h-1.58v-11.055h1.58v11.055Zm3.159 0h-1.58v-11.055h1.58v11.055Zm3.158 0h-1.579v-11.055h1.58v11.055Zm3.16 0h-1.58v-11.055h1.58v11.055Zm3.553-18.953h-5.133v-1.974c0-.651-.533-1.184-1.185-1.184h-5.528c-.651 0-1.184.533-1.184 1.184v1.974h-5.133c-.652 0-1.185.533-1.185 1.185v1.974h20.533v-1.974c0-.652-.533-1.185-1.185-1.185Zm-6.713 0h-4.738v-1.56h4.738v1.56Z" fill="#fff" fill-rule="evenodd" data-name="Icon metro-bin"/></svg>
                                        Delete Station
                                    </button>
                                )}
                            </>
                        ) : (
                            <>
                                <button className='add-station-submit-btn pe-5'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24.846" height="24.846" viewBox="874.5 3014.154 24.846 24.846"><path d="m898.566 3019.586-4.652-4.652c-.5-.5-1.176-.78-1.882-.78h-14.87a2.662 2.662 0 0 0-2.662 2.662v19.521a2.662 2.662 0 0 0 2.662 2.663h19.522a2.662 2.662 0 0 0 2.662-2.663v-14.87c0-.705-.28-1.382-.78-1.881Zm-11.643 15.864a3.55 3.55 0 1 1 0-7.099 3.55 3.55 0 0 1 0 7.1Zm5.324-16.888v5.574a.666.666 0 0 1-.666.666h-12.866a.666.666 0 0 1-.666-.666v-5.767c0-.368.298-.666.666-.666h12.673c.177 0 .346.07.471.195l.193.193a.665.665 0 0 1 .195.47Z" fill="#fff" fill-rule="evenodd" data-name="Icon awesome-save"/></svg>
                                    ADD CHARGER
                                </button>
                                <button className='add-station-reset-btn' type='button' onClick={() => {setConnectorType(null); setImage(null); setImgPreview([]); setLocation({lat: 14.3700, lng: 100.9925}); setConnectors([]); reset();}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="33.128" height="24.846" viewBox="1079.5 3014.154 33.128 24.846"><path d="m1087.782 3014.154-8.282 12.423 8.282 12.423h24.846v-24.846h-24.846Zm6.212 3.23 6.211 6.211 6.212-6.211 2.981 2.981-6.211 6.212 6.211 6.211-2.981 2.982-6.212-6.212-6.211 6.212-2.982-2.982 6.212-6.211-6.212-6.212 2.982-2.981Z" fill="#fff" fill-rule="evenodd" data-name="Icon open-delete"/></svg>
                                    RESET
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <div className="m-nav-div">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" className={activeOp === 1 ? "m-active-link" : "no-optt"} onClick={() => {navigate('/home'); setActiveOp(1)}} width="27.424" height="27.424" viewBox="56 801.576 27.424 27.424"><path d="M69.712 817.002a7.715 7.715 0 0 0 7.713-7.713 7.715 7.715 0 0 0-7.713-7.713A7.715 7.715 0 0 0 62 809.289a7.715 7.715 0 0 0 7.713 7.713Zm6.856 1.714h-2.951a9.334 9.334 0 0 1-3.905.857c-1.392 0-2.71-.31-3.905-.857h-2.95A6.856 6.856 0 0 0 56 825.572v.857A2.572 2.572 0 0 0 58.571 829h22.282a2.572 2.572 0 0 0 2.571-2.571v-.857a6.856 6.856 0 0 0-6.856-6.856Z" fill-rule="evenodd" data-name="Icon awesome-user-alt"/></svg>
                </div>
                <div>
                    <svg className={activeOp === 2 ? "m-active-link" : "no-optt"} onClick={() => {navigate('/home/profile'); setActiveOp(2)}} xmlns="http://www.w3.org/2000/svg" width="27.424" height="27.424" viewBox="181 799.288 27.424 27.424"><path d="m205.099 799.288-10.387 10.284-3.428-3.428L181 816.531l3.428 3.428 6.856-6.959 3.428 3.428 13.712-13.712-3.325-3.428ZM181 823.284v3.428h27.424v-3.428H181Z" fill-rule="evenodd" data-name="Icon open-graph"/></svg>
                </div>
                <div>
                    <svg onClick={logoutFun2} xmlns="http://www.w3.org/2000/svg" width="25.71" height="27.424" viewBox="301.993 801.575 25.71 27.424"><path d="M322.56 818.716v-3.428h-8.57v-3.428h8.57v-3.428l5.143 5.142-5.142 5.142Zm-1.713-1.714v6.856h-8.57V829l-10.284-5.142v-22.283h18.854v8.57h-1.714v-6.856H305.42l6.856 3.429v15.426h6.856v-5.142h1.714Z"  fill-rule="evenodd" data-name="Icon metro-exit"/></svg>
                </div>
            </div>

            

            {/* <div className='overview-section' style={{borderTop: '2px solid #E4E7EC', paddingTop: '2rem'}}>
                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5>Charger Detail</h5>
                    </div>
                    <div>
                        <p>Charger name</p>
                        <input className='form-control' placeholder='Charger 1'/>
                        <div className='station-field-location'>
                            <div>
                                <p>Charger ID</p>
                                <input className='form-control' placeholder='T54-HU1-3220-008'/>
                            </div>
                            <div>
                                <p>Charger Number</p>
                                <input className='form-control' placeholder='P101'/>
                            </div>
                        </div>
                        <div className='station-field-location'>
                            <div>
                                <p>Brand</p>
                                <input className='form-control' placeholder='ABB'/>
                            </div>
                            <div>
                                <p>Model</p>
                                <input className='form-control' placeholder='TERRA CJG-54'/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5>Connector Type</h5>
                    </div>
                    <div className='station-connector'>
                        <div className='station-connector-section'>
                            <div>
                                <h6>CHAdeMO</h6>
                                <p>Type: DC <br/> Rated Power: 50 kW</p>
                            </div>
                            <div className='status'>
                                Available
                            </div>
                        </div>
                        <div className='station-connector-section'>
                            <div>
                                <h6>CCS 2</h6>
                                <p>Type: DC <br/> Rated Power: 50 kW</p>
                            </div>
                            <div className='status'>
                                Available
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </form>
        
    </div>
  )}
}
