import React, { useEffect, useState } from "react";
import HomeHdr from "../HomeHdr";
import AddHomeForm from "../AddHomeForm";
import EnergyUsed from "../../../common/EnergyUsed";
import DataTableBase from "../../../common/DataTableBase";
import { useNavigate, useParams } from "react-router-dom";
import GoogleMap from "../../../common/GoogleMap";
import axios from "axios";
import { BallTriangle } from "react-loader-spinner";
import { Button, Modal } from "react-bootstrap";
import dash1Icon from '../../../../assets/icons/dash1.png';
import { apiEndPoint } from "../../../../utils";
import SweetAlert from "react-bootstrap-sweetalert";

import * as XLSX from 'xlsx/xlsx.mjs';


const data2 = [
    {
        date: '2023-04-17',
        time: '18:30',
        chargingTime: '43m',
        EnergyUsed: '1.71',
        status: 'normal',
    },
    {
        date: '2023-04-16',
        time: '7:30',
        chargingTime: '1h 33m',
        EnergyUsed: '2.31',
        status: 'normal',
    },
    {
        date: '2023-04-13',
        time: '15:23',
        chargingTime: '2h 51m',
        EnergyUsed: '4.72',
        status: 'normal',
    },
    {
        date: '2023-04-11',
        time: '2:38',
        chargingTime: '42m',
        EnergyUsed: '1.09',
        status: 'normal',
    },
]
const SubstationDetail = ({user}) => {
    const [activeOp, setActiveOp] = useState(1);
    const [location, setLocation] = useState({
        // lat:33.684422,
        // lng: 73.047882
    });

    const [tableData, setTableData] = useState({});
    const [filter, setFilter] = useState('today');

    const [delInput, setDelInput] = useState(null);

    const navigate = useNavigate();

    const columns = [
        {
            name: "Date",
            selector: row => {
                if(row?.reportTime !== null && row?.reportTime !== 'undefined'){
                    const date = new Date(row?.reportTime)?.toISOString()?.split('T')[0]
                    return date;
                }
            },
        },
        {
            name: "Start Time",
            selector: row => {
                // if(row?.startTime !== null  && row?.startTime !== 'undefined'){
                //     const time = new Date(row?.startTime)?.toISOString()?.split('T')[1];
                //     const formateTime = time?.split('.')[0];
                //     return formateTime;
                // }
                return null;
            },
        },
        {
            name: "End Time",
            selector: row => {
                // if(row?.stopTime !== null && row?.stopTime !== 'undefined'){
                //     const time = new Date(row?.stopTime)?.toISOString()?.split('T')[1];
                //     const formateTime = time?.split('.')[0];
                //     return formateTime;
                // }
                return null;
            },
        },
        // {
        //     name: "Charging Time",
        //     selector: row => row?.chargingTime,
        // },
        {
            name: "Energy Used (kWh)",
            selector: row => (Number(row?.energy)).toLocaleString(),
        },
        {
            name: "Limit",
            selector: row => `${row?.transCapacity} / 100 %`,
        }
    ]


    function logoutFun2 (){
        localStorage.removeItem('pea_client');
        navigate('/login');
    }


    function handleLocation(event){
        const name = event.target.name
        const value = event.target.value
        if(name === 'lat'){
            setLocation({lng: location.lng, lat: parseInt(value)})
        }else if(name === 'lng'){
            setLocation({lat: location.lat, lng: parseInt(value)})
        }
    }

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const {id} = useParams();
    
    useEffect(() => {
        const token = localStorage.getItem("pea_admin");
        axios.get(`${apiEndPoint}/api/admin/substations/${id}`, {
            headers: {Authorization: `Bearer ${token}`}
        }).then((res) => {
            console.log("Res dashboard : ", res);
            setData(res?.data);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.log("Err : ", err);
        })
    }, [])

    const deleteFunc = () => {
        if(delInput === "DELETE"){
            handleClose();
            setLoading(true);
            const token = localStorage.getItem("pea_admin");
            
            axios.get(`${apiEndPoint}/api/admin/substation/delete/${id}`, {
                headers: {Authorization: `Bearer ${token}`}
            }).then((res) => {
                console.log("Res dashboard : ", res);
                setLoading(false);
                setShow1(true);
                setTimeout(() => {
                    setShow1(false);
                    navigate('/substationslist');
                }, 2000)
            }).catch((err) => {
                setLoading(false);
                console.log("Err : ", err);
            })
        }        
    }

    function convertToWorksheet(tableData) {
        const worksheet = XLSX.utils.json_to_sheet(tableData);

        // Set width for all columns to 15 characters
        // worksheet['!cols'] = Array(worksheet['!ref'].split(':')[1].charCodeAt(0) - 64).fill({ width: 25 });
        const columnWidths = [18, 20, 20, 22]

        worksheet['!cols'] = columnWidths.map(width => ({ width }));

        return worksheet;
    }


    function downloadExcelFile(tableData, fileName) {
        const worksheet = convertToWorksheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
    }



    function handleDownloadExcel(fileData) {
        const now = new Date();
        const isoDateTime = now.toISOString();    
        const structuredData2 = [];
        const StructureData = fileData?.filter(item => {
            let date;
            if(item?.startTime !== null && item?.startTime !== 'undefined'){
                date = new Date(item?.startTime)?.toISOString()?.split('T')[0];
            }
            let start;
            if(item?.startTime !== null  && item?.startTime !== 'undefined'){
                const date2 = new Date(item?.startTime)?.toISOString()?.split('T')[1];
                start = date2?.split('.')[0];
            }
            let end;
            if(item?.stopTime !== null && item?.stopTime !== 'undefined'){
                const time = new Date(item?.stopTime)?.toISOString()?.split('T')[1];
                end = time?.split('.')[0];
            }
            const obj = {"Date": date, "Start Time": start, "Stop Time": end, "Energy Used kWh": item?.meterDifference, "Status": `0/100`};
            structuredData2?.push(obj);
            return obj;
            }
        )

        // console.log("Structured data : ", StructureData)

        // console.log("Structured data 2 : ", structuredData2)
        
        downloadExcelFile(structuredData2, `Substation Detail ${isoDateTime}`);
    }

    if(loading){
        return (
            <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '33vh'}}>
                <BallTriangle
                    height={90}
                    width={90}
                    radius={5}
                    color="#814097"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle="" 
                    visible={true}
                />
            </div>
        )
    } else {

    return (
        <>
            {show1 && (
                <SweetAlert success title="Congratulations" onConfirm={() => {setShow1(false); navigate('/substationslist')}} onCancel={() => {setShow1(false);}}>
                    Substation deleted sucessfully.
                </SweetAlert>
            )}
            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                <Modal.Title>Alert</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center">
                        <h4>Are you sure you want to delete this? </h4>
                        <p>
                            If yes then write 'DELETE' in the input field
                        </p>
                        <input type="text" className="form-control" onChange={(e) => setDelInput(e.target.value)} />
                    </div>
                
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={deleteFunc}>
                    Yes
                </Button>
                </Modal.Footer>
            </Modal>

            {user != 1 && (
                <HomeHdr type={1} handleShow={handleShow} data={data} url={data?.substation?.substationImage} />
            )}
            <div className="overview-page pt-0">
                <EnergyUsed setTableData={setTableData} lab1={`${data?.substation?.substationsID} Statistic`} url={`SubStation/${id}`} />
                





            </div>
            <div className="m-nav-div">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" className={activeOp === 1 ? "m-active-link" : "no-optt"} onClick={() => {navigate('/home'); setActiveOp(1)}} width="27.424" height="27.424" viewBox="56 801.576 27.424 27.424"><path d="M69.712 817.002a7.715 7.715 0 0 0 7.713-7.713 7.715 7.715 0 0 0-7.713-7.713A7.715 7.715 0 0 0 62 809.289a7.715 7.715 0 0 0 7.713 7.713Zm6.856 1.714h-2.951a9.334 9.334 0 0 1-3.905.857c-1.392 0-2.71-.31-3.905-.857h-2.95A6.856 6.856 0 0 0 56 825.572v.857A2.572 2.572 0 0 0 58.571 829h22.282a2.572 2.572 0 0 0 2.571-2.571v-.857a6.856 6.856 0 0 0-6.856-6.856Z" fill-rule="evenodd" data-name="Icon awesome-user-alt"/></svg>
                </div>
                <div>
                    <svg className={activeOp === 2 ? "m-active-link" : "no-optt"} onClick={() => {navigate('/home/profile'); setActiveOp(2)}} xmlns="http://www.w3.org/2000/svg" width="27.424" height="27.424" viewBox="181 799.288 27.424 27.424"><path d="m205.099 799.288-10.387 10.284-3.428-3.428L181 816.531l3.428 3.428 6.856-6.959 3.428 3.428 13.712-13.712-3.325-3.428ZM181 823.284v3.428h27.424v-3.428H181Z" fill-rule="evenodd" data-name="Icon open-graph"/></svg>
                </div>
                <div>
                    <svg onClick={logoutFun2} xmlns="http://www.w3.org/2000/svg" width="25.71" height="27.424" viewBox="301.993 801.575 25.71 27.424"><path d="M322.56 818.716v-3.428h-8.57v-3.428h8.57v-3.428l5.143 5.142-5.142 5.142Zm-1.713-1.714v6.856h-8.57V829l-10.284-5.142v-22.283h18.854v8.57h-1.714v-6.856H305.42l6.856 3.429v15.426h6.856v-5.142h1.714Z"  fill-rule="evenodd" data-name="Icon metro-exit"/></svg>
                </div>
            </div>

            
            
            
            
            
            <div className='overview-section' style={{padding: '0px 32px'}}>
                <div className="charge-div-border">
                    <div>
                        <h3 className="mb-0 clr-707070">Substation Detail</h3>
                        <p className="clr-707070 mb-2">Substation details and address</p>
                    </div>
                </div>

                <div className='station-field pt-4'>
                    <div className='station-field-div1'>
                        <h5 className="clr-707070">Substation ID</h5>
                        {/* <p></p> */}
                    </div>
                    <div className="width-35-percent">
                        <input className='form-control ps-0 input-read clr-707070'  defaultValue={data?.substation?.substationsID} readOnly placeholder='SUB-ID-1'/>
                    </div>
                </div>
                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5 className="clr-707070">Substation Name</h5>
                        {/* <p>This will be displayed to customer.</p> */}
                    </div>
                    <div className="width-35-percent">
                        <input className='form-control ps-0 input-read'  defaultValue={data?.substation?.substationName} readOnly placeholder='NAME-1' />
                    </div>
                </div>
                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5 className="clr-707070">Address</h5>
                        {/* <p>This will be displayed to customer.</p> */}
                    </div>
                    <div className="width-35-percent">
                        <input className='form-control ps-0 input-read'  defaultValue={data?.substation?.substationAddress} readOnly  placeholder='111/111 mittraparb road naimuang muang korat 30000'/>
                    </div>
                </div>

                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5 className="clr-707070">Location</h5>
                        <p className="clr-707070">Location of this power transformer</p>
                    </div>
                    <div>
                        <div className='station-field-location'>
                            <div>
                                <h6 className="clr-707070">Latitude</h6>
                                <input className='ps-0 form-control input-read' defaultValue={data?.substation?.substationlocationLat} placeholder='14.044750' name='lat' onChange={handleLocation}/>
                            </div>
                            <div>
                                <h6 className="clr-707070">Longitude</h6>
                                <input className='ps-0 form-control input-read' defaultValue={data?.substation?.substationlocationLong} placeholder='100.591728' name='lng' onChange={handleLocation}/>
                            </div>
                        </div>
                        <div className='station-map station-map-desk'>
                            {/* <GoogleMap lat={location.lat} lng={location.lng} width={'40%'} height={'40%'}/> */}
                            {/* {station ? ( */}
                                <GoogleMap type={1} lat={parseFloat(data?.substation?.substationlocationLat)} lng={parseFloat(data?.substation?.substationlocationLong)} width={'40%'} height={'380px'}/>
                            {/* ) : (
                                <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '3vh'}}>
                                    <BallTriangle
                                        height={70}
                                        width={70}
                                        radius={5}
                                        color="#814097"
                                        ariaLabel="ball-triangle-loading"
                                        wrapperClass={{}}
                                        wrapperStyle=""
                                        visible={true}
                                    />
                                </div>
                            )} */}
                        </div>
                    </div>
                </div>


                <div className='w-100 station-map-mob' style={{overflow:'hidden'}}>
                    <div className='station-map '>
                        {/* <GoogleMap lat={location.lat} lng={location.lng} width={'40%'} height={'40%'}/> */}
                        {/* {station ? ( */}
                        <GoogleMap type={1} lat={data?.substation?.substationlocationLat} lng={data?.substation?.substationlocationLong} width={'40%'} height={'40%'}/>
                    </div>
                </div>
                

                <div className="charge-log mt-5">
                    <div className="d-flex charge-div-border">
                        <div className="w-75">
                            <h3 className="mb-0">Substation Information</h3>
                            <p className="mb-2">The information of the substation</p>
                        </div>
                        <div className="w-25">
                            <div className="float-end pt-md-4 pt-2 mt-2">
                                <button className="arrow-btns">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10.56" height="18.471" viewBox="10.166 6.257 10.56 18.471"><path d="m13.349 15.49 6.989-6.984a1.314 1.314 0 0 0 0-1.864 1.326 1.326 0 0 0-1.87 0l-7.918 7.913c-.5.5-.511 1.303-.038 1.82l7.95 7.968c.26.258.6.385.936.385.335 0 .676-.127.934-.385a1.314 1.314 0 0 0 0-1.864l-6.983-6.99Z" fill="#b2b2b2" fill-rule="evenodd" data-name="Icon ionic-ios-arrow-back"/></svg>
                                </button>
                                <button className="arrow-btns arrow-right-rotate ms-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10.56" height="18.471" viewBox="10.166 6.257 10.56 18.471"><path d="m13.349 15.49 6.989-6.984a1.314 1.314 0 0 0 0-1.864 1.326 1.326 0 0 0-1.87 0l-7.918 7.913c-.5.5-.511 1.303-.038 1.82l7.95 7.968c.26.258.6.385.936.385.335 0 .676-.127.934-.385a1.314 1.314 0 0 0 0-1.864l-6.983-6.99Z" fill="#b2b2b2" fill-rule="evenodd" data-name="Icon ionic-ios-arrow-back"/></svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="mt-2">
                        <div className="overview-sec4">
                            <div className="d-flex w-100">
                                <div className="w-20 pe-3">
                                    <h5 className="mb-1">Substation (1)</h5>
                                    <div className="scrollable-box">
                                        <h6 className="mb-1" onClick={() => navigate(`/substationdetail/${data?.substation?.id}`)}>{data?.substation?.substationsID}</h6>
                                    </div>
                                </div>
                                <div className="w-20 pe-3">
                                    <h5 className="mb-1">Feeder ({data?.feeders?.length})</h5>
                                    <div className="scrollable-box scroll-y-auto">
                                        {data?.feeders?.map((item, index) => (
                                            <h6 key={index+1} onClick={() => navigate(`/feederdetail/${item?.id}`)} className="mb-1 pointer">{item?.feederID}</h6>
                                        ))}
                                        {/* <h6 className="mb-1">FEED-ID-no-name1</h6>
                                        <h6 className="mb-1">FEED-ID-no-name2</h6>
                                        <h6 className="mb-1">FEED-ID-no-name3</h6>
                                        <h6 className="mb-1">FEED-ID-no-name4</h6> */}

                                    </div>
                                </div>
                                <div className="w-20 pe-3">
                                    <h5 className="mb-1">Transformer ({data?.transformer?.length})</h5>
                                    <div className="scrollable-box">
                                        {data?.transformer?.map((item, index) => (
                                            <h6 key={index+1} onClick={() => navigate(`/transformerdetail/${item?.id}`)} className="mb-1 pointer">{item?.transID}</h6>
                                        ))}
                                    </div>
                                </div>
                                <div className="w-20 pe-3">
                                    <h5 className="mb-1">Meter ({data?.meters?.length})</h5>
                                    <div className="scrollable-box">
                                        {data?.meters?.map((item, index) => (
                                            <h6 key={index+1} onClick={() => navigate(`/meterdetail/${item?.id}`)} className="mb-1 pointer">{item?.meterID}</h6>
                                        ))}
                                        

                                    </div>
                                </div>
                                <div className="w-20 pe-21">
                                    <h5 className="mb-1">Charger ({data?.chargers?.length})</h5>
                                    <div className="scrollable-box">
                                        {data?.chargers?.map((item, index) => (
                                            <h6 key={index+1} onClick={() => navigate(`/chargerdetail/${item?.id}`)} className="mb-1 pointer">{item?.chargerID}</h6>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="charge-log">
                    <div className="d-flex charge-div-border">
                        <div className="w-50">
                            <h3 className="mb-0">Charge Log</h3>
                            <p className="mb-2">status history</p>
                        </div>
                        <div className="w-50">
                            <div className="float-endx d-flex justify-content-end w-100 pt-md-4 pt-2 mt-2">
                                <div className="pt-2">
                                    <span>Choose time :</span>
                                </div>
                                <div className="mx-3">
                                    <select onChange={(e) => setFilter(e.target.value)} style={{width: '110px'}} className="form-control d-inline">
                                        <option value="today">Today</option>
                                        <option value="week">This Week</option>
                                        <option value="month">This Month</option>
                                        <option value="year">This Year</option>
                                    </select>
                                </div>
                                <div>
                                    <button className="exprt-btn" onClick={() => handleDownloadExcel(filter === 'today' ? tableData?.today : filter === 'week' ? tableData?.week : filter === "month" ? tableData?.month : tableData?.year)}>Export</button>
                                </div>
                                {/* <button className="arrow-btns">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10.56" height="18.471" viewBox="10.166 6.257 10.56 18.471"><path d="m13.349 15.49 6.989-6.984a1.314 1.314 0 0 0 0-1.864 1.326 1.326 0 0 0-1.87 0l-7.918 7.913c-.5.5-.511 1.303-.038 1.82l7.95 7.968c.26.258.6.385.936.385.335 0 .676-.127.934-.385a1.314 1.314 0 0 0 0-1.864l-6.983-6.99Z" fill="#b2b2b2" fill-rule="evenodd" data-name="Icon ionic-ios-arrow-back"/></svg>
                                </button>
                                <button className="arrow-btns arrow-right-rotate ms-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10.56" height="18.471" viewBox="10.166 6.257 10.56 18.471"><path d="m13.349 15.49 6.989-6.984a1.314 1.314 0 0 0 0-1.864 1.326 1.326 0 0 0-1.87 0l-7.918 7.913c-.5.5-.511 1.303-.038 1.82l7.95 7.968c.26.258.6.385.936.385.335 0 .676-.127.934-.385a1.314 1.314 0 0 0 0-1.864l-6.983-6.99Z" fill="#b2b2b2" fill-rule="evenodd" data-name="Icon ionic-ios-arrow-back"/></svg>
                                </button> */}
                            </div>
                        </div>
                    </div>
                    <div className="mt-2">
                        <DataTableBase 
                            pagination
                            // title={"Error Home Location"}
                            columns={columns}
                            data={filter === 'today' ? tableData?.today : filter === 'week' ? tableData?.week : filter === "month" ? tableData?.month : tableData?.year}
                        />
                    </div>
                </div>


            
            </div>

            {/* {user != 1 && (
                <AddHomeForm type={1} />
            )} */}
        </>
    )}
}

export default SubstationDetail;