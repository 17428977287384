import React from "react";
import HomeHdr from "../HomeHdr";
import AddHomeForm from "../AddHomeForm";

const AddHomePage = () => {
    return (
        <>
            <HomeHdr />
            <AddHomeForm />
        </>
    )
}

export default AddHomePage;