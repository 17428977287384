import React from "react";
import HomeHdr from "../HomeHdr";
import AddHomeForm from "../AddHomeForm";

const EditHomePage = ({user}) => {

    return (
        <>
            <div>
                <HomeHdr type={2} user={user} />            
                <AddHomeForm type={2} user={user} />
            </div>
        </>
    )
}

export default EditHomePage;