import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const AreaCircuit = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <div className="area-circuit">
            <div className="w-100 heading">
                <h2 className="mb-0">Area Circuit</h2>
                <p className="mb-0">Turn on / off circuit area.</p>
            </div>
            <div className="w-100 pt-4">
                <Area heading={"Area 12"} handleShow={handleShow} />
            </div>
            <div className="w-100 heading py-4">
                <h2 className="mb-0">Sub Area Circuit</h2>
                <p className="mb-0">Turn on / off sub circuit area.</p>
            </div>

            <Row>
                <Col md={6} className="px-2 py-2">
                    <Area heading={"Sub Area 1"} handleShow={handleShow} />
                </Col>
                <Col md={6} className="px-2 py-2">
                    <Area heading={"Sub Area 2"} handleShow={handleShow} />
                </Col>
                <Col md={6} className="px-2 py-2">
                    <Area heading={"Sub Area 3"} handleShow={handleShow} />
                </Col>
                <Col md={6} className="px-2 py-2">
                    <Area heading={"Sub Area 4"} handleShow={handleShow} />
                </Col>
            </Row>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                style={{marginTop: '20vh'}}
            >
                {/* <Modal.Header closeButton>
                <Modal.Title>Modal title</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                I will not close if you click outside me. Don't even try to press
                escape key.
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary">Understood</Button>
                </Modal.Footer> */}
                <div className="notification-model">
                    <h4 className="popup-notification-heading">Notification</h4>
                    <p className="model-desc-style">are you sure to changes....</p>
                    <div style={{ marginLeft: '15px'}}>
                        <button className="yes-no-btn" onClick={handleClose}>YES</button>
                        <button className="yes-no-btn no-btn-gray" onClick={handleClose}>NO</button>
                    </div>
                </div>
            </Modal>

        </div>
    )
}


const Area = ({heading, handleShow}) => {
    const [switchh, setSwitchh] = useState(true);

    return (
        <div className="main-area d-flex">
            <div className="w-50">
                <h3 className="mb-0">{heading}</h3>
                <p className="mb-0">Details : turn off circuit this area</p>
            </div>
            <div className="w-50">
                <div className='switch-btn-div pt-3 float-end'>
                    <input type="checkbox" defaultChecked={true} onChange={(e) => { handleShow(); setSwitchh(e.target.checked)}} name="check" value={true} class="toggle-station"/>
                    {switchh ? (
                        <span className='text-successsdf ms-2'>On</span>
                    ) : (
                        <span className='text-secondary ms-2'>Off</span>
                    )}

                </div>
            </div>
        </div>
    )
}


export default AreaCircuit;

