import React, { useState } from 'react';
import Chart from 'react-apexcharts'



const ColumnGraph = ({tooltipLabel, tog, tog2, data2, label, labs, labels, weekDateLabel, max}) => {
  const withMax = {      
    max: max,
    axisBorder: {
      show: false
      // show: true
    },
    axisTicks: {
      show: false,
      // show: true,
    },
    labels: {
      // show: false,
      show: true,
      formatter: function (val) {
        // return (Math.ceil(val * 100) / 100).toLocaleString();
        return Number((val).toFixed(0)).toLocaleString();
      }
    }    
  }
  const withoutMax = {      
    axisBorder: {
      show: false
      // show: true
    },
    axisTicks: {
      show: false,
      // show: true,
    },
    labels: {
      // show: false,
      show: true,
      formatter: function (val) {
        return Number((val).toFixed(0)).toLocaleString();
      }
    }    
  };
  const data =  [{
    name: label,
    // data: [5.3, 3.1, 7.0, 10.1, 4.0, 8.6, 6.2, 7.3, 1.4, 8, 5, 8.2]
    data: data2 ? data2 : [60, 123, 232, 133, 154, 255, 216, 177, 238, 379, 110, 212, 293, 114, 315, 246, 127, 198,149, 230, 321, 222, 233],
  }]
  let annotation1 = [];
  if(tog2 === 1){
    annotation1 = [{x: '1'},{x: '2'},{x: '3'},{x: '4'},{x: '5'},{x: '6'},{x: '7'},{x: '8'},{x: '9'},{x: '10'},{x: '11'},{x: '12'},{x: '13'},{x: '14'},{x: '15'},{x: '16'},{x: '17'},{x: '18'},{x: '19'},{x: '20'},{x: '21'},{x: '22'},{x: '23'},{x: '00'}];
  } else if(tog2 === 2){
    annotation1 = [{x: '0'},{x: '24'},{x: '49'},{x: '75'},{x: '100'},{x: '125'},{x: '150'}];
  } else {
    annotation1 = [{x: '222222'}]
  }

   // Dynamically generate labels for x-axis
  const generateLabels = (dataArray) => {
    const arr = [];
    dataArray?.map(item => {
      arr?.push(`"${item?.x}"`);
    })
    console.log("label array : ", arr);
    return arr;
  };

  const option = {
    annotations: {
      xaxis: 
          annotation1      
    },
    chart: {
      type: 'bar',
      height: 500,
      stacked: true,
      toolbar: {
        show: true
      },
      zoom: {
        enabled: true
      },
    },
    plotOptions: {
          bar: {
            borderRadius: 5,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return (Math.ceil(val * 100) / 100).toLocaleString();
      },
      offsetY: -20,
      style: {
        colors: ["transparent"]
      }
    },
    responsive: [{
      breakpoint: "480px"
    }],
    xaxis: {
      // categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      // categories: labs? labs : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 16, 17, 18,19, 20, 21, 22, 23],
      // categories: Array.from({ length: 24 }, (_, i) => `${i}:00`), // For today            
      categories: labs, // For today            
      position: 'bottom',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          }
        }
      },
      tooltip: {
        enabled: true,
      }

    },
    yaxis: max ? withMax : withoutMax,    
    fill: {
      opacity: 1,
      colors: ['#B76AD1'],
    },
    stroke: {
      dashArray: 8,
      colors: ['#B76AD1'],
    },
    // labels: labels,
    tooltip: {
      enabled: true,
      theme: 'light', // Set the theme to 'light' for visibility
      // custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      //   return '<div class="tooltip-container">' +
      //     '<span>Energy: </span><span>' + w.globals.labels[dataPointIndex] + '</span><br/>' +
      //     '<span> Value' + series[seriesIndex][dataPointIndex] + '</span>' +
      //     '</div>';
      // },
      // custom: ({ series, seriesIndex, dataPointIndex, w }) => {
      //   return '<div class="arrow_box">' +
      //     '<span>' + series[seriesIndex][dataPointIndex] + '</span>' +
      //     '</div>'
      // },
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        return `
          <div class="custom-tooltip">
            <span class="marker" style="background-color: ${w.globals.colors[seriesIndex]}"></span>
            <span>${tooltipLabel ? tooltipLabel : "Power"}: <strong>${Number(series[seriesIndex][dataPointIndex]).toFixed(3)}</strong></span>
          </div>
        `;
      },
      marker: {
        show: true,
        fillColors: ['#B76AD1'], // Change tooltip marker color here
      },
    },
    markers: {
      show: false,
      // size: 8,
      colors: ['#B76AD1'], // Initial dot color
      // ... other marker configurations
    }
  }

  return (
    <div id="chart">
      <Chart options={option} series={[{name: label ? label : "Power", data: data2}]} type="bar" height={350} />
      {tog2 === 2 && (
        <div className='week-label-div'>
          <div className="d-flex text-center">
            {weekDateLabel?.map((item, index) => (
              <div className="dayy" key={index}>{item}</div>            
            ))}
          </div>
        </div>
      )}
      {/* {tog === 4 && (
        <div className='w-100 text-center'>
          Week
        </div>
      )} */}
    </div>
  )
}

export default ColumnGraph