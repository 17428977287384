import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { BallTriangle } from 'react-loader-spinner'
import { useNavigate, useParams } from 'react-router-dom'
import '../../../styles/Addhome.css'

import homeImg from '../../../assets/charger station mock-mobile.png';


import GoogleMap from '../../common/GoogleMap';

export default function AddHomeForm({type, user}) {
    const [imgPreview, setImgPreview] = useState([]);
    const [hide, setHide] = useState(false);
    const [station, setStation] = useState(null);

    const [activeOp, setActiveOp] = useState(2);

    const [check, setCheck] = useState(true);
    
    const [newPass, setNewPass] = useState(false);


    const navigate = useNavigate();

    const [location, setLocation] = useState({
        // lat:33.684422,
        // lng: 73.047882
    })

    const {id} = useParams();

    function handleLocation(event){
        const name = event.target.name
        const value = event.target.value
        if(name === 'lat'){
            setLocation({lng: location.lng, lat: parseInt(value)})
        }else if(name === 'lng'){
            setLocation({lat: location.lat, lng: parseInt(value)})
        }
    }

    function handleFile(event){
        if(event.target.files){
            const files = event.target.files
            let array = []
            for(var i=0;i<files.length;i++){
                array.push(URL.createObjectURL(files[i]))
            }
            setImgPreview([...array])
        }
    }

    function removeImg(index){
        const array = []
        for(var i=0;i<imgPreview.length;i++){
            if(i !== index){
                array.push(imgPreview[i])
            }
        }
        setImgPreview([...array])
    }

    useEffect(() => {
        // const token = localStorage.getItem('token');

        // axios.get(`https://api.pea-cpo.com/api/getChargerListCpoById/${id}`, {headers: {'Authorization' : `Bearer ${token}`}}).then((res) => {
        // console.log("Get Charger detail : ", res);
        
        // setStation(res?.data?.data[0]);

        // setLocation({
        //     lat: res?.data?.data[0]?.lat,
        //     lng: res?.data?.data[0]?.lng
        // })
        // setImgPreview([res?.data?.data[0]?.chargerPhoto])


        // console.log("lat : ", res?.data?.data[0]?.lat)
        // console.log("lng : ", res?.data?.data[0]?.lng)

        // }).catch((err) =>{
        //     console.log("Error get user : ", err);
        // })
    }, [])



    function logoutFun2 (){
        localStorage.removeItem('pea_client');
        navigate('/login');
    }


  return (
    <div className='station-overview' style={{marginTop: `${type === 1 ? '-16px': 'afa'}`}}>
        <div className='overview-section-heading'>
            <h3>Home Location Profile</h3>
            <p>Add home details and address</p>
        </div> 
        <div className='station-img-mobile-div'>
            <img src={homeImg} alt="station-img" width={"100%"} />
        </div>
        <div className='overview-section'>  
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Home Location ID</h5>
                    <p>This will be displayed to customer.</p>
                </div>
                <div className="width-35-percent">
                    <input className='form-control'  defaultValue={station?.chargerID} placeholder='AST-175A-556'/>
                </div>
            </div>
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Home Location name (TH)</h5>
                    <p>This will be displayed to customer.</p>
                </div>
                <div className="width-35-percent">
                    <input className='form-control'  defaultValue={station?.locationTH} placeholder='บ้าน โคราช 0'/>
                </div>
            </div>
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Home Location name (EN)</h5>
                    <p>This will be displayed to customer.</p>
                </div>
                <div className="width-35-percent">
                    <input className='form-control'  defaultValue={station?.locationEN}  placeholder='HOME KORAT 01'/>
                </div>
            </div>
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Address (TH)</h5>
                    <p>Address that available for contact</p>
                </div>
                <div className="width-35-percent">
                    <input className='form-control'  defaultValue={station?.descriptionTH} placeholder='41/179 ต.ในเมือง อ.เมือง จ.นคคราชสีมา 30000 '/>
                </div>
            </div>
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Address (EN)</h5>
                    <p>Address that available for contact</p>
                </div>
                <div className="width-35-percent">
                    <input className='form-control'  defaultValue={station?.descriptionEN} placeholder='41/179 Thambol Nai Mueang Amphur Mueang, Nakhon Ratchasima 30000'/>
                </div>
            </div>

            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>User</h5>
                    <p>required *</p>
                </div>
                <div className="width-35-percent">
                    <input className='form-control' defaultValue={station?.descriptionEN} placeholder='Santanasandee'/>
                </div>
            </div>
            
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Email</h5>
                    <p>required *</p>
                </div>
                <div className="width-35-percent">
                    <input className='form-control' defaultValue={station?.descriptionEN} placeholder='Santana@gmail.com'/>
                </div>
            </div>
            
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Name - Surname</h5>
                    <p>required *</p>
                </div>
                <div className="width-35-percent">
                    <input className='form-control' defaultValue={station?.descriptionEN} placeholder='Santana Sandee'/>
                </div>
            </div>

            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Phone</h5>
                    <p>required *</p>
                </div>
                <div className="width-35-percent">
                    <input className='form-control' defaultValue={station?.descriptionEN} placeholder='This will be displayed to customer.'/>
                </div>
            </div>
            {type === 1 ? (
                <></>
            ) : type === 2 ? (
                <>
                    <div className='station-field'>
                        <div className='station-field-div1'>
                            <h5>Password</h5>
                            <p>required *</p>
                        </div>
                        <div className="width-35-percent">
                            <button className='generate-password-btn me-3' onClick={() => setNewPass(!newPass)}>{newPass ? "Don't Want to change" : 'Set New Password'}</button>
                            {newPass && (
                                <>
                                    <button className='generate-password-btn'>Generate Password</button>
                                    <div className='d-flex'>
                                        <div className='w-888'>
                                            <input className='form-control' type={hide ? 'text' : 'password'} defaultValue={station?.descriptionEN} placeholder='Enter Password'/>                            
                                        </div>
                                        <div>
                                            <button className='hide-btn' onClick={() => setHide(!hide)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22.531" height="17.925" viewBox="673.484 11.333 27.531 22.025"><path d="M687.25 28.54c-3.263 0-5.904-2.526-6.147-5.726l-4.513-3.488c-.593.745-1.139 1.531-1.58 2.392-.199.395-.199.86 0 1.256 2.334 4.551 6.952 7.63 12.24 7.63 1.158 0 2.274-.171 3.35-.45l-2.231-1.726a6.2 6.2 0 0 1-1.119.112Zm13.5 2.5-4.756-3.676a14.25 14.25 0 0 0 3.496-4.39c.2-.396.2-.862 0-1.257-2.333-4.551-6.952-7.63-12.24-7.63-2.215.002-4.393.56-6.337 1.62l-5.473-4.23a.688.688 0 0 0-.966.122l-.845 1.087c-.233.3-.179.732.121.966l25.31 19.561c.3.234.733.18.966-.12l.845-1.088a.688.688 0 0 0-.121-.966Zm-7.903-6.11-1.69-1.306a4.076 4.076 0 0 0-4.996-5.245c.26.351.4.776.4 1.213-.006.146-.028.29-.065.43l-3.167-2.447a6.122 6.122 0 0 1 3.921-1.424 6.191 6.191 0 0 1 6.195 6.194c0 .93-.228 1.798-.598 2.586Z" fill="#814097" fill-rule="evenodd" data-name="Icon awesome-eye-slash"/></svg>
                                                Hide
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className='station-field'>
                        <div className='station-field-div1'>
                            <h5>Password</h5>
                            <p>required *</p>
                        </div>
                        <div className="width-35-percent">
                            <button className='generate-password-btn'>Generate Password</button>
                            <div className='d-flex'>
                                <div className='w-888'>
                                    <input className='form-control' type={hide ? 'text' : 'password'} defaultValue={station?.descriptionEN} placeholder='Enter Password'/>                            
                                </div>
                                <div>
                                    <button className='hide-btn' onClick={() => setHide(!hide)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22.531" height="17.925" viewBox="673.484 11.333 27.531 22.025"><path d="M687.25 28.54c-3.263 0-5.904-2.526-6.147-5.726l-4.513-3.488c-.593.745-1.139 1.531-1.58 2.392-.199.395-.199.86 0 1.256 2.334 4.551 6.952 7.63 12.24 7.63 1.158 0 2.274-.171 3.35-.45l-2.231-1.726a6.2 6.2 0 0 1-1.119.112Zm13.5 2.5-4.756-3.676a14.25 14.25 0 0 0 3.496-4.39c.2-.396.2-.862 0-1.257-2.333-4.551-6.952-7.63-12.24-7.63-2.215.002-4.393.56-6.337 1.62l-5.473-4.23a.688.688 0 0 0-.966.122l-.845 1.087c-.233.3-.179.732.121.966l25.31 19.561c.3.234.733.18.966-.12l.845-1.088a.688.688 0 0 0-.121-.966Zm-7.903-6.11-1.69-1.306a4.076 4.076 0 0 0-4.996-5.245c.26.351.4.776.4 1.213-.006.146-.028.29-.065.43l-3.167-2.447a6.122 6.122 0 0 1 3.921-1.424 6.191 6.191 0 0 1 6.195 6.194c0 .93-.228 1.798-.598 2.586Z" fill="#814097" fill-rule="evenodd" data-name="Icon awesome-eye-slash"/></svg>
                                        Hide
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='station-field'>
                        <div className='station-field-div1'>
                            <h5>Send User Notification</h5>
                            <p>Send the new user an email about their account.</p>
                        </div>
                        <div className="width-35-percent">
                            <input type="checkbox" class="form-check-input notification-check" id="exampleCheck1" />
                            {/* <input className='form-control' type='checkbox' defaultValue={station?.descriptionEN} placeholder='This will be displayed to customer.'/> */}
                        </div>
                    </div>
                </>
            )}
            
            



            



            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Location</h5>
                    <p>Location of this charger</p>
                </div>
                <div>
                    <div className='station-field-location'>
                        <div>
                            <h6>Latitude</h6>
                            <input className='form-control' defaultValue={location?.lat} placeholder='14.044750' name='lat' onChange={handleLocation}/>
                        </div>
                        <div>
                            <h6>Longitude</h6>
                            <input className='form-control' defaultValue={location?.lng} placeholder='100.591728' name='lng' onChange={handleLocation}/>
                        </div>
                    </div>
                    <div className='station-map station-map-desk'>
                        {/* <GoogleMap lat={location.lat} lng={location.lng} width={'40%'} height={'40%'}/> */}
                        {/* {station ? ( */}
                            <GoogleMap lat={15.8700} lng={100.9925} width={'40%'} height={'40%'}/>
                        {/* ) : (
                            <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '3vh'}}>
                                <BallTriangle
                                    height={70}
                                    width={70}
                                    radius={5}
                                    color="#814097"
                                    ariaLabel="ball-triangle-loading"
                                    wrapperClass={{}}
                                    wrapperStyle=""
                                    visible={true}
                                />
                            </div>
                        )} */}
                    </div>
                </div>
            </div>


            <div className='w-100 station-map-mob' style={{overflow:'hidden'}}>
                <div className='station-map '>
                    {/* <GoogleMap lat={location.lat} lng={location.lng} width={'40%'} height={'40%'}/> */}
                    {/* {station ? ( */}
                    <GoogleMap lat={15.8700} lng={100.9925} width={'40%'} height={'40%'}/>
                </div>
            </div>



            <div className='station-field location' style={{height: '10vh', marginTop: '13vh'}}>
                
                <div className='station-field-div1'>
                    <h5>Home Charger Picture</h5>
                    <p>This will be displayed to customer.</p>
                </div>
                <div className='img-preview' style={{zIndex:'10'}} >
                    <div>
                    {imgPreview.length > 0 && imgPreview.map((url, index)=>
                        <div key={index}>
                            <span style={{cursor:'pointer'}} onClick={()=>removeImg(index)}>&times;</span>
                            <img src={url} alt={url}/>
                        </div>
                    )}
                    </div>
                    <input type='file'  className='form-control' accept="image/*"  multiple onChange={handleFile}/>
                </div>
            </div>
        </div>
        <div className='overview-section-heading' style={{marginTop: '160px'}}>
            <h4 className='haeding-style-charger-info'>Charger Information</h4>
            <p>The information of the charger and connector types.</p>
        </div>
        <div className='overview-section'>
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Charger Detail</h5>
                </div>
                <div>
                    <p>Charger name</p>
                    <input className='form-control' defaultValue={station?.locationTH} placeholder='PEA VOLTA #01'/>
                    <div className='station-field-location'>
                        <div>
                            <p>Charger ID</p>
                            <input className='form-control' defaultValue={station?.chargerID} placeholder='PEA-1129'/>
                        </div>
                        <div>
                            <p>Charger Number</p>
                            <input className='form-control' defaultValue={station?.number} placeholder='P101'/>
                        </div>
                    </div>
                    <div className='station-field-location'>
                        <div>
                            <p>Brand</p>
                            <input className='form-control' placeholder='AlphaEV'/>
                        </div>
                        <div>
                            <p>Model</p>
                            <input className='form-control'  placeholder='A129'/>
                        </div>
                    </div>
                </div>
            </div>


            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Connector Type</h5>
                    {/* <p>required *</p> */}
                </div>
                <div className='connector-type-divv'>
                    {/* <input className='form-control'  defaultValue={station?.descriptionEN} placeholder='This will be displayed to customer.'/> */}
                    {type === 1 ? (
                        <></>
                    ) : (
                        user != 1 && (
                            <div className='d-flex station-field'>
                                <div className='connector-type-select-div'>
                                    <select className='form-control pe-4'>
                                        <option>Choose Connector Type </option>
                                        <option value="AC">AC</option>
                                        <option value="DC">DC</option>
                                    </select>
                                    {/* <svg className='connector-type-icon' xmlns="http://www.w3.org/2000/svg" width="23.616" height="13.503" viewBox="1227 2213.001 23.616 13.503"><path d="m1238.813 2222.433 8.93-8.937a1.68 1.68 0 0 1 2.383 0c.654.661.654 1.73 0 2.39l-10.118 10.126c-.64.64-1.667.654-2.328.049l-10.188-10.167a1.687 1.687 0 0 1-.492-1.196c0-.428.162-.864.492-1.195a1.68 1.68 0 0 1 2.384 0l8.937 8.93Z" fill="#707070" fill-rule="evenodd" data-name="Icon ionic-ios-arrow-down"/></svg> */}
                                </div>
                                <div>
                                    <button className='add-connector-btn'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24.846" height="24.846" viewBox="1364.137 2203.154 24.846 24.846"><path d="M1376.56 2203.154c-6.863 0-12.423 5.56-12.423 12.423s5.56 12.423 12.423 12.423c6.862 0 12.423-5.56 12.423-12.423 0-6.862-5.56-12.423-12.423-12.423Zm7.213 13.826c0 .33-.27.6-.601.6h-4.609v4.61c0 .33-.27.6-.6.6h-2.806a.603.603 0 0 1-.601-.6v-4.61h-4.609a.603.603 0 0 1-.6-.6v-2.805c0-.331.27-.601.6-.601h4.609v-4.609c0-.33.27-.601.601-.601h2.805c.33 0 .601.27.601.601v4.609h4.609c.33 0 .601.27.601.6v2.806Z" fill="#fff" fill-rule="evenodd" data-name="Icon awesome-plus-circle"/></svg>
                                        Add Connector
                                    </button>
                                </div>
                            </div>
                        )
                    )}

                    <div className='connector-display-div d-flex'>
                        <div className='div1'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="63.5" height="50.758" viewBox="881.5 2833.154 63.5 50.758"><path d="M941.46 2837.426a7.862 7.862 0 0 0-7.015-4.272h-42.389a7.89 7.89 0 0 0-7.037 4.309c-2.314 4.5-3.498 9.362-3.519 14.448-.035 8.784 3.47 17 9.875 23.132 5.934 5.685 13.855 8.869 21.945 8.869.523 0 1.045-.014 1.57-.04 16.884-.862 30.11-14.843 30.11-31.827 0-5.151-1.19-10.07-3.54-14.62Zm-19.5.77c2.682 0 4.866 2.19 4.866 4.884 0 2.693-2.184 4.884-4.867 4.884s-4.866-2.19-4.866-4.884c0-2.693 2.183-4.885 4.866-4.885Zm-17.42 0c2.684 0 4.867 2.19 4.867 4.884 0 2.693-2.183 4.884-4.866 4.884-2.684 0-4.867-2.19-4.867-4.884 0-2.693 2.183-4.885 4.867-4.885Zm-16.886 16.814c0-3.863 3.131-7.006 6.98-7.006 3.848 0 6.98 3.143 6.98 7.006 0 3.863-3.132 7.006-6.98 7.006-3.849 0-6.98-3.143-6.98-7.006Zm17.117 20.81c-3.85 0-6.981-3.144-6.981-7.007 0-3.863 3.131-7.006 6.98-7.006 3.848 0 6.98 3.143 6.98 7.006 0 3.863-3.132 7.007-6.98 7.007Zm8.479-13.804c-3.85 0-6.98-3.143-6.98-7.006 0-3.863 3.13-7.006 6.98-7.006 3.849 0 6.98 3.143 6.98 7.006 0 3.863-3.131 7.006-6.98 7.006Zm8.48 13.804c-3.849 0-6.979-3.144-6.979-7.007 0-3.863 3.13-7.006 6.98-7.006 3.847 0 6.979 3.143 6.979 7.006 0 3.863-3.132 7.007-6.98 7.007Zm10.137-13.804c-3.849 0-6.98-3.143-6.98-7.006 0-3.863 3.131-7.006 6.98-7.006 3.848 0 6.98 3.143 6.98 7.006 0 3.863-3.132 7.006-6.98 7.006Z" fill="#00a651" fill-rule="evenodd" data-name="Path 25"/></svg>
                        </div>
                        <div>
                            <p className='connector-name mb-0'>AC Type 2</p>
                            <p className='connector-type mb-0'>Type : AC</p>
                            <div className='d-flex'>
                                <div className='pe-3'>
                                    <p className='connector-type mb-0'>Rated Power : </p>
                                </div>
                                <div className='w-25'>
                                    <input type='number' className='form-control'  placeholder='40' />
                                </div>
                                <div>
                                    <p className='connector-type mb-0 ms-2 mb-0'> kW</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            {type === 1 ? (
                                <>
                                <div className='switch-btn-div pt-5 mt-2'>
                                    <input type="checkbox" defaultChecked={true} onChange={(e) => setCheck(e.target.checked)} name="check" value={true} class="toggle-station"/>
                                    {check ? (
                                        <span className='text-successsdf ms-2'>On</span>
                                    ) : (
                                        <span className='text-secondary ms-2'>Off</span>
                                    )}

                                </div>
                                </>
                            ) : (
                                user != 1 && (
                                    <svg className='cross-connector-icon' xmlns="http://www.w3.org/2000/svg" width="29.25" height="29.25" viewBox="1411.613 2844.154 29.25 29.25"><path d="M1426.238 2844.154a14.574 14.574 0 0 0-14.625 14.625 14.575 14.575 0 0 0 14.625 14.625 14.575 14.575 0 0 0 14.625-14.625 14.575 14.575 0 0 0-14.625-14.625Zm7.313 19.89-2.048 2.047-5.265-5.265-5.265 5.265-2.047-2.047 5.265-5.265-5.265-5.265 2.047-2.047 5.265 5.264 5.265-5.264 2.048 2.047-5.265 5.265 5.265 5.265Z" fill="#b2b2b2" fill-rule="evenodd" data-name="Icon ionic-md-close-circle"/></svg>
                                )
                            )}
                        </div>
                    </div>


                </div>
            </div>


            {/* <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Connector Type</h5>
                </div>
                <div className='station-connector '>
                    <div className='d-flex station-field'>
                        <div className='connector-type-select-div'>
                            <select className='form-control pe-4'>
                                <option>Choose Connector Type </option>
                                <option value="AC">AC</option>
                                <option value="DC">DC</option>
                            </select>
                            <svg className='connector-type-icon' xmlns="http://www.w3.org/2000/svg" width="23.616" height="13.503" viewBox="1227 2213.001 23.616 13.503"><path d="m1238.813 2222.433 8.93-8.937a1.68 1.68 0 0 1 2.383 0c.654.661.654 1.73 0 2.39l-10.118 10.126c-.64.64-1.667.654-2.328.049l-10.188-10.167a1.687 1.687 0 0 1-.492-1.196c0-.428.162-.864.492-1.195a1.68 1.68 0 0 1 2.384 0l8.937 8.93Z" fill="#707070" fill-rule="evenodd" data-name="Icon ionic-ios-arrow-down"/></svg>
                        </div>
                        <div>
                            <button className='add-connector-btn'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24.846" height="24.846" viewBox="1364.137 2203.154 24.846 24.846"><path d="M1376.56 2203.154c-6.863 0-12.423 5.56-12.423 12.423s5.56 12.423 12.423 12.423c6.862 0 12.423-5.56 12.423-12.423 0-6.862-5.56-12.423-12.423-12.423Zm7.213 13.826c0 .33-.27.6-.601.6h-4.609v4.61c0 .33-.27.6-.6.6h-2.806a.603.603 0 0 1-.601-.6v-4.61h-4.609a.603.603 0 0 1-.6-.6v-2.805c0-.331.27-.601.6-.601h4.609v-4.609c0-.33.27-.601.601-.601h2.805c.33 0 .601.27.601.601v4.609h4.609c.33 0 .601.27.601.6v2.806Z" fill="#fff" fill-rule="evenodd" data-name="Icon awesome-plus-circle"/></svg>
                                Add Connector
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}


            {/* <div className='overview-section mt-4 pt-3'>
                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5>Connector Price</h5>
                    </div>
                    <div className="width-35-percent">
                        <p className='mb-2'>Minimum Price</p>
                        <input className='form-control'  defaultValue={station?.prices?.min_price}  placeholder='Enter Minimum Price'/>
                    </div>
                </div>
                <div className='station-field'>
                    <div className='station-field-div1'>
                       // <h5>Connector Price</h5>
                    </div>
                    <div className="width-35-percent">
                        <p className='mb-2'>Maximum Price</p>
                        <input className='form-control'  defaultValue={station?.prices?.max_price} placeholder='Enter Maximum Price'/>
                    </div>
                </div>
            </div> */}


            {/* <div className='overview-section mt-4 pt-3'>
                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5></h5>
                    </div>
                    <div className="width-35-percent">
                        <p className='mb-2'>On Peak Price</p>
                        <input className='form-control'  defaultValue={station?.prices?.onPeak} placeholder='Enter On Peak Price'/>
                    </div>
                </div>
                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5></h5>
                    </div>
                    <div className="width-35-percent">
                        <p className='mb-2'>Off Peak Price</p>
                        <input className='form-control'  defaultValue={station?.prices?.offPeak} placeholder='Enter Off Peak Price'/>
                    </div>
                </div>
            </div> */}

        </div>

        <div className='add-home-cancel-submitsection mt-4'>
            <div className='station-field w-100 d-flex'>
                <div className='station-field-div1' style={{width: '31%'}}>
                    <h5></h5>
                    <p></p>
                </div>
                <div style={{width:'45%'}}>
                    {type === 1 ? (
                        <>
                            <button className='add-station-submit-btn' onClick={() => navigate(`/homelocationlist/edit/1`)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="19.975" height="19" viewBox="1728.013 136.318 19.975 19"><g data-name="Icon feather-edit-3"><path d="M1738 155.318h9.988" stroke-linejoin="round" stroke-linecap="round" stroke-width="3" stroke="#fff" fill="transparent" data-name="Path 26"/><path d="M1742.994 137.008a2.354 2.354 0 0 1 3.33 3.329l-13.872 13.872-4.44 1.11 1.11-4.44 13.872-13.871Z" stroke-linejoin="round" stroke-linecap="round" stroke-width="3" stroke="#fff" fill="transparent" data-name="Path 27"/></g></svg>
                                Edit Home
                            </button>
                            <button className='add-station-reset-btn del-station-btn'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20.533" height="25.271" viewBox="1088.734 2551.281 20.533 25.271"><path d="M1090.314 2559.179v15.794c0 .869.71 1.58 1.579 1.58h14.215c.868 0 1.58-.711 1.58-1.58v-15.794h-17.374Zm4.738 14.214h-1.58v-11.055h1.58v11.055Zm3.159 0h-1.58v-11.055h1.58v11.055Zm3.158 0h-1.579v-11.055h1.58v11.055Zm3.16 0h-1.58v-11.055h1.58v11.055Zm3.553-18.953h-5.133v-1.974c0-.651-.533-1.184-1.185-1.184h-5.528c-.651 0-1.184.533-1.184 1.184v1.974h-5.133c-.652 0-1.185.533-1.185 1.185v1.974h20.533v-1.974c0-.652-.533-1.185-1.185-1.185Zm-6.713 0h-4.738v-1.56h4.738v1.56Z" fill="#fff" fill-rule="evenodd" data-name="Icon metro-bin"/></svg>
                                Delete Station
                            </button>
                        </>
                    ) : type === 2 ? (
                        <>
                            <button className='add-station-submit-btn' >
                                <svg xmlns="http://www.w3.org/2000/svg" width="19.975" height="19" viewBox="1728.013 136.318 19.975 19"><g data-name="Icon feather-edit-3"><path d="M1738 155.318h9.988" stroke-linejoin="round" stroke-linecap="round" stroke-width="3" stroke="#fff" fill="transparent" data-name="Path 26"/><path d="M1742.994 137.008a2.354 2.354 0 0 1 3.33 3.329l-13.872 13.872-4.44 1.11 1.11-4.44 13.872-13.871Z" stroke-linejoin="round" stroke-linecap="round" stroke-width="3" stroke="#fff" fill="transparent" data-name="Path 27"/></g></svg>
                                Update
                            </button>
                            {user != 1 && (
                                <button className='add-station-reset-btn del-station-btn'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.533" height="25.271" viewBox="1088.734 2551.281 20.533 25.271"><path d="M1090.314 2559.179v15.794c0 .869.71 1.58 1.579 1.58h14.215c.868 0 1.58-.711 1.58-1.58v-15.794h-17.374Zm4.738 14.214h-1.58v-11.055h1.58v11.055Zm3.159 0h-1.58v-11.055h1.58v11.055Zm3.158 0h-1.579v-11.055h1.58v11.055Zm3.16 0h-1.58v-11.055h1.58v11.055Zm3.553-18.953h-5.133v-1.974c0-.651-.533-1.184-1.185-1.184h-5.528c-.651 0-1.184.533-1.184 1.184v1.974h-5.133c-.652 0-1.185.533-1.185 1.185v1.974h20.533v-1.974c0-.652-.533-1.185-1.185-1.185Zm-6.713 0h-4.738v-1.56h4.738v1.56Z" fill="#fff" fill-rule="evenodd" data-name="Icon metro-bin"/></svg>
                                    Delete Station
                                </button>
                            )}
                        </>
                    ) : (
                        <>
                            <button className='add-station-submit-btn'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24.846" height="24.846" viewBox="874.5 3014.154 24.846 24.846"><path d="m898.566 3019.586-4.652-4.652c-.5-.5-1.176-.78-1.882-.78h-14.87a2.662 2.662 0 0 0-2.662 2.662v19.521a2.662 2.662 0 0 0 2.662 2.663h19.522a2.662 2.662 0 0 0 2.662-2.663v-14.87c0-.705-.28-1.382-.78-1.881Zm-11.643 15.864a3.55 3.55 0 1 1 0-7.099 3.55 3.55 0 0 1 0 7.1Zm5.324-16.888v5.574a.666.666 0 0 1-.666.666h-12.866a.666.666 0 0 1-.666-.666v-5.767c0-.368.298-.666.666-.666h12.673c.177 0 .346.07.471.195l.193.193a.665.665 0 0 1 .195.47Z" fill="#fff" fill-rule="evenodd" data-name="Icon awesome-save"/></svg>
                                ADD STATION
                            </button>
                            <button className='add-station-reset-btn'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="33.128" height="24.846" viewBox="1079.5 3014.154 33.128 24.846"><path d="m1087.782 3014.154-8.282 12.423 8.282 12.423h24.846v-24.846h-24.846Zm6.212 3.23 6.211 6.211 6.212-6.211 2.981 2.981-6.211 6.212 6.211 6.211-2.981 2.982-6.212-6.212-6.211 6.212-2.982-2.982 6.212-6.211-6.212-6.212 2.982-2.981Z" fill="#fff" fill-rule="evenodd" data-name="Icon open-delete"/></svg>
                                RESET
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>

        <div className="m-nav-div">
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" className={activeOp === 1 ? "m-active-link" : "no-optt"} onClick={() => {navigate('/home'); setActiveOp(1)}} width="27.424" height="27.424" viewBox="56 801.576 27.424 27.424"><path d="M69.712 817.002a7.715 7.715 0 0 0 7.713-7.713 7.715 7.715 0 0 0-7.713-7.713A7.715 7.715 0 0 0 62 809.289a7.715 7.715 0 0 0 7.713 7.713Zm6.856 1.714h-2.951a9.334 9.334 0 0 1-3.905.857c-1.392 0-2.71-.31-3.905-.857h-2.95A6.856 6.856 0 0 0 56 825.572v.857A2.572 2.572 0 0 0 58.571 829h22.282a2.572 2.572 0 0 0 2.571-2.571v-.857a6.856 6.856 0 0 0-6.856-6.856Z" fill-rule="evenodd" data-name="Icon awesome-user-alt"/></svg>
            </div>
            <div>
                <svg className={activeOp === 2 ? "m-active-link" : "no-optt"} onClick={() => {navigate('/home/profile'); setActiveOp(2)}} xmlns="http://www.w3.org/2000/svg" width="27.424" height="27.424" viewBox="181 799.288 27.424 27.424"><path d="m205.099 799.288-10.387 10.284-3.428-3.428L181 816.531l3.428 3.428 6.856-6.959 3.428 3.428 13.712-13.712-3.325-3.428ZM181 823.284v3.428h27.424v-3.428H181Z" fill-rule="evenodd" data-name="Icon open-graph"/></svg>
            </div>
            <div>
                <svg onClick={logoutFun2} xmlns="http://www.w3.org/2000/svg" width="25.71" height="27.424" viewBox="301.993 801.575 25.71 27.424"><path d="M322.56 818.716v-3.428h-8.57v-3.428h8.57v-3.428l5.143 5.142-5.142 5.142Zm-1.713-1.714v6.856h-8.57V829l-10.284-5.142v-22.283h18.854v8.57h-1.714v-6.856H305.42l6.856 3.429v15.426h6.856v-5.142h1.714Z"  fill-rule="evenodd" data-name="Icon metro-exit"/></svg>
            </div>
        </div>


        {/* <div className='overview-section' style={{borderTop: '2px solid #E4E7EC', paddingTop: '2rem'}}>
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Charger Detail</h5>
                </div>
                <div>
                    <p>Charger name</p>
                    <input className='form-control' placeholder='Charger 1'/>
                    <div className='station-field-location'>
                        <div>
                            <p>Charger ID</p>
                            <input className='form-control' placeholder='T54-HU1-3220-008'/>
                        </div>
                        <div>
                            <p>Charger Number</p>
                            <input className='form-control' placeholder='P101'/>
                        </div>
                    </div>
                    <div className='station-field-location'>
                        <div>
                            <p>Brand</p>
                            <input className='form-control' placeholder='ABB'/>
                        </div>
                        <div>
                            <p>Model</p>
                            <input className='form-control' placeholder='TERRA CJG-54'/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Connector Type</h5>
                </div>
                <div className='station-connector'>
                    <div className='station-connector-section'>
                        <div>
                            <h6>CHAdeMO</h6>
                            <p>Type: DC <br/> Rated Power: 50 kW</p>
                        </div>
                        <div className='status'>
                            Available
                        </div>
                    </div>
                    <div className='station-connector-section'>
                        <div>
                            <h6>CCS 2</h6>
                            <p>Type: DC <br/> Rated Power: 50 kW</p>
                        </div>
                        <div className='status'>
                            Available
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
    </div>
  )
}



