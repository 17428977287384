import React, { useState } from "react";
import HomeHdr from "../HomeHdr";
import AddHomeForm from "../AddHomeForm";
import EnergyUsed from "../../../common/EnergyUsed";
import DataTableBase from "../../../common/DataTableBase";
import { useNavigate } from "react-router-dom";

const data2 = [
    {
        date: '2023-04-17',
        time: '18:30',
        chargingTime: '43m',
        EnergyUsed: '1.71',
        status: 'normal',
    },
    {
        date: '2023-04-16',
        time: '7:30',
        chargingTime: '1h 33m',
        EnergyUsed: '2.31',
        status: 'normal',
    },
    {
        date: '2023-04-13',
        time: '15:23',
        chargingTime: '2h 51m',
        EnergyUsed: '4.72',
        status: 'normal',
    },
    {
        date: '2023-04-11',
        time: '2:38',
        chargingTime: '42m',
        EnergyUsed: '1.09',
        status: 'normal',
    },
]
const HomeDetailPage = ({user}) => {
    const [activeOp, setActiveOp] = useState(1);

    const navigate = useNavigate();

    const columns = [
        {
            name: "Date",
            selector: row => row?.date,
        },
        {
            name: "Time",
            selector: row => row?.time,
        },
        {
            name: "Charging Time",
            selector: row => row?.chargingTime,
        },
        {
            name: "Energy Used (kW)",
            selector: row => row?.EnergyUsed,
        },
        {
            name: "Status",
            selector: row => row?.status,
        }
    ]


    function logoutFun2 (){
        localStorage.removeItem('pea_client');
        navigate('/login');
    }


    return (
        <>
            {user != 1 && (
                <HomeHdr type={1} />
            )}
            <div className="overview-page">
                <EnergyUsed />
                <div className="charge-log">
                    <div className="d-flex charge-div-border">
                        <div className="w-75">
                            <h3 className="mb-0">Charge Log</h3>
                            <p className="mb-2">Charging history</p>
                        </div>
                        <div className="w-25">
                            <div className="float-end pt-md-4 pt-2 mt-2">
                                <button className="arrow-btns">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10.56" height="18.471" viewBox="10.166 6.257 10.56 18.471"><path d="m13.349 15.49 6.989-6.984a1.314 1.314 0 0 0 0-1.864 1.326 1.326 0 0 0-1.87 0l-7.918 7.913c-.5.5-.511 1.303-.038 1.82l7.95 7.968c.26.258.6.385.936.385.335 0 .676-.127.934-.385a1.314 1.314 0 0 0 0-1.864l-6.983-6.99Z" fill="#b2b2b2" fill-rule="evenodd" data-name="Icon ionic-ios-arrow-back"/></svg>
                                </button>
                                <button className="arrow-btns arrow-right-rotate ms-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10.56" height="18.471" viewBox="10.166 6.257 10.56 18.471"><path d="m13.349 15.49 6.989-6.984a1.314 1.314 0 0 0 0-1.864 1.326 1.326 0 0 0-1.87 0l-7.918 7.913c-.5.5-.511 1.303-.038 1.82l7.95 7.968c.26.258.6.385.936.385.335 0 .676-.127.934-.385a1.314 1.314 0 0 0 0-1.864l-6.983-6.99Z" fill="#b2b2b2" fill-rule="evenodd" data-name="Icon ionic-ios-arrow-back"/></svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="mt-2">
                        <DataTableBase 
                            // title={"Error Home Location"}
                            columns={columns}
                            data={data2}
                        />
                    </div>
                </div>
            </div>
            <div className="m-nav-div">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" className={activeOp === 1 ? "m-active-link" : "no-optt"} onClick={() => {navigate('/home'); setActiveOp(1)}} width="27.424" height="27.424" viewBox="56 801.576 27.424 27.424"><path d="M69.712 817.002a7.715 7.715 0 0 0 7.713-7.713 7.715 7.715 0 0 0-7.713-7.713A7.715 7.715 0 0 0 62 809.289a7.715 7.715 0 0 0 7.713 7.713Zm6.856 1.714h-2.951a9.334 9.334 0 0 1-3.905.857c-1.392 0-2.71-.31-3.905-.857h-2.95A6.856 6.856 0 0 0 56 825.572v.857A2.572 2.572 0 0 0 58.571 829h22.282a2.572 2.572 0 0 0 2.571-2.571v-.857a6.856 6.856 0 0 0-6.856-6.856Z" fill-rule="evenodd" data-name="Icon awesome-user-alt"/></svg>
                </div>
                <div>
                    <svg className={activeOp === 2 ? "m-active-link" : "no-optt"} onClick={() => {navigate('/home/profile'); setActiveOp(2)}} xmlns="http://www.w3.org/2000/svg" width="27.424" height="27.424" viewBox="181 799.288 27.424 27.424"><path d="m205.099 799.288-10.387 10.284-3.428-3.428L181 816.531l3.428 3.428 6.856-6.959 3.428 3.428 13.712-13.712-3.325-3.428ZM181 823.284v3.428h27.424v-3.428H181Z" fill-rule="evenodd" data-name="Icon open-graph"/></svg>
                </div>
                <div>
                    <svg onClick={logoutFun2} xmlns="http://www.w3.org/2000/svg" width="25.71" height="27.424" viewBox="301.993 801.575 25.71 27.424"><path d="M322.56 818.716v-3.428h-8.57v-3.428h8.57v-3.428l5.143 5.142-5.142 5.142Zm-1.713-1.714v6.856h-8.57V829l-10.284-5.142v-22.283h18.854v8.57h-1.714v-6.856H305.42l6.856 3.429v15.426h6.856v-5.142h1.714Z"  fill-rule="evenodd" data-name="Icon metro-exit"/></svg>
                </div>
            </div>
            {user != 1 && (
                <AddHomeForm type={1} />
            )}
        </>
    )
}

export default HomeDetailPage;