import React from "react";
import { Route, Routes } from "react-router-dom";
import Admin from "./admin";
import Overview from "./admin/pages/Overview";
import ChargerList from "./admin/pages/ChargerList";
import AddHomePage from "./admin/pages/AddHomePage";
import HomeDetailPage from "./admin/pages/HomeDetail";
import EditHomePage from "./admin/pages/EditHomePage";
import ErrorHomeNotifications from "./admin/pages/ErrorHomeNotifications";
import AreaCircuit from "./admin/pages/AreaCircuit";
import AdminLogin from "./admin/pages/AdminLogin";
import ClientLogin from "./client/pages/ClientLogin";
import Client from "./client";
import Substations from "./admin/pages/Substations";
import AddSubstation from "./admin/pages/AddSubstation";
import EditSubstation from "./admin/pages/EditSubstation";
import SubstationDetail from "./admin/pages/SubstationDetail";
import Feeders from "./admin/pages/Feeders";
import AddFeeder from "./admin/pages/AddFeeder";
import EditFeeder from "./admin/pages/EditFeeder";
import FeederDetail from "./admin/pages/FeederDetail";
import Transformers from "./admin/pages/Transformers";
import AddTransformer from "./admin/pages/AddTransformer";
import EditTransformer from "./admin/pages/EditTransformer";
import TransformerDetail from "./admin/pages/TransformerDetail";
import Meters from "./admin/pages/Meters";
import AddMeter from "./admin/pages/AddMeter";
import EditMeter from "./admin/pages/EditMeter";
import MeterDetail from "./admin/pages/MeterDetail";
import AddCharger from "./admin/pages/AddCharger";
import EditCharger from "./admin/pages/EditCharger";
import ChargerDetail from "./admin/pages/ChargerDetail";

const Users = () => {
    return (
        <>  
            <Routes>
                <Route path="/" element={<Admin />}>
                    <Route index element={<Overview />} />
                    <Route path="chargerlist" element={<ChargerList />} />
                    <Route path="/chargerlist/add" element={<AddCharger />} />
                    <Route path="/chargerlist/edit/:id" element={<EditCharger />} />
                    <Route path="/chargerdetail/:id" element={<ChargerDetail />} />

                    <Route path="homelocationlist/addnewhome" element={<AddHomePage />} />

                    <Route path="substationslist" element={<Substations />} />
                    <Route path="substationslist/add" element={<AddSubstation />} />
                    <Route path="substationslist/edit/:id" element={<EditSubstation />} />
                    <Route path="substationdetail/:id" element={<SubstationDetail />} />
                    
                    <Route path="feederlist" element={<Feeders />} />
                    <Route path="feederlist/add" element={<AddFeeder />} />
                    <Route path="feederlist/edit/:id" element={<EditFeeder />} />
                    <Route path="feederdetail/:id" element={<FeederDetail />} />

                    <Route path="transformerlist" element={<Transformers />} />
                    <Route path="transformerlist/add" element={<AddTransformer />} />
                    <Route path="transformerlist/edit/:id" element={<EditTransformer />} />
                    <Route path="transformerdetail/:id" element={<TransformerDetail />} />

                    <Route path="meterlist" element={<Meters />} />
                    <Route path="meterlist/add" element={<AddMeter />} />
                    <Route path="meterlist/edit/:id" element={<EditMeter />} />
                    <Route path="meterdetail/:id" element={<MeterDetail />} />
                    
                    <Route path="homelocationlist/:id" element={<HomeDetailPage />} />
                    <Route path="homelocationlist/edit/:id" element={<EditHomePage />} />
                    <Route path="errornotification" element={<ErrorHomeNotifications />} />
                    <Route path="areacircuit" element={<AreaCircuit />} />
                </Route>
                <Route path="/adminlogin" element={<AdminLogin />} />
                <Route path="/login" element={<ClientLogin />} />
                <Route path="/home/" element={<Client />}>
                    <Route index element={<HomeDetailPage user={1} />} />
                    <Route path="profile" element={<EditHomePage user={1} />} />
                </Route>
            </Routes>
        </>
    )
}

export default Users;