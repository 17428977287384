// export const apiEndPoint = 'https://home-api.pea-cpo.com';
export const apiEndPoint = 'https://www.pea2-chargingnetwork.com/Backend';

// export function startsWithAlpha(inputString) {
//     return inputString.startsWith('alpha');
//   }


export function formatDate(date) {
    const day = ("0" + date.getDate()).slice(-2);
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
}

export function getCurrentYear() {
    const date = new Date();
    return date.getFullYear();
}

export function getCurrentMonthName() {
    const date = new Date();
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return monthNames[date.getMonth()];
}
  