import React, { useEffect, useState } from "react";
// import img1 from '../assets/profile photo.png'
// import evStationIcon from '../assets/ev_station1.svg'
// import eleServicesIcon from '../assets/electrical_services.svg'
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
// import { BallTriangle } from "react-loader-spinner";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import homeImg from '../../../assets/charger station mock.png';
import delIcon from '../../../assets/icons/del-icon.png';
import { apiEndPoint } from "../../../utils";

// import { FiCamera } from 'react-icons/fi';

// import SweetAlert from 'react-bootstrap-sweetalert';

const HomeHdr = ({type, user, data, handleShow, url}) => {
    const [focusElements, setFocusElements] = useState(['active-tog']);

    const [station, setStation] = useState(null);

    const [image, setImage] = useState(null);

    const [showAlert, setShowAlert] = useState(null);

    const [show, setShow] = useState(false);

    console.log("data************************************************** : ", data);
    
    const { id } = useParams();
    
    const navigate = useNavigate();
    
    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    
    function handleClick(value) { 
        const data = []
        for(var i = 0; i < 9; i++) {
            if(i !== value){
                data[i] = '';
            }else{
                data[value] =  'active-tog'
            }
        }
        setFocusElements(data)
    };

    useEffect(() => {
        // const token = localStorage.getItem('token');

        // axios.get(`https://api.pea-cpo.com/api/getChargerListCpoById/${id}`, {headers: {'Authorization' : `Bearer ${token}`}}).then((res) => {
        // console.log("Get Charger detail : ", res);
        
        // setStation(res?.data?.data[0]);

        // }).catch((err) =>{
        // console.log("Error get user : ", err);
        // })
    }, [])

    function handleAlert(){
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
        }, 2000)
    }

    function uploadImage(){
        if(image){
            const token = localStorage.getItem('token');
    
            const formData = new FormData();
            formData.append("image", image);
            formData.append("charger_id", id);

            // axios.post('https://api.pea-cpo.com/api/updateStationProfileImage', formData, {headers: {'Authorization' : `Bearer ${token}`}}).then((res) => {
            // console.log("Update Image : ", res);
            // handleClose();
            // handleAlert();
    
            // }).catch((err) =>{
            //     handleClose();
            //     console.log("Error Image update : ", err);
            // })
        } 

    }

    // console.log("Station : ", station);

    return (
        <>
        <div className={user === 1 ? "Station-detail hdr-on-mobile" : "Station-detail"}>
        {/* {showAlert && (
            <SweetAlert success title="Congratulations!" onConfirm={() => setShowAlert(false)} onCancel={() => setShowAlert(false)}>
                Image Updated Successfully!
            </SweetAlert>
        )} */}
        {user != 1 && (
            <>
                <span className="pages-routes1">Substation </span>
                <span className="pages-routes2">&#62;</span>
                <span className="pages-routes1">{data?.substation?.substationsID} </span>
                <span className="pages-routes2">&#62;</span>
                {type === 2 && (
                    <>
                        <span className="pages-routes1">HOME KORAT 01 </span>
                        <span className="pages-routes2">&#62;</span>
                    </>
                )}
                
                <span className="pages-routes3">{type === 1 ? 'view' : type === 2 ? 'Edit' : 'Add New Home'}</span>
                <h2 className="station-detail-title">{station?.locationTH}</h2>
            </>
        )}

        <div className="station-detail-card">
            <div className="d-flex w-100">
                <Modal show={show} style={{marginTop: '25vh'}} onHide={handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title className="w-100 text-center">Update Profile</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="px-4">
                        <label className="mb-2">Upload Profile</label>
                        <input type="file" name="profile" onChange={(e) => setImage(e.target.files[0])} className="form-control" />
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" style={{background: '#814097', border: 'none'}} onClick={uploadImage}>
                        Save Changes
                    </Button>
                    </Modal.Footer>
                </Modal>
                <div className="staion-detail-cardimg" style={{background: '#eeeeee'}}>
                    {/* {station?.chargerPhoto ? ( */}
                        <>
                            {/* <div className="edit-img-div2" onClick={handleShow}>
                                <FiCamera size={"0.5cm"} color="#000" />
                            </div> */}
                            {type === 1 || type === 2 ? (
                                <img src={url?.startsWith('substation_images') === true ? `${apiEndPoint}/${url}` : homeImg} style={{marginTop: '-0px'}} height="164px" width="164px" alt="picc" />
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" style={{margin: '45px 30%'}} width="65" height="65" viewBox="504 253 65 65"><path d="M504 253v65h65v-65h-65Zm8.125 8.125h48.75V285.5l-8.125-8.125-8.125 8.125 16.25 16.25v8.125h-8.125l-32.5-32.5-8.125 8.125v-24.375Z" fill="#b2b2b2" fill-rule="evenodd" data-name="Icon open-image"/></svg>
                            )}
                        </>
                    {/* ) : (
                        <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '3vh'}}>
                            <BallTriangle
                                height={70}
                                width={70}
                                radius={5}
                                color="#814097"
                                ariaLabel="ball-triangle-loading"
                                wrapperClass={{}}
                                wrapperStyle=""
                                visible={true}
                            />
                        </div>
                    )} */}
                </div>
                <div className="staion-detail-carddetail">
                    {/* {type === 1 ? (
                        <div className="stations-number" style={{height: '24px'}}>
                            <h6>PEA-1129-P248</h6>
                            <div style={{cursor:'pointer'}}>
                                <div>
                                    <div className="home-status-div d-flex justify-content-between" style={{ float: 'right' }}>
                                        <div className="w-50 status-heading-div pt-1">
                                            <span className="status-heading-clr">Status :</span>
                                        </div>
                                        <div>
                                            <div className="home-status-div-show">
                                                <span>
                                                    ON
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="w-100" style={{ float: 'right' }}>
                                    <p className="home-fontstyle-1">Last Charge : 2023-04-07 18:68</p>

                                </div>
                            </div>
                        </div>
                    ) : (
                        <h6 className="font-style10">PEA-1129-P248</h6>
                    )} */}
                    <h4 className="font-style11" onClick={() => {if(type === 1) {} else { 
                        //navigate('/homelocationlist/1'); 
                    }}}>{data?.substation?.substationsID}</h4>
                    <h5 className="font-style1121">{data?.substation?.substationName}</h5>
                    <h6 classN0ame="font-style12 mb-0 pt-2 mt-3">
                        {data?.substation?.substationAddress}
                        <div style={{float: 'right'}}>
                            <button className="edit-btn-header" onClick={() => navigate(`/substationslist/edit/${id}`)}>Edit</button>
                            <img src={delIcon} className="del-icon-hdr pointer" onClick={handleShow} alt="del-icon-image" />
                        </div>
                    </h6>
                    <h6 className="font-style12 mb-0 pt-2">Status :  {data?.substation?.status === 1 ? <span className="clr-ff0000 text-success"> Online</span> : <span className="clr-ff0000"> Offline</span>}</h6>
                    {/* <div className="w-100 d-flex font-style131">
                        <div className="me-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="35.75" height="31.778" viewBox="650.458 336.223 35.75 31.778"><path d="M671.312 364.028h-19.86a.993.993 0 0 0-.994.993v1.986c0 .549.444.993.993.993h19.861a.993.993 0 0 0 .993-.993v-1.986a.993.993 0 0 0-.993-.993Zm12.91-19.86v-2.98a.993.993 0 1 0-1.986 0v2.98h-1.986v-2.98a.993.993 0 1 0-1.986 0v2.98h-.993a.993.993 0 0 0-.994.992v1.986c0 2.22 1.466 4.077 3.476 4.713v7.354c0 .866-.59 1.671-1.444 1.812a1.74 1.74 0 0 1-2.032-1.714v-1.738a5.462 5.462 0 0 0-5.461-5.461h-.497v-11.917a3.972 3.972 0 0 0-3.972-3.972h-9.93a3.972 3.972 0 0 0-3.973 3.972v21.847h17.875v-6.951h.497a2.483 2.483 0 0 1 2.482 2.482v1.528c0 2.462 1.795 4.665 4.246 4.904a4.721 4.721 0 0 0 5.188-4.694v-7.452c2.01-.636 3.476-2.493 3.476-4.713v-1.986a.993.993 0 0 0-.993-.993h-.993ZM666.6 347.13l-5.816 8.628a.77.77 0 0 1-.645.325c-.476 0-.836-.39-.724-.802l1.429-5.157h-3.684c-.45 0-.797-.347-.738-.738l.993-6.64c.05-.324.365-.566.738-.566h4.22c.49 0 .846.406.72.82l-.72 3.152h3.582c.573 0 .931.545.645.978Z" fill="#b2b2b2" fill-rule="evenodd" data-name="Icon awesome-charging-station"/></svg>
                        </div>
                        <div>
                            <h6 className="font-style133 mb-0">Charger name : PEA VOLTA #01</h6>
                            <h6 className="font-style134">ID : PEA-1129</h6>
                        </div>
                    </div> */}
                    
                </div> 
            </div>
            
        </div>
        {/* <Outlet/> */}
        </div>
    </>
    )
}

export default HomeHdr;
